import React from 'react';
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import base from "../../../../Services/BaseService";
import "ag-grid-community";
import "ag-grid-enterprise";
import "../../../../Content/stylesheet/ag-grid-style.css";
import { createRoot } from "react-dom/client";
import { Edit32, Trash32, TailUp32, TailDown32, FileAdd32 } from "@bphxd/ds-core-react/lib/icons";
import { useModuleContext } from "../../../Shared/moduleContext";
import ErrorToastNew from "../../../Toast/ErrorToastNew1";
import ConfirmDeleteToast from "../../../Toast/ConfirmDeleteToast";
import StepModal from '../Modals/StepModal';

const StepTab = ({ getAllSteps, stepGridData }) => {
    const { t } = useTranslation();
    const updatedStepData = stepGridData.map((step, index) => {
        let moveUp = true;
        let moveDown = true;
        if (step.taskID === "GBL10057") {
            moveUp = false;
            moveDown = false;
        } else {
            if (step.step_Label === "1." || (step?.step_Label || '').endsWith(".1")) {
                moveUp = false;
            }
            if (index === stepGridData.length - 1) {
                moveDown = false;
            } else {
                const subsequentSteps = stepGridData.slice(index + 1);
                const nextStep = subsequentSteps.find(nextStep => nextStep.step_Level <= step.step_Level);
                if (nextStep === undefined || nextStep.step_Level < step.step_Level) {
                    moveDown = false;
                }
            }
        }
        const updatedStep = {
            ...step,
            "moveUp": moveUp,
            "moveDown": moveDown
        };
        return updatedStep;
    });
    const pageSize = 200;
    const [stepGridColumnHeadings, setStepGridColumnHeadings] = useState([]);
    const gridDefaultColDef = {
        flex: 1,
        minWidth: 50,
        sortable: true,
        filter: true,
        resizable: true,
        editable: false,
        wrapText: true,
        autoHeight: true,
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
        sortingOrder: ["asc", "desc"],
        filterParams: {
            buttons: ["clear"],
            newRowsAction: "keep",
        },
        menuTabs: ["filterMenuTab"],
    }
    const [deleteStep, setDeleteStep] = useState(false);
    const [stepsModal, setStepsModal] = useState(false)
    const [create, setCreate] = useState(false)
    const [stepData, setStepData] = useState([])
    const [stepDataToDelete, setStepDataToDelete] = useState([])
    const [showToast, setShowToast] = useState({ Name: "", show: false });
    const [deleteStepConfirmToast, setDeleteSteponfirmToast] = useState({
        Message: "",
        show: false,
    });
    const [hasFailed, setHasFailed] = useState(false)
    const { moduleData, moduleLanguagePreference, moduleDataSaved, moduleRefetch, globalUser } = useModuleContext();
    const langaugeRef = useRef(moduleLanguagePreference);
    const currentLanguageRef = useRef(moduleLanguagePreference);
    const [selectedHazards, setSelectedHazards] = useState([])
    const dataError = (tostName) => {
        setShowToast({ Name: tostName, show: true });
        document.addEventListener("mousedown", DataSavingToastonClick);
    };
    const dataSaved = (tostName) => {
        setShowToast({ Name: tostName, show: true });
        document.addEventListener("mousedown", DataSavingToastonClick);
    };

    function DataSavingToastonClick() {
        setShowToast({ Name: "", show: false });
    }

    useEffect(() => {
        setStepGridColumnHeadings([
            {
                headerName: t("Curriculumpage.StepsHeader.LEVEL"),
                headerTooltip: t("Curriculumpage.StepsHeader.LEVEL"),
                field: "step_Label",
                resizable: false,
                filter: true,
                suppressMovable: true,
                minWidth: 150,
                maxWidth: 150,
                cellRenderer: NewsHeadlineCellRenderer,
            },
            {
                headerName: t("Curriculumpage.StepsHeader.CRITICAL"),
                headerTooltip: t("Curriculumpage.StepsHeader.CRITICAL"),
                field: "step_Critical",
                resizable: false,
                suppressMovable: true,
                filter: true,

                minWidth: 150,
                maxWidth: 150,
                cellRenderer: NewsHeadlineCellRenderer,
            },
            {
                headerName: t("Curriculumpage.StepsHeader.DESCRIPTION"),
                headerTooltip: t("Curriculumpage.StepsHeader.DESCRIPTION"),
                field: "step_Description",
                resizable: false,
                filter: true,
                suppressMovable: true,
                minWidth: 600,
                maxWidth: 900,
                cellRenderer: NewsHeadlineCellRenderer,
            },
            {
                headerName: t("Curriculumpage.StepsHeader.MOVE_ABOVE_BELOW"),
                headerTooltip: t("Curriculumpage.StepsHeader.MOVE_ABOVE_BELOW"),
                resizable: false,
                suppressMovable: true,
                filter: false,
                minWidth: 180,
                maxWidth: 180,
                suppressHeaderMenuButton: true, cellRenderer: NewsUpDownCellRenderer,
            },
            {
                headerName: t("Curriculumpage.StepsHeader.ADD_STEP_ACTIVITY"),
                headerTooltip: t("Curriculumpage.StepsHeader.ADD_STEP_ACTIVITY"),
                resizable: false,
                suppressMovable: true,
                filter: false,
                minWidth: 180,
                maxWidth: 180,
                suppressHeaderMenuButton: true, cellRenderer: AddStepCellRenderer,
            },
            {
                headerName: t("Curriculumpage.StepsHeader.EDIT_DELETE"),
                headerTooltip: t("Curriculumpage.StepsHeader.EDIT_DELETE"),
                resizable: false,
                suppressMovable: true,
                minWidth: 200,
                maxWidth: 200,
                sortable: false,
                filter: false,
                suppressHeaderMenuButton: true, cellRenderer: NewsEditCellRenderer,
            },
        ]);
        // eslint-disable-next-line
    }, []);

    class NewsHeadlineCellRenderer {
        init(params) {
            var element = document.createElement("span");
            element.innerHTML = params.value;
            if (params.data.step_Critical === "Y") {
                element.classList.add("stepCriticalFont");
            } else element.classList.add("headline");

            this.eGui = element;
        }
        getGui() {
            return this.eGui;
        }
    }

    function NewsEditCellRenderer(params) {
        let disableDelete = "";
        let disableEdit = "";
        if (params.data.taskID === "GBL10057" || (globalUser !== "GADMIN" && moduleData[0].task_Site === "GLOBAL")) {
            disableDelete = "dat2DisableStepsActivityIcons";
        }
        if (moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK") {
            disableDelete = "dat2DisableStepsActivityIcons";
        }

        return (
            <div className="gridCrudIcons">
                <Edit32
                    id="editButton"
                    className={"pointer " + disableEdit}
                    style={{ marginLeft: "0px", marginTop: "6px" }}
                    onClick={() => openStepsModal("edit", params.node.data)}
                />
                <Trash32
                    className={"pointer " + disableDelete}
                    id="deleteButton"
                    style={{ marginLeft: "8px", marginTop: "6px" }}
                    onClick={() => deleteStepClick(params.node.data)}
                />
            </div>
        );
    }

    class NewsUpDownCellRenderer {
        init(params) {
            var divElement = document.createElement("div");
            divElement.classList.add("gridCrudIcons");
            this.eGui = divElement;

            let disableUP = "";
            let disableDown = "";

            if (params.data.moveDown === false) {
                disableDown = "upDownDisable";
            }
            if (params.data.moveUp === false) {
                disableUP = "upDownDisable";
            }

            if (moduleData[0].task_Site === "GLOBAL" && globalUser === "GADMIN" & moduleData[0].task_Type !== "TSK") {
                disableDown = "";
                disableUP = "";
            }
            if (moduleData[0].task_Site === "GLOBAL" && globalUser !== "GADMIN" & moduleData[0].task_Type !== "TSK") {
                disableDown = "upDownDisable";
                disableUP = "upDownDisable";
            }
            if (moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK") {
                disableDown = "upDownDisable";
                disableUP = "upDownDisable";
            }

            const root = createRoot(divElement);
            root.render(
                <>
                    <TailUp32 className={
                        "pointer " + disableUP
                    }
                        style={{ marginLeft: "10px", marginTop: "6px" }}
                        onClick={() => moveUp(params.node.data)}
                    />
                    <TailDown32 className={
                        "pointer " + disableDown
                    }
                        style={{ marginLeft: "10px", marginTop: "6px" }}
                        onClick={() => moveDown(params.node.data)}
                    />
                </>);
        }
        getGui() {
            return this.eGui;
        }
    }

    class AddStepCellRenderer {
        init(params) {
            var disableForDAT2 = "";
            if (params.data.taskID === "GBL10057")
                disableForDAT2 = "dat2DisableStepsActivityIcons";

            if (moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK") {
                disableForDAT2 = "dat2DisableStepsActivityIcons";
            }
            if (moduleData[0].task_Site === "GLOBAL" && globalUser === "GADMIN" & moduleData[0].task_Type !== "TSK") {
                disableForDAT2 = ""
            }
            if (moduleData[0].task_Site === "GLOBAL" && globalUser !== "GADMIN" & moduleData[0].task_Type !== "TSK") {
                disableForDAT2 = "dat2DisableStepsActivityIcons"
            }

            var divElement = document.createElement("div");
            divElement.classList.add("gridCrudIcons");
            this.eGui = divElement;
            const root = createRoot(divElement);
            root.render(
                <>
                    <FileAdd32
                        className={"pointer " + disableForDAT2}
                        style={{ marginLeft: "36px", marginTop: "6px" }}
                        onClick={() => openStepsModal("create", params.node.data)}
                    />
                </>);
        }
        getGui() {
            return this.eGui;
        }
    }

    const openStepsModal = (params, data) => {
        if (params === "create") {
            setCreate(true)
            setSelectedHazards([]);
            setStepsModal(true)
            setStepData([{
                id: data.id,
                ssrt: data.ssrt,
                stepID: "",
                step_Critical: "N",
                step_Description: "",
                step_Label: data.step_Label,
                step_Level: "",
                parent_step_Level: data.step_Level,
                parent_stepID: data.stepID,
                fk_taskID: data.taskID,
                fk_LanguageID: langaugeRef.current
            }])
        }
        if (params === "edit") {
            let promises = [];
            promises.push(base.getAll("step/frm/" + data.stepID))
            promises.push(base.getAll("hazardstep/Qry/" + data.stepID))
            Promise.all(promises).then(([stepData, hazardStep]) => {
                setSelectedHazards(hazardStep);
                setStepData(stepData)
                setCreate(false)
                setStepsModal(true)
            });
        }
    }

    const closeStepsModal = () => {
        setStepsModal(false)
    }

    const saveStepModal = () => {
        setStepsModal(false);
        getAllSteps(moduleData[0].taskID);
        moduleDataSaved("dataSuccess", "Step/activity saved");
    }

    useEffect(() => {
        getAllSteps(moduleData[0].taskID)
        currentLanguageRef.current = moduleLanguagePreference;
        langaugeRef.current = moduleLanguagePreference;
        // eslint-disable-next-line
    }, [moduleLanguagePreference, moduleRefetch]);

    const deleteStepClick = (params) => {
        let deleteStepData = params;
        let taskID = params.taskID;
        var datatoDelete = [];
        var parentToDelete = "";
        var childrenToDelete = "";
        var TotalStepsToTask = "";
        base.getAll("step/qrylocal/" + taskID + "/" + moduleLanguagePreference).then((res) => {
            var cnt = 0;
            var len = deleteStepData.step_Label.length;
            var label = deleteStepData.step_Label.substr(0, len);
            var labelToFind = label + '.';
            cnt = labelToFind.length;

            if (res !== undefined) {
                childrenToDelete = res.filter(
                    (x) =>
                        x.rel_rec !== 0 &&
                        x.step_Description !== "" &&
                        x.step_Description != null &&
                        x.step_Label.substr(0, cnt) === labelToFind
                );
                parentToDelete = res.filter(
                    (x) =>
                        x.rel_rec !== 0 &&
                        x.step_Description !== "" &&
                        x.step_Description != null &&
                        x.step_Label === label
                );
                datatoDelete = datatoDelete.concat(parentToDelete, childrenToDelete);
                TotalStepsToTask = res.filter(
                    (x) =>
                        x.rel_rec !== 0 &&
                        x.step_Description !== "" &&
                        x.step_Description != null &&
                        x.step_Level === "1"
                );

                setStepDataToDelete(datatoDelete);

                if (
                    TotalStepsToTask.length === 1 &&
                    deleteStepData.step_Level === "1"
                ) {
                    moduleDataSaved("error", t("Curriculumpage.ErrorCannotDeleteDummystep"))
                } else {
                    if (datatoDelete.length === 1) {
                        let msg =
                            t("Curriculumpage.StepActivityDelToastMsg1") +
                            params.step_Label +
                            " ?";
                        setDeleteSteponfirmToast({ Message: msg, show: true });
                        setDeleteStep(params);
                    } else {
                        let msg =
                            t("Curriculumpage.Deleting Step / Activity") +
                            params.step_Label + ' ' +
                            t("Curriculumpage.Will delete") + ' ' +
                            (datatoDelete.length - 1) + ' ' +
                            t("Curriculumpage.StepActivityDelToastMsg2");
                        setDeleteSteponfirmToast({ Message: msg, show: true });
                        setDeleteStep(params);
                    }
                }
            }
        });
    };

    const handleDeleteStep = () => {
        try {
            if (!!deleteStep) {
                getAllstepsWithTAskID(deleteStep);
            }
        } catch (err) {
        } finally {
            setDeleteSteponfirmToast({ Message: "", show: false });
        }
    };
    const getAllstepsWithTAskID = (deleteStepData, morethanEightDelete) => {
        var taskID = moduleData[0].taskID;
        try {
            var datatoDelete = stepDataToDelete;

            for (let i = 0; i < datatoDelete.length; i++) {
                base
                    .delete(
                        process.env.REACT_APP_STEP_DELETE,
                        datatoDelete[i].stepID
                    )
                    .then((response) => {
                        if (response === 200) {
                            moduleDataSaved(
                                "dataSuccess",
                                t("Curriculumpage.StepDeleteToast")
                            );
                            getAllSteps(taskID);
                        } else {
                            setDeleteSteponfirmToast({ Message: "", show: false });
                        }
                    });
            }
            ;
        } catch (err) {
        } finally {
            setDeleteSteponfirmToast({ Message: "", show: false });
        }
    };

    const cancelDelete = () => {
        setDeleteSteponfirmToast({ Message: "", show: false });
        setDeleteStep({});
    };

    const moveUp = (params) => {
        base.getAll("step/Get/" + params.stepID).then((res) => {
            if (res != null && res !== undefined) {
                var taskID = res.fk_TaskID;
                var url =
                    process.env.REACT_APP_STEP_UPD +
                    "/" +
                    taskID +
                    "/" +
                    params.stepID +
                    "/U/step/move";
                base
                    .update(url)
                    .then((response) => {
                        if ((response.data = "step moved")) {
                            base
                                .getAll("step/Get/" + params.stepID)
                                .then((res) => {
                                    if (res.step_Label === params.step_Label) {
                                        dataSaved("cannotmove");
                                    } else {
                                        moduleDataSaved(
                                            "dataSuccess",
                                            t("Curriculumpage.StepMoveToast")
                                        );
                                    }
                                });
                            getAllSteps(taskID);
                        }
                    })
                    .catch((error) => {
                        dataError("error");
                    });
            }
        });

    };
    const moveDown = (params) => {
        base.getAll("step/Get/" + params.stepID).then((res) => {
            if (res != null && res !== undefined) {
                var taskID = res.fk_TaskID;
                var url =
                    process.env.REACT_APP_STEP_UPD +
                    "/" +
                    taskID +
                    "/" +
                    params.stepID +
                    "/D/step/move";
                base
                    .update(url)
                    .then((response) => {
                        if ((response.data = "step moved")) {

                            base
                                .getAll("step/Get/" + params.stepID)
                                .then((res) => {
                                    if (res.step_Label === params.step_Label) {
                                        dataSaved("cannotmove");
                                    } else {
                                        moduleDataSaved(
                                            "dataSuccess",
                                            t("Curriculumpage.StepMoveToast")
                                        );
                                    }
                                });
                        }
                        getAllSteps(taskID);
                    })
                    .catch((error) => {
                        dataError("error");
                    });
            }
        });
    };

    return (
        <div>
            {showToast.Name === "deleteDummyStep" && (
                <ErrorToastNew
                    show={showToast.show}
                    Message={t("Curriculumpage.ErrorCannotDeleteDummystep")}
                    setHasFailed={setHasFailed}
                    hasFailed={hasFailed}
                />
            )}

            {showToast.Name === "cannotmove" && (
                <ErrorToastNew
                    show={showToast.show}
                    Message={t("Curriculumpage.ItemCannotMoveToast")}
                    setHasFailed={setHasFailed}
                    hasFailed={hasFailed}
                />
            )}
            <ConfirmDeleteToast
                show={deleteStepConfirmToast.show}
                handleCancel={cancelDelete}
                Message={deleteStepConfirmToast.Message}
                handleDelete={handleDeleteStep}
            />
            <div className="ms-3 me-3 mt-0">
                <div className="text-end mb-2 me-4 mt-2">
                    <div className="p-4" />
                </div>
                <div className="gridDiv">
                    <div
                        className="ag-theme-material gridHtWd mt-3 "
                        style={{ position: 'relative' }}
                    >
                        <AgGridReact
                            columnDefs={stepGridColumnHeadings}
                            rowData={updatedStepData}
                            suppressMenuHide={true}
                            overlayNoRowsTemplate="No records found"
                            defaultColDef={gridDefaultColDef}
                            pagination={true}
                            paginationPageSizeSelector={[10, 20, 50, 200]}

                            paginationPageSize={pageSize}
                            suppressScrollOnNewData={true}
                            resizeable={true}
                            animateRows={true}
                        />
                    </div>
                </div>
                {stepsModal && <StepModal closeStepModal={closeStepsModal} stepData={stepData} setStepData={setStepData} create={create} saveStepModal={saveStepModal} stepGridData={stepGridData} selectedHazards={selectedHazards} />}
            </div>
        </div>
    );
}

export default StepTab;
