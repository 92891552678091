import React, { useState, useEffect, useRef, useCallback } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import "../../Content/stylesheet/ag-grid-style.css";
import SuccessToastNew from "../Toast/SuccessToastNew";
import ErrorToastNew from "../Toast/ErrorToastNew1";
import base from "../../Services/BaseService";
import "../../Content/stylesheet/assessment.css";
import "../../Content/stylesheet/ManageData.css";
import CreateAssessment from "./createAssessment";
import ConfirmDeleteToast from "../Toast/ConfirmDeleteToast";
import InfoForCompletedEmployees from "./InfoForCompletedEmployees";
import AssignEmployee from "./assignEmployee";
import Infobox from "../Toast/Infobox";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { Service } from "../../Service";
import TitleBanner from "../Shared/Layout/TitleBanner";
import { People32, Edit32, DownloadBasic32 as Download32, Checklist32, Trash32 } from "@bphxd/ds-core-react/lib/icons";
import LoadingView from "../Shared/Layout/LoadingView";
import { createRoot } from "react-dom/client";
import NewModal from './RecordResults/recordResultsModal'
import { useMyTrainingContext } from '../Shared/myTrainingContext'
import { useAssessmentContext } from '../Shared/assessmentContext'
import { useUser } from "../Shared/userContext";
import DismissToast from "../Toast/DismissToast";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';

function ManageAssessment(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const gridRef = useRef();
  const [showNewModal, setShowNewModal] = useState(false);
  const { updateAssessmentCount } = useAssessmentContext();
  const { updateMyTrainingCount } = useMyTrainingContext()
  const { languages } = useUser()

  const initGridVar = useCallback(() => {
    setGridDefaultColDef({
      flex: 1,
      minWidth: 50,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      wrapText: false,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("assessment.LandingGridHeader.MODULE"),
        headerTooltip: t("assessment.LandingGridHeader.MODULE"),
        field: "task_Number",
        sort: "asc",
        suppressMovable: true,
        minWidth: 80,
        maxWidth: 175,
        cellClass: 'right-border',
        headerClass: 'right-border',
        pinned: true,

      },
      {
        headerName: t("assessment.LandingGridHeader.ASSESSMENT_TYPE"),
        headerTooltip: t("assessment.LandingGridHeader.ASSESSMENT_TYPE"),
        field: "assessment_Type",
        // cellClass: "alignCenter",
        suppressMovable: true,
        minWidth: 125,
        comparator: customComparator,
      },
      {
        headerName: t("assessment.LandingGridHeader.DESCRIPTION"),
        headerTooltip: t("assessment.LandingGridHeader.DESCRIPTION"),
        field: "description",
        suppressMovable: true,
        minWidth: 200,

        comparator: customComparator,
      },
      {
        headerName: t("assessment.LandingGridHeader.ASSIGN_EMPLOYEE"),
        headerTooltip: t("assessment.LandingGridHeader.ASSIGN_EMPLOYEE"),
        field: "",
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        minWidth: 125,

        cellRenderer: AssignCellRenderer,
        suppressMovable: true,
        sortable: false,
      },
      {
        headerName: t("assessment.LandingGridHeader.PRODUCE_DOCUMENT"),
        headerTooltip: t("assessment.LandingGridHeader.PRODUCE_DOCUMENT"),
        field: "",
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        minWidth: 125,

        filter: false,
        cellRenderer: FileCellRenderer,
        suppressMovable: true,
        sortable: false,
      },
      {
        headerName: t("assessment.LandingGridHeader.TRAINER ACTION"),
        headerTooltip: t("assessment.LandingGridHeader.TRAINER ACTION"),
        field: "actionCount",
        headerClass: 'ag-center-header',
        cellRenderer: TranierActionRender,
        suppressMovable: true,
        minWidth: 125,
        comparator: customComparator,
      },
      {
        headerName: t("assessment.LandingGridHeader.RECORD_TRAINING_RESULT"),
        headerTooltip: t("assessment.LandingGridHeader.RECORD_TRAINING_RESULT"),
        field: "",
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        minWidth: 125,

        filter: false,
        cellRenderer: RecordCellRenderer,
        suppressMovable: true,
        sortable: false,
      },
      {
        headerName: t("assessment.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("assessment.LandingGridHeader.EDIT_DELETE"),
        field: "",
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        minWidth: 200,
        maxWidth: 200,
        filter: false,
        cellRenderer: EditCellRenderer,
        suppressMovable: true,
        sortable: false,
      },
    ]);
    // eslint-disable-next-line
  }, []);


  const assessmentNode = useRef("");
  useEffect(() => {
    initGridVar();
    getAllAssessmentdata();
  }, [initGridVar]);

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
  }, [initGridVar]);

  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [gridData, setGridData] = useState();
  const [createassessment, setcreateassessment] = useState(false);
  const [editassessment, setEditassessment] = useState(false);
  const [empdata, setempdata] = useState({});
  const [empdata1, setempdata1] = useState({});
  const [deleteitem, setDeleteitem] = useState({});
  const [edititem, setEdititem] = useState({});
  const [confirmdelete, setConfirmdelete] = useState(false);
  const [assignemployee, setassignemployee] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isemployee, setIsemployee] = useState(false);
  const [infomsg, setInfomsg] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const [isDocDownloadStarted, setIsDocDownloadStarted] = useState(false);
  const [showInfoPopup, setshowInfoPopup] = useState(false);
  const [completedAssessment, setCompletedAssessment] = useState([]);
  const [trainerActions, setTrainerActions] = useState([]);
  const [pendingAssessment, setPendingAssessment] = useState([]);
  const [totalEmployeesAssigned, setTotalEmployeesAssigned] = useState(0);
  const [selectedAssessment, setSelectedAssessment] = useState([]);
  const [hasFailed, setHasFailed] = useState(false);
  const [hasFailedMessage, setHasFailedMessage] = useState("");

  function Producedocument(data, url, docNo) {
    if (docNo === 0) {
      setIsDocDownloadStarted(true);
      setTimeout(() => {
        setIsDocDownloadStarted(false);
      }, 5000);
    }
    base.getReport(`${url + "/" + data.assessmentID}`).then(({ responseData, fileName }) => {
      fileDownload(responseData, fileName);
    })
      .catch((err) => {
        setIsDocDownloadStarted(false);
        setHasFailed(true);
        setHasFailedMessage(t("ErrorMessages.Something"));
      });
  }

  class AssignCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons2");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <People32
            className="pointer"
            onClick={() => {
              setempdata(params.node.data);
              setassignemployee(true);
            }}
          />
        </>
      );
    }
    getGui() {
      return this.eGui;
    }
  }

  class FileCellRenderer {
    init(params) {
      var divElement = document.createElement("span");
      divElement.classList.add("gridcellicons");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        params.data.assessment_Type === "EXT" ||
          params.data.assessment_Type === "NON" ||
          params.data.xTrained < 0 ? (
          <div className="pt-3">
            <Download32 style={{ position: "fixed", right: "48%", color: "#cecece" }} />
          </div>
        ) : (
          <UncontrolledDropdown direction="down" className="pointer" style={{ position: "fixed", right: "48%", color: "#111" }}>
            <DropdownToggle className="kebabdropdown btnpadding">
              <Download32 />
            </DropdownToggle>
            <DropdownMenu style={{ position: "static" }} container="body">
              <DropdownItem
                onClick={() => {
                  TPO(
                    params.node.data,
                    "assessmentdata/questions/download"
                  );
                }}
              >
                {t("assessment.Test Paper Output")}
              </DropdownItem>
              {params.data.assessment_Type !== "DAT" ? (
                <DropdownItem
                  onClick={() => {
                    checkassignedemployees(
                      params.node.data,
                      "assessmentdata/answers/download"
                    );
                  }}
                >
                  {t("assessment.Model Answer Sheet")}
                </DropdownItem>
              ) : (
                ""
              )}
              {params.data.assessment_Type === "TAR" ? (
                <DropdownItem
                  onClick={() => {
                    checkassignedemployees(
                      params.node.data,
                      "assessmentdata/operatortaskflow"
                    );
                  }}
                >
                  {t("assessment.Operator Task Flow")}
                </DropdownItem>
              ) : (
                ""
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      );
    }
    getGui() {
      return this.eGui;
    }
  }

  class RecordCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <Checklist32 className="pointer"
            onClick={() => {
              isyearvalid(params.node.data);
            }}
          />
        </>
      );
    }
    getGui() {
      return this.eGui;
    }
  }

  class TranierActionRender {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("text-center");
      this.eGui = divElement;

      var trainerActionCount = params.node.data.trainerAction;
      var assignedCount = params.node.data.employeeAction + params.node.data.trainerAction + params.node.data.complete
      var isClick = assignedCount === 0 ? false : true;

      var statusClass = ""
      switch (trainerActionCount) {
        case 0:
          statusClass = "grayBack";
          break;
        default:
          statusClass = "amberBack";
          break;
      }

      if (isClick) {
        const root = createRoot(divElement);
        root.render(
          <>
            <div className="text-center" style={{ cursor: "pointer", position: "fixed", right: "35%" }}
              onClick={() => {
                base.getAll(`assessmentemployee/qry/employee/status/${params.node.data.assessmentID}`).then((res) => {
                  setempdata1(res);
                  setSelectedAssessment(params.node.data);
                  setCompletedAssessment(res.filter(d => d.employee_Status === 3));
                  setTrainerActions(res.filter(d => d.employee_Status === 2));
                  setPendingAssessment(res.filter(d => d.employee_Status === 1));
                  setTotalEmployeesAssigned(assignedCount);
                  setshowInfoPopup(true);
                });
              }}
            >
              <span className={statusClass + " assessment_notification"}>
                {trainerActionCount}
              </span>
            </div>
          </>
        );
      } else {
        <></>;
      }
    }
    getGui() {
      return this.eGui;
    }
  }

  function checkemployees(para) {
    setIsLoading(true);
    setempdata(para);
    var promises = []
    const firstPromise = base.get("result/qry", para.assessmentID)
    promises.push(firstPromise)
    firstPromise.then((res) => {
      if (res === "MSG_NOOPSASSESS") {
        setIsLoading(false);

        setErrmsg(
          //"No employees assigned to Assessment. Please assign employees to proceed"
          t("ErrorMessages.NoEmp")
        );
        setError(true);
      } else if (res === "MSG_NOASSESSQTN") {
        setIsLoading(false);

        setErrmsg(
          //"No questions assigned to Assessment. Please assign questions to proceed"
          t("ErrorMessages.NoQ")
        );
        setError(true);
      } else if (res === "MSG_ASSESSNE") {
        setIsLoading(false);

        setErrmsg(
          t("ErrorMessages.NoEmpModule")
          //"No employees assigned to the module. Please assign employees to proceed"
        );
        setError(true);
      } else {
        if (
          para.assessment_Type === "NON" ||
          para.assessment_Type === "EXT"
        ) {
          setShowNewModal(true);
        } else if (
          para.assessment_Type === "CAS" ||
          para.assessment_Type === "TAR"
        ) {
          // const secondPromise = base.get(`assessment/Get/${para.assessmentID}`, i18n.language.toLowerCase())
          // promises.push(secondPromise)
          // secondPromise.then((res) => {
          if (assessmentNode.xTrained < 0) {
            setShowNewModal(true);
          } else {
            if (para.assessment_Type === "TAR") {
              // const thirdPromise = base.get("AssessmentData/IsAllMCQAssessment", para.assessmentID + "/" + true)
              // promises.push(thirdPromise)
              // thirdPromise.then((response) => {
              // let tskID = para.taskID;
              if (assessmentNode.current.isMCQ === 1) {
                // setRecordresultstar(true);
                setShowNewModal(true);
              } else {
                setShowNewModal(true);
              }
              // });
            } else setShowNewModal(true);
          }
          // });
        } else if (para.assessment_Type === "DAT") {
          if (para.taskID === "GBL10057") {
            setShowNewModal(true);
          } else {
            setShowNewModal(true);
          }
        } else {
          setShowNewModal(true);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    })
      .catch((err) => {
        setHasFailed(true);
        setHasFailedMessage(t("ErrorMessages.Something"));
      });
  }

  function msgassessne() {
    setErrmsg(
      //"No employees assigned to the module. Please assign employees to proceed"
      t("ErrorMessages.NoEmpModule")
    );
    setError(true);
  }

  function isdeleteacceptable(param) {
    base.get("resultcompetency/Qry1", param.assessmentID).then((res) => {
      if (res.length < 1) {
        setConfirmdelete(true);
        setDeleteitem(param);
      } else {
        setErrmsg(t("ErrorMessages.OopsPartialkey"));
        setError(true);
      }
    });
  }

  function checkassignedemployees(para, data, langID, docNo) {
    Producedocument(para, data, docNo);
  }

  const TPO = (para, data) => {
    base.get("result/qry", para.assessmentID).then((res) => {
      if (res === "MSG_NOOPSASSESS") {
        setInfomsg(
          //"No employees assigned to Assessment. Please assign employees to proceed"
          t("ErrorMessages.NoEmp")
        );
        setIsemployee(true);
      } else {
        if (para.assessment_Type === "CAS" || para.assessment_Type === "TAR") {
          for (let i = 0; i < languages.length; i++) {
            checkassignedemployees(para, data + `/${languages[i].languageID}`, languages[i].languageID, i)
          }
        }
        else {
          checkassignedemployees(para, data + "/en-gb", "en-gb")
        }

      }
    })
      .catch((err) => {
        setHasFailed(true);
        setHasFailedMessage(t("ErrorMessages.Something"));
      });
  }

  class EditCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <Edit32 onClick={() => { setEditassessment(true); setEdititem(params.node.data); }} className="pointer" />
          <Trash32 onClick={() => { isdeleteacceptable(params.node.data); }} className="pointer trashOnSmallerScreen ms-2" />
        </>
      );
    }
    getGui() {
      return this.eGui;
    }
  }
  const [modules, setModules] = useState([]);

  useEffect(() => {
    let url = "";
    url = `task/qry/null/null/Y/null`;
    base.getAll(url, "assessment").then((res) => {
      setModules(res);
    });
  }, []);


  const getAllAssessmentdata = () => {
    base.update("/assessmentemployeeresultscount/upd/checkAll")
      .then((resp) => {
        return resp;
      })
      .then((data) => {
        base.getAll("/assessment/qry1").then((res) => {
          var data = res.filter((x) => x.rel_rec !== 0);
          for (let i = 0; i < data.length; i++) {

            var isClick =
              data[i].trainerAction === 0
                ? false
                : true;
            var count =
              data[i].trainerAction;


            if (!isClick) count = "";

            data[i].actionCount = count?.toString();
          }

          setGridData(data);
          setIsLoading(false)
        });
      });
  };
  const customComparator = (valueA, valueB) => {
    valueA =
      typeof valueA === "undefined" || valueA === null || valueA === ""
        ? ""
        : valueA;
    valueB =
      typeof valueB === "undefined" || valueB === null || valueB === ""
        ? ""
        : valueB;
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  function Successshow() {
    setSuccess(true);
    document.addEventListener("mousedown", DataSavingToastonClick);
  }

  function Errorshow() {
    setError(true);
    document.addEventListener("mousedown", DatanotsavedToastonClick);
  }

  function DatanotsavedToastonClick() {
    setError(false);
  }

  function DataSavingToastonClick() {
    setSuccess(false);
  }

  function isyearvalid(params) {
    // var date = new Date();
    assessmentNode.current = params;
    checkemployees(params);
  }

  function deleteAssessment(data) {

    base
      .delete("assessment/Del", data.id)
      .then((res) => {
        getAllAssessmentdata();
        setConfirmdelete(false);
        updateAssessmentCount();
        updateMyTrainingCount();
        setMessage(t("ErrorMessages.DeleteSuccess"));
        Successshow();
      })
      .catch((err) => {
        setErrmsg(
          //"Oops! Data not deleted. Try again!"
          t("ErrorMessages.NotDeleted")
        );
        Errorshow();
      });
  }

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  return (
    <>
      {isLoading && <LoadingView />}
      <div>
        <SuccessToastNew show={success} Message={message} form={false} />
        <SuccessToastNew
          form={false}
          show={isDocDownloadStarted}
          Message={"Document downloading"}
        />
        <ErrorToastNew
          show={hasFailed}
          setHasFailed={setHasFailed}
          Message={hasFailedMessage}
        />
        <DismissToast
          show={error}
          Message={errmsg}
          handleCancel={() => {
            setError(false);
          }}
        />
        {showInfoPopup ? (
          <InfoForCompletedEmployees
            show={true}
            data={empdata1}
            completedAssessment={completedAssessment}
            trainerActions={trainerActions}
            pendingAssessment={pendingAssessment}
            totalEmployeesAssigned={totalEmployeesAssigned}
            selectedAssessment={selectedAssessment}
            close={() => {
              setshowInfoPopup(false);
              setempdata1({});
            }}
            isyearvalid={() => {
              isyearvalid(selectedAssessment);
            }}
          ></InfoForCompletedEmployees>
        ) : (
          ""
        )}

        {confirmdelete ? (
          <ConfirmDeleteToast
            show={true}
            Message={t("ErrorMessages.DeleteSure")}
            handleCancel={() => {
              setConfirmdelete(false);
            }}
            handleDelete={() => {
              deleteAssessment(deleteitem);
            }}
          ></ConfirmDeleteToast>
        ) : (
          ""
        )}
        {createassessment ? (
          <CreateAssessment
            closed={() => {
              setcreateassessment(false);
            }}
            mode="create"
            update={() => {
              getAllAssessmentdata();
            }}
            noquestion={(message) => {
              setInfomsg(
                message.length > 0
                  ? t("ErrorMessages.NoAdquate")
                  : t("ErrorMessages.NoEnoughQ") //"Not enough questions to this module. Please assign questions to this module before proceedings"
              );
              setIsemployee(true);
            }}
            success={() => {
              Successshow();
              setMessage(
                t("ErrorMessages.AssSuccess")
              );
            }}
            error={() => {
              setError(true);
            }}
            moduletasks={modules}
          ></CreateAssessment>
        ) : (
          ""
        )}
        {editassessment ? (
          <CreateAssessment
            closed={() => {
              setEditassessment(false);
            }}
            mode="edit"
            update={() => {
              getAllAssessmentdata();
            }}
            success={() => {
              Successshow();
              setMessage(
                t("ErrorMessages.SavedSucc")
              );
            }}
            error={() => {
              setError(true);
            }}
            selectedval={edititem}
            moduletasks={modules}
          ></CreateAssessment>
        ) : (
          ""
        )}
        {assignemployee ? (
          <AssignEmployee
            setIsLoading={setIsLoading}

            msgassesserr={() => {
              msgassessne();
            }}
            closed={() => {
              setassignemployee(false);
            }}
            reload={() => {
              getAllAssessmentdata();
            }}
            data={empdata}
          ></AssignEmployee>
        ) : (
          ""
        )}


        <Infobox
          message={infomsg}
          show={isemployee}
          close={() => {
            setIsemployee(false);
            setInfomsg("");
          }}
        ></Infobox>

        <div>

          <TitleBanner
            heading={t("assessment.Assessment Menu")}
            subheading={""}
            infoText={""}
            breadcrumbArray={[t("Common.Home"), t("assessment.Assessment Menu")]}
            showButton1={false}
            functionButton2={() => setcreateassessment(true)}
            showButton2={true}
            showButton3={false}
            textButton2={t("assessment.Create Assessment")}
            buttonHeading={""}
          />
        </div>
        <div className="ps-7 pe-7 pb-5 pt-4">
          <div className="gridDiv">
            {showNewModal && (
              <NewModal setShowNewModal={setShowNewModal} assessmentNode={assessmentNode.current} setIsLoading={setIsLoading}
                handleClose={() => {
                  setIsLoading(true)
                  setShowNewModal(false);
                  updateAssessmentCount();
                  updateMyTrainingCount();
                  getAllAssessmentdata();
                }}
              />
            )
            }

            <div
              className="ag-theme-material gridHtWd "
              style={{ width: "100%", position: 'relative' }}>
              <AgGridReact
                ref={gridRef}
                columnDefs={gridColDefs}
                rowData={gridData}
                suppressMenuHide={true}
                overlayNoRowsTemplate="No records found"
                defaultColDef={gridDefaultColDef}
                pagination={true}
                paginationPageSize={10}
                suppressScrollOnNewData={true}
                paginationPageSizeSelector={[10, 20, 50]}
                components={{
                  EditCellRenderer: EditCellRenderer,
                  AssignCellRenderer: AssignCellRenderer,
                  FileCellRenderer: FileCellRenderer,
                  RecordCellRenderer: RecordCellRenderer,
                }}
                onFilterChanged={onFilterChanged}
                postProcessPopup={postProcessPopup}
                suppressHorizontalScroll={false}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageAssessment;
