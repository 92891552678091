import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import base from "../../../Services/BaseService";
import { Modal, Input, Label } from '@bphxd/ds-core-react';
import { Col, Row } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import AssignSiteManagerPopUp from "./AssignSMPopUp";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import "../../../Content/stylesheet/Location.css"
import "ag-grid-community";
import "ag-grid-enterprise";
//import "ag-grid-community/dist/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import "../../../Content/stylesheet/ag-grid-style.css";
import CustomCheckboxFilter from "../../Shared/CustomCheckboxFilter";
import { Service } from "../../../Service";
import { createRoot } from "react-dom/client";

const AssignSiteManager = (props) => {
  const { t } = useTranslation();
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridData, setGridData] = useState();
  const pageSize = 10;
  const [ddlReqBy, setddlReqBy] = useState([]);
  const [assignManagerPopUp, setassignManagerPopUp] = useState(false);
  const [assignManagerPopUpFeed, setassignManagerPopUpFeed] = useState({});
  const [showToast, setShowToast] = useState({ Name: "", show: false });

  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initGridVar();
    getAllUsers();
    getAPIData();
    // eslint-disable-next-line
  }, []);

  //Loading data for Requested By Drop Down
  const getAPIData = () => {

    base.getAll("drop/frm/ANON_REQB").then((res1) => {
      setddlReqBy(res1);
    });
  };


  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  //Setting up of AG Grid
  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      wrapText: true,
      resizable: false,
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: "",
        field: "",
        minWidth: 80,
        maxWidth: 80,
        resizable: false,
        suppressMovable: true,
        sortable: false,
        filter: CustomCheckboxFilter,
        cellRenderer: CheckboxCellRenderer,
      },
      {
        headerName: t("Regionpage.USER NAME"),
        headerTooltip: t("Regionpage.USER NAME"),
        field: "userDisplayName",
        resizable: false,
        suppressMovable: true,
        sort: "asc",
        comparator: customComparator,
        cellRenderer: UserHeadlineCellRenderer,
      },
      {
        headerName: t("Regionpage.USER EMAIL ID"),
        headerTooltip: t("Regionpage.USER EMAIL ID"),
        field: "email",
        resizable: false,
        suppressMovable: true,
        comparator: customComparator,
        cellRenderer: UserHeadlineCellRenderer,
      },
    ]);
  };

  //Setting up of AG Grid Row Data
  class UserHeadlineCellRenderer {
    init(params) {
      var element = document.createElement("span");
      element.innerHTML = params.value;
      element.classList.add("headline");
      this.eGui = element;
    }
    getGui() {
      return this.eGui;
    }
  }

  //Fetching list of all users
  const getAllUsers = () => {
    var locID = props.assignManagerFeed.locationID;

    base.getAll("user/qry1/manager/" + locID).then((res) => {
      if (res !== undefined) {
        var data = res
          .filter((x) => x.id !== "" && x.id != null)
          .map((userData) => {
            return { ...userData, isAssigned: userData.isSiteManager };
          });

        setGridData(data);
      }
    });
  };

  //Setting up of Checkbox renderer in AG Grid
  class CheckboxCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridCrudIcons");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <Input
            type="checkbox"
            id={params.data.userID}
            style={{ left: "100px" }}
            checked={params.node.data.isSiteManager === "true" ? true : false}
            onChange={() => callAssignManagerPopUp(params.node.data)}
          />

        </>);
    }
    getGui() {
      return this.eGui;
    }
  }

  //Function invoked when user clicks on checkbox, to open new modal pop up
  const callAssignManagerPopUp = (params) => {
    var assignManagerPopUpFeed = {
      fk_UserID: params.userID,
      fk_LocationID: props.assignManagerFeed.locationID,
    };
    if (
      document.getElementById(params.userID) != null &&
      document.getElementById(params.userID) !== undefined
    ) {
      var checked = document.getElementById(params.userID).checked;
      if (checked) {
        setassignManagerPopUpFeed(assignManagerPopUpFeed);
        setassignManagerPopUp(true);
      } else if (!checked) {
        setassignManagerPopUpFeed(assignManagerPopUpFeed);
        setassignManagerPopUp(false);
        RemoveassignManagerUser(params);
      }
    }
  };

  //Function invoked to unassign a user from site manager
  const RemoveassignManagerUser = (params) => {
    var assignManager = {
      fk_UserID: params.userID,
      fk_LocationID: props.assignManagerFeed.locationID,
      assign_Indicator: "MANAGER",
      text01: "",
      text02: "",
      text03: "",
      text04: null,
      rel_rec: 0,
    };

    base.add(process.env.REACT_APP_DEANONYMISE_ADD, assignManager)
      .then((response) => {
        getAllUsers();
        removeassignManagerSuccess();
      })
      .catch((error) => {
        console.log(error)
      });
  };

  function DataSavingToastonClick() {
    setShowToast(false);
  }

  const customComparator = (valueA, valueB) => {
    valueA = typeof valueA === "undefined" || valueA === null ? "" : valueA;
    valueB = typeof valueB === "undefined" || valueB === null ? "" : valueB;
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  const cancelHandler = () => {
    props.close(false);
  };

  const closeassignManagerPopUp = (val) => {
    setassignManagerPopUp(val);
  };

  //Toast Messages Settings
  const assignManagerSuccess = () => {
    setShowToast({ Name: "successAssignManager", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const removeassignManagerSuccess = () => {
    setShowToast({ Name: "successRemoveManager", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  return (
    <div>
      <Modal
        isOpen={props.isopen}
        centered
        className="modal-70"
      >
        <Modal.Header
          className="comm-header-bgPosition modalPopupBGColour "
          onToggleOpen={cancelHandler}
        >
          <label
            className="modalHeaderGlobal ms-5"
          >
            {t("Regionpage.Assign Site Manager")}
          </label>
          {showToast.Name === "successAssignManager" && (
            <SuccessToastNew
              form={true}
              show={showToast.show}
              Message={t("Regionpage.assignmanagerSuccess")}
            />
          )}
          {showToast.Name === "successRemoveManager" && (
            <SuccessToastNew
              form={true}
              show={showToast.show}
              Message={t("Regionpage.removeassignmanagerSuccess")}
            />
          )}
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Label>
                {t("Regionpage.Location Code")}
              </Label>
              <Input
                disabled
                value={props.assignManagerFeed.locationID}
              ></Input>
            </Col>
            <Col>
              <Label>
                {t("Regionpage.Location")}
              </Label>
              <Input
                disabled
                value={props.assignManagerFeed.location_Description}
              ></Input>
            </Col>
          </Row>
          <div className="pt-4">
            <div className="gridDiv">

              <div
                className="ag-theme-material gridHtWd "
                style={{ position: 'relative' }}
              >

                <AgGridReact
                  ref={gridRef}
                  columnDefs={gridColDefs}
                  rowData={gridData}
                  suppressMenuHide={true}
                  overlayNoRowsTemplate="No Records found"
                  defaultColDef={gridDefaultColDef}
                  pagination={true}
                  paginationPageSizeSelector={[10, 20, 50]}

                  paginationPageSize={pageSize}
                  suppressScrollOnNewData={true}
                  suppressHorizontalScroll={false}
                  resizeable={true}
                  onFilterChanged={onFilterChanged}
                  postProcessPopup={postProcessPopup}
                />

              </div>
            </div>

          </div>

          <div className="mt-8"></div>
          {assignManagerPopUp ? (
            <AssignSiteManagerPopUp
              getAllUser={getAllUsers}
              close={closeassignManagerPopUp}
              AssignSiteManagerFeed={assignManagerPopUpFeed}
              ReqByList={ddlReqBy}
              isopen={assignManagerPopUp}
              saveSuccess={assignManagerSuccess}
            ></AssignSiteManagerPopUp>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AssignSiteManager;
