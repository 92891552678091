import { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import base from "../../../Services/BaseService";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew from "../../Toast/ErrorToastNew";
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/common.css";
import "../../../Content/stylesheet/manageemployees.css";
import AssignTraining from "./AssignModuleToEmployeeLandingNew";
import React from "react";
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/common.css";
import "ag-grid-community";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/assignModuleToEmployee.css";
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { FileAdd32 } from "@bphxd/ds-core-react/lib/icons";
import LoadingView from "../../Shared/Layout/LoadingView";
import { createRoot } from "react-dom/client";

const AssignModuleToEmployee = (props) => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [gridData, setGridData] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [selectedempFeed, setselectedEmpFeed] = useState({});
  const [trngModule, setTrngModule] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initGridVar();
    getAllEmp();
    // eslint-disable-next-line
  }, []);

  // modify pageSize

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  //Setting up AG Grid

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      wrapText: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("AssignModulepage.LandingGridHeader.EMPLOYEE_NAME"),
        headerTooltip: t("AssignModulepage.LandingGridHeader.EMPLOYEE_NAME"),
        field: "emp_DisplayName",
        minWidth: 150,
        maxWidth: 400,
        resizable: false,
        suppressMovable: true,
        sort: "asc",
      },
      {
        headerName: t("AssignModulepage.LandingGridHeader.JOB_TITLE"),
        headerTooltip: t("AssignModulepage.LandingGridHeader.JOB_TITLE"),
        field: "jobTitle",
        minWidth: 0,
        maxWidth: 250,
        headerClass: 'sm-hide-header', cellClass: 'sm-hide-cell',
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("AssignModulepage.LandingGridHeader.LOCATION"),
        headerTooltip: t("AssignModulepage.LandingGridHeader.LOCATION"),
        field: "fk_LocationID",
        minWidth: 100,
        maxWidth: 300,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("AssignModulepage.LandingGridHeader.ASSIGN_MODULE"),
        headerTooltip: t("AssignModulepage.LandingGridHeader.ASSIGN_MODULE"),
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 150,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressHeaderMenuButton: true, cellRenderer: PeopleIconCellRenderer,
      },
    ]);
  };

  //Setting Up Assign Module icon
  class PeopleIconCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <FileAdd32 onClick={() => callSetupModule(params.node.data)} className="pointer" />
        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  const closeCreateManageEmp = (val) => {
    setTrngModule(val);
  };

  const callSetupModule = (event) => {
    if (
      event.employeeID != null &&
      event.employeeID !== "" &&
      event.employeeID !== "undefined" &&
      event.employeeID !== undefined
    ) {
      var assignTrng = {
        employeeID: event.employeeID,
        emp_DisplayName: event.emp_DisplayName,
        fk_LocationID: event.fk_LocationID,
        location_Description: event.location_Description,
      };
      setselectedEmpFeed(assignTrng);
      setTrngModule("true");
    } else {
      setTrngModule("false");
    }
  };

  //Toast messages settings

  const assignSuccess = () => {
    setShowToast({ Name: "successAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const assignDateSuccess = () => {
    setShowToast({ Name: "successdateAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const unassignSuccess = () => {
    setShowToast({ Name: "successUnassign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const assignFailure = () => {
    setShowToast({ Name: "errorAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const assignDateFailure = () => {
    setShowToast({ Name: "errordateAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const unassignFailure = () => {
    setShowToast({ Name: "errorUnassign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };


  function DataSavingToastonClick() {
    setShowToast(false);
  }

  //Fetching all employees for the AG Grid
  const getAllEmp = () => {

    setTimeout(() => {
      base.getAll("employee/qry").then((res) => {
        var data = res.filter(
          (x) =>
            x.rel_rec !== 0 &&
            x.employeeID !== "" &&
            x.employeeID != null &&
            x.fk_LocationID !== "" &&
            x.fk_LocationID != null
        );
        setGridData(data);
      });
    }, 300);
  };

  return (
    <>
      <div>
        {isloader &&
          <LoadingView />
        }
        <div>
          <TitleBanner
            heading={t("AssignModulepage.Assign_Modules_to_Employee")}
            subheading={""}
            infoText={""}
            breadcrumbArray={[t("Common.Manage Data"), t("AssignModulepage.Assign_Modules_to_Employee")]}
            showButton1={false}
            showButton2={false}
            showButton3={false}
          />

          {showToast.Name === "successdateAssign" && (
            <SuccessToastNew
              show={showToast.show}
              Message={t("Training.DateAssignSuccess")}
            />
          )}

          {showToast.Name === "errorAssign" && (
            <ErrorToastNew
              show={showToast.show}
              Message={t("Training.AssignFailure")}
            />
          )}
          {showToast.Name === "errordateAssign" && (
            <ErrorToastNew
              show={showToast.show}
              Message={t("Training.DateAssignFailure")}
            />
          )}
          {showToast.Name === "errorUnassign" && (
            <ErrorToastNew
              show={showToast.show}
              Message={t("Training.UnAssignFailure")}
            />
          )}

        </div>

        <div className="ps-7 pe-7 pb-3 pt-4">
          <div className="gridDiv">

            <div
              className="ag-theme-material gridHtWd "
              style={{ position: 'relative' }}
            >
              <AgGridReact
                ref={gridRef}
                columnDefs={gridColDefs}
                rowData={gridData}
                suppressMenuHide={true}
                overlayNoRowsTemplate="No records found"
                defaultColDef={gridDefaultColDef}
                pagination={true}
                paginationPageSizeSelector={[10]}
                paginationPageSize={10}
                suppressScrollOnNewData={true}
                suppressHorizontalScroll={false}
                onFilterChanged={onFilterChanged}
              ></AgGridReact>

            </div>
          </div>

        </div>
        <div className="col-lg-12 mb-3 p1-0 pr-1 " style={{ width: "98.9%" }}>
          <div className="mt-8"></div>

          {trngModule ? (
            <AssignTraining
              close={closeCreateManageEmp}
              selectedempFeed={selectedempFeed}
              assignSuccess={assignSuccess}
              assignFailure={assignFailure}
              unassignSuccess={unassignSuccess}
              unassignFailure={unassignFailure}
              assignDateSuccess={assignDateSuccess}
              assignDateFailure={assignDateFailure}
              reload={() => { setIsloader(true); }}
              reloadDone={() => { setIsloader(false); }}
              isopen={true}
            ></AssignTraining>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default AssignModuleToEmployee;
