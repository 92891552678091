import { useEffect, useState, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import base from "../../../Services/BaseService";
import CreateThreats from "./CreateThreats";
import React from "react";
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/ManageData.css";
import "ag-grid-community";
import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { Edit32 } from "@bphxd/ds-core-react/lib/icons";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew from "../../Toast/ErrorToastNew1";
import { Messages } from "../../../Constants";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import { createRoot } from "react-dom/client";
import { useUser } from "../../Shared/userContext";

const ManageThreats = (props) => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [gridData, setGridData] = useState();
  const { languagePreference } = useUser();

  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  //Setting up of AG Grid Data Cell Renderer
  class ThreatDescriptionCellRenderer {
    init(params) {
      var element = document.createElement("span");
      element.innerHTML = params.value;
      element.classList.add("headline");
      this.eGui = element;
    }
    getGui() {
      return this.eGui;
    }
  }
  //Setting up of AG Grid Edit and Delete Icon Cell Renderer
  class ThreatEditCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      const root = createRoot(divElement)
      root.render(
        <>
          <Edit32
            onClick={() => editThreatClick(params.node.data)}
            className="pointer"
          />
        </>);
    }

    getGui() {
      return this.eGui;
    }
  }

  const [gridColDefs, setGridColDefs] = useState([
    {
      headerName: t("Threatpage.LandingGridHeader.THREAT_DESCRIPTION"),
      minWidth: 200,
      maxWidth: 800,
      field: "hazard_Description",
      resizable: false,
      suppressMovable: true,
      sort: "asc",
      suppressNavigable: false,
      comparator: customComparator,
      headerTooltip: t("Threatpage.LandingGridHeader.THREAT_DESCRIPTION"),
      cellRenderer: ThreatDescriptionCellRenderer,
    },
    {
      headerName: t("Threatpage.LandingGridHeader.EDIT_DELETE"),
      headerTooltip: t("Threatpage.LandingGridHeader.EDIT_DELETE"),
      field: "",
      minWidth: 200,
      maxWidth: 200,
      cellClass: "text-center",
      headerClass: 'ag-center-header',
      filter: false,
      cellRenderer: ThreatEditCellRenderer,
      suppressMovable: true,
      sortable: false,
      suppressHeaderMenuButton: true,

    },
  ]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState({
    flex: 1,
    sortable: true,
    filter: true,
    resizable: false,
    editable: false,
    sortingOrder: ["asc", "desc"],
    wrapText: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    autoHeight: true,
    filterParams: {
      buttons: ["clear"],
      newRowsAction: "keep",
    },
    menuTabs: ["filterMenuTab"],
  });
  const [editThreat, setEditThreat] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [deleteThreat, setDeleteThreat] = useState({});
  const [showError, setShowError] = useState(false);
  const [deleteThreatConfirmToast, setDeleteThreatConfirmToast] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    base.getAll("hazard/qry").then((res) => {
      var data = res.filter(
        (x) => x.hazard_Description !== "" && x.hazard_Description != null
      );
      setGridData(data);
    });
  }, []);

  function handleSuccessClickOutside() {
    setDeleteSuccess(false);
  }

  function handleErrorClickOutside() {
    setShowError(false);
  }

  //Function invoked when user deletes a threat
  const handleDeleteThreat = () => {
    if (deleteThreat) {
      try {
        base
          .delete(process.env.REACT_APP_THREAT_DELETE, deleteThreat.hazardID)
          .then((response) => {
            if (response === 200) {
              setDeleteSuccess(true);
              cancelDelete();
              getAllThreats();
              // setTimeout(() => setDeleteSuccess(false), 3000);
              document.addEventListener(
                "mousedown",
                handleSuccessClickOutside
              );
            }
          });
      } catch (err) {
        setShowError(true);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeleteThreatConfirmToast(false);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteThreatConfirmToast(false);
    setDeleteThreat({});
  };

  // modify pageSize

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);


  //Setting up of AG Grid
  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,

      wrapText: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("Threatpage.LandingGridHeader.THREAT_DESCRIPTION"),
        minWidth: 200,
        maxWidth: 800,
        field: "hazard_Description",
        resizable: false,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        sort: "asc",
        suppressNavigable: false,
        comparator: customComparator,
        headerTooltip: t("Threatpage.LandingGridHeader.THREAT_DESCRIPTION"),
        cellRenderer: ThreatDescriptionCellRenderer,
      },
      {
        headerName: t("Threatpage.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("Threatpage.LandingGridHeader.EDIT_DELETE"),
        field: "",
        minWidth: 200,
        maxWidth: 200,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        cellRenderer: ThreatEditCellRenderer,
        suppressMovable: true,
        sortable: false,
        suppressHeaderMenuButton: true,
      },
    ]);
  };

  const editThreatClick = (threat) => {
    base.get('hazard/frm', threat.hazardID).then((response) => {
      if (response !== undefined) {
        setEditThreat(response);
        setEditModal(true);
      }
    });
  };


  const closeCreateManageThreats = (val) => {
    setCreateModal(val);
    setEditModal(val);
  };

  //Toast Messages Setup
  const dataSaved = () => {
    setShowToast({ Name: "success", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataNotSaved = () => {
    setShowToast({ Name: "error", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataCreated = () => {
    setShowToast({ Name: "successCreate", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataNotCreated = () => {
    setShowToast({ Name: "errorCreate", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  function DataSavingToastonClick() {
    setShowToast(false);
  }
  const getAllThreats = useCallback(() => {
    base.getAll("hazard/qry").then((res) => {
      var data = res.filter(
        (x) => x.hazard_Description !== "" && x.hazard_Description != null
      );
      setGridData(data);
    });
  }, []);

  useEffect(() => {
    getAllThreats()
  }, [languagePreference, getAllThreats]);

  return (
    <>
      <div>
        <TitleBanner
          heading={t("Threatpage.Threats")}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("Common.Manage Data"), t("Threatpage.Threats")]}
          showButton1={false}
          functionButton2={() => setCreateModal(true)}
          showButton2={false}
          textButton2={t("Threatpage.Create Threat")}
          buttonHeading={""}
          showButton3={false}
        />

        <ConfirmDeleteToast
          show={deleteThreatConfirmToast}
          handleCancel={cancelDelete}
          Message={Messages.ManageThreat.DeleteConfirm}
          handleDelete={handleDeleteThreat}
        />
        <SuccessToastNew
          show={deleteSuccess}
          Message={t("Threatpage.OnDeleteSuccessMsg")}
        />
        <ErrorToastNew
          show={showError}
          Message={t("Threatpage.OnDeleteErrorMsg")}
        />
        {showToast.Name === "success" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Threatpage.SaveSuccess")}
          />
        )}
        {
          showToast.Name === "delete" && (
            <ErrorToastNew
              show={showToast.show}
              Message={t("ErrorMessages.UserLevelDelete")}
            />
          )
        }
        {showToast.Name === "error" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Threatpage.SaveFailure")}
          />
        )}
        {showToast.Name === "successCreate" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Threatpage.CreateSuccess")}
          />
        )}
        {showToast.Name === "errorCreate" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Threatpage.CreateFailure")}
          />
        )}
      </div>
      <div className="ps-7 pe-7 pb-5 pt-4">
        <div className="gridDiv" >
          <div className="ag-theme-material gridHtWd " style={{ width: "100%", position: 'relative' }} >
            <span style={{ width: "1100px" }}>
              <AgGridReact
                ref={gridRef}
                columnDefs={gridColDefs}
                rowData={gridData}
                suppressMenuHide={true}
                overlayNoRowsTemplate="No records found"
                defaultColDef={gridDefaultColDef}
                pagination={true}
                paginationPageSizeSelector={[10, 20, 50]}
                paginationPageSize={10}
                suppressScrollOnNewData={true}
                onFilterChanged={onFilterChanged}
                postProcessPopup={postProcessPopup}
                suppressHorizontalScroll={false}
              ></AgGridReact>
            </span>
          </div>
        </div>
      </div>

      <div className="col-lg-12 mb-3 p1-0 pr-1 " style={{ width: "98.9%" }}>
        {createModal ? (
          <CreateThreats
            create={true}
            close={closeCreateManageThreats}
            saveSuccess={dataCreated}
            dataError={dataNotCreated}
            getAll={getAllThreats}
            isopen={createModal}
          ></CreateThreats>
        ) : (
          ""
        )}
        {editModal ? (
          <CreateThreats
            create={false}
            isopen={editModal}
            editThreat={editThreat}
            close={closeCreateManageThreats}
            saveSuccess={dataSaved}
            error={dataNotSaved}
            getAll={getAllThreats}
          ></CreateThreats>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ManageThreats;