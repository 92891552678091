import React, { useState, useEffect, useRef } from "react";
import { Col, Row, UncontrolledPopover, PopoverBody } from "reactstrap";
import { Remove32, Info24, Check32 } from "@bphxd/ds-core-react/lib/icons";
import "../../Content/stylesheet/assessment.css";
import base from "../../Services/BaseService";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../../Content/stylesheet/ag-grid-style.css";
import AssignEmployees from "../ManageData/AssignEmployeesToTask/AssignEmployeesNew";
import { Trans, useTranslation } from "react-i18next";
import CustomCheckboxFilter from "../Shared/CustomCheckboxFilter";
import { useAssessmentContext } from "../Shared/assessmentContext";
import { useMyTrainingContext } from "../Shared/myTrainingContext";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';

function AssignEmployee(props) {
  const [taskID, setTaskID] = useState("");
  const [assignTaskData, setassignTaskData] = useState({});
  const [locationID, setLocationID] = useState("");
  const { t } = useTranslation();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [gridData, setGridData] = useState();
  const [assignEmptoModule, setAssignEmptoModule] = useState(false);
  const [isAssignEmpToModuleClicked, setisAssignEmpToModuleClicked] = useState(false);
  const [selectedemployees, setselectedemployees] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [ischeckboxClicked, setIscheckboxClicked] = useState(false);
  const [newSelectedEmp, setNewSelectedEmp] = useState({});
  var dictselectedEmployeesToSendAPI = {};
  const [deSelectedEmployees, setDeSelectedEmployees] = useState({});
  var dictdelectedEmployees = {};
  var dictselectedEmployees = {};
  const [selectedEmployeesDi, setselectedEmployeesDi] = useState({});
  var selectedemp = [];
  const { updateAssessmentCount } = useAssessmentContext();
  const { updateMyTrainingCount } = useMyTrainingContext()

  const gridRef = useRef();

  useEffect(() => {
    getassignedemployee();
    setAssessment(props.data.assessment_Type);
    setTask(props.data.task_Number);
    getAllEmployees(true);
    // eslint-disable-next-line
  }, []);

  function getAllEmployees(isFirst) {
    base
      .get("assessmentemployee/frm", props.data.assessmentID)
      .then((res) => {
        selectedemp = res;
        var selectD = Object.assign(
          {},
          selectedEmployeesDi,
          dictselectedEmployees
        );
        for (let i = 0; i < res.length; i++) {
          if (
            !deSelectedEmployees.hasOwnProperty(res[i]) &&
            !selectD.hasOwnProperty(res[i]) &&
            !dictdelectedEmployees.hasOwnProperty(res[i])
          ) {
            dictselectedEmployees[res[i]] = "true";
            selectD[res[i]] = "true";
          }
        }
        setselectedEmployeesDi(selectD);
        const KeysEmpIDs = Object.keys(selectD);
        selectedemp = KeysEmpIDs;
        initGridVar();

        base.get("employeetask/qry", props.data.taskID).then((res) => {
          if (res === "MSG_ASSESSNE") {
            setGridData([]);
          } else {
            for (let i = 0; i < res.length; i++) {
              if (
                (dictselectedEmployees.hasOwnProperty(res[i].employeeID) ||
                  selectedEmployeesDi.hasOwnProperty(res[i].employeeID)) &&
                !dictdelectedEmployees.hasOwnProperty(res[i].employeeID)
              ) {
                res[i].isAssigned = "true";
              } else res[i].isAssigned = "false";
            }
            setselectedEmployeesDi(dictselectedEmployees);
            filteremployee(res);
          }
        })
          .catch((err) => {
            closemodal();
          });
      });
  }

  function filteremployee(emplist) {
    let expeemp = [];
    let overdueemp = [];
    let dueeemp = [];
    let duesoonemp = [];
    let otheremp = [];
    let filteredemp = [];
    for (let i = 0; i < emplist.length; i++) {
      if (emplist[i].training_Status === "EXPIRED") {
        expeemp.push(emplist[i]);
      } else if (emplist[i].training_Status === "OVERDUE") {
        overdueemp.push(emplist[i]);
      } else if (emplist[i].training_Status === "DUE") {
        dueeemp.push(emplist[i]);
      } else if (emplist[i].training_Status === "DUE SOON") {
        duesoonemp.push(emplist[i]);
      } else {
        otheremp.push(emplist[i]);
      }
    }
    filteredemp.push(...expeemp);
    filteredemp.push(...overdueemp);
    filteredemp.push(...dueeemp);
    filteredemp.push(...duesoonemp);
    filteredemp.push(...otheremp);

    var selectD = Object.assign({}, selectedEmployeesDi, dictselectedEmployees);
    if (selectedemp.length > 0) {
      if (Object.keys(selectD).length > 0) {
        const KeysEmpIDs = Object.keys(selectD);
        setselectedemployees(KeysEmpIDs);
        selectedemp = KeysEmpIDs;
      } else setselectedemployees(selectedemp);
    } else {
      selectedemp = selectedemployees;
    }

    let newFilteredEmp = filteredemp.map((emp) => {
      return {
        ...emp,
        isAssigned: selectedemp.includes(emp.employeeID) ? "true" : "false",
      };
    });
    setGridData(newFilteredEmp);
  }

  function getassignedemployee() {
    base
      .get("assessmentemployee/frm", props.data.assessmentID)
      .then((res) => {
        selectedemp = res;
        var selectD = Object.assign(
          {},
          selectedEmployeesDi,
          dictselectedEmployees
        );
        for (let i = 0; i < res.length; i++) {
          if (
            !deSelectedEmployees.hasOwnProperty(res[i]) &&
            !selectD.hasOwnProperty(res[i]) &&
            !dictdelectedEmployees.hasOwnProperty(res[i])
          ) {
            dictselectedEmployees[res[i]] = "true";
            selectD[res[i]] = "true";
          }
        }
        setselectedEmployeesDi(selectD);
        const KeysEmpIDs = Object.keys(selectD);
        selectedemp = KeysEmpIDs;
        initGridVar();
      });
  }

  function removeData(value) {
    selectedemp = selectedemp.filter(function (ele) {
      return ele !== value;
    });
    setselectedemployees(selectedemp);
  }


  const [assessment, setAssessment] = useState("");
  const [task, setTask] = useState("");

  function sendEmailforAssigningAssessment(empIDs) {
    var tskNum = encodeURIComponent(props.data.task_Number);
    for (let i = 0; i < empIDs.length; i++) {
      base.get("Email/SendEmailPart1", `${empIDs[i]}/${props.data.assessment_Type}/${tskNum}/${true}/${props.data.taskID}/${props.data.assessmentID}`)
    }
  }
  function closemodal(val) {
    if (isChanged) {
      props.closed();
      props.setIsLoading(true);
      var selectedFromDB = [];
      base
        .get("assessmentemployee/frm", props.data.assessmentID)
        .then((res) => {
          selectedFromDB = res;

          const KeysEmpIDs = Object.keys(newSelectedEmp);
          var newlyAssignedEmployees = KeysEmpIDs.filter(function (
            n
          ) {
            return !this.has(n);
          },
            new Set(selectedFromDB));

          var deselectedEmp = (deSelectedEmployees);
          var seselectedEmpAPI = (newSelectedEmp);
          let allData = Object.assign(
            {},
            seselectedEmpAPI,
            deselectedEmp
          );

          if (ischeckboxClicked) {
            base
              .add(
                "assessmentemployee/update/" +
                props.data.assessmentID,
                allData
              )
              .then((response) => {
                updateAssessmentCount()
                props.reload();
                updateAssessmentCount();
                updateMyTrainingCount();
                if (
                  (props.data.assessment_Type === "TAR" &&
                    props.data.isMCQ) ||
                  props.data.assessment_Type === "CAS"
                ) {
                  if (newlyAssignedEmployees.length > 0) {
                    sendEmailforAssigningAssessment(
                      newlyAssignedEmployees
                    );
                  }
                }
              });
          } else {
            props.closed();
            props.reload();
            updateAssessmentCount();
            updateMyTrainingCount();

          }
        });
    } else {
      props.closed();
    }
  }

  const cancelHandler = () => {
    if (isAssignEmpToModuleClicked) {
      updateAssessmentCount();
      props.setIsLoading(false);
      props.closed();
      props.reload();
    } else props.closed();
  };

  const OnlineEmployeeRender = (params) => {
    if (params.node.data.isOnlineEmployee === 1) {
      return (
        <Check32 />
      );
    } else {
      return (
        <Remove32 />
      );
    }
  };

  function removeDataDeselected(employeeID) {
    if (dictdelectedEmployees.hasOwnProperty(employeeID)) {
      delete dictdelectedEmployees[employeeID];
    }
    setDeSelectedEmployees(dictdelectedEmployees);
  }

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      // CheckboxCellRenderer
      {
        headerName: "",
        field: "isAssigned",
        cellClass: "alignCenter boldtext",
        suppressMovable: true,
        maxWidth: 70,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
        filter: CustomCheckboxFilter,
        cellRenderer: function (params) {
          return (
            <Input
              type="checkbox"
              checked={params.value === 'true' ? true : false}
              onChange={() => {
                if (params.value === 'true') {
                  base
                    .get(
                      `resultcompetency/get1/${props.data.assessmentID}`,
                      params.data.employeeID
                    )
                    .then((res) => {
                      if (res === null) {
                        setIscheckboxClicked(true);
                        setIsChanged(true);
                        removeData(params.data.employeeID);
                        dictdelectedEmployees[params.data.employeeID] = "false";
                        setDeSelectedEmployees(dictdelectedEmployees);
                        params.node.setDataValue('isAssigned', 'false');
                      }
                    });
                } else {
                  setIscheckboxClicked(true);
                  setIsChanged(true);
                  selectedemp.push(params.data.employeeID);
                  dictselectedEmployees[params.data.employeeID] = "true";
                  dictselectedEmployeesToSendAPI[params.data.employeeID] = "true";
                  setNewSelectedEmp(dictselectedEmployeesToSendAPI);
                  removeDataDeselected(params.data.employeeID);
                  params.node.setDataValue('isAssigned', 'true');
                }
              }}
            />
          );
        },
        pinned: true,
      },
      {
        headerName: t("assessment.AssignEmployeeHeader.EMPLOYEE"),
        headerTooltip: t("assessment.AssignEmployeeHeader.EMPLOYEE"),
        field: "emp_DisplayName",
        width: window.innerWidth / 5 < 200 ? 200 : window.innerWidth / 5,
        suppressMovable: true,
        cellClass: 'right-border',
        headerClass: 'right-border',
        pinned: true,
      },
      {
        headerName: t("assessment.AssignEmployeeHeader.LOCATION"),
        headerTooltip: t("assessment.AssignEmployeeHeader.LOCATION"),
        field: "fk_LocationID",
        minWidth: 150,
        suppressMovable: true,
      },
      {
        headerName: t("assessment.AssignEmployeeHeader.JOB_TITLE"),
        headerTooltip: t("assessment.AssignEmployeeHeader.JOB_TITLE"),
        field: "jobTitle",
        minWidth: 150,
        suppressMovable: true,
      }
      ,
      {
        headerName: t("assessment.AssignEmployeeHeader.OnlineEmployee"),
        headerTooltip: t("assessment.AssignEmployeeHeader.OnlineEmployee"),
        field: "isOnlineEmployee",
        minWidth: 120,
        maxWidth: 160,
        hide: props.data.isMCQ === 1 ? false : true,
        cellRenderer: OnlineEmployeeRender,
        suppressMovable: true,
      },
      {
        headerName: t("assessment.AssignEmployeeHeader.TRAINING_STATUS"),
        headerTooltip: t("assessment.AssignEmployeeHeader.TRAINING_STATUS"),
        field: "training_Status",
        minWidth: 150,
        suppressMovable: true,
      },
      {
        headerName: t("assessment.AssignEmployeeHeader.DUE_DATE"),
        headerTooltip: t("assessment.AssignEmployeeHeader.DUE_DATE"),
        field: "dt_x1Date",
        minWidth: 150,
        filter: false,
        suppressMovable: true,
        valueFormatter: (params) => {
          return dateformat(params.node.data.dt_x1Date);
        },
      },
    ]);
  };


  function dateformat(val) {
    val = val - 2; //from db due to some caculation date is coming 32 days making it to 30
    var someDate = new Date("1900-01-01");
    var result = someDate.setDate(someDate.getDate() + parseInt(val));
    return moment(result).format("DD MMM YYYY");
  }

  const assignemptoModuleClick = () => {
    setisAssignEmpToModuleClicked(true);
    AssignEmployeesModuleClick(props.data);
  };

  const AssignEmployeesModuleClick = (params) => {
    base.get("task/get", params.taskID).then((response) => {
      if (response !== undefined) {
        var locationID = "";
        if (response.fk_CountryID !== "" && response.fk_CountryID != null)
          locationID = response.fk_CountryID;
        if (
          response.fk_LocationID !== "" &&
          response.fk_LocationID != null
        )
          locationID = response.fk_LocationID;
        if (response.fk_PUID !== "" && response.fk_PUID != null)
          locationID = response.fk_PUID;

        setLocationID(locationID);
        setassignTaskData(response);
        setTaskID(params.taskID);
        setAssignEmptoModule(true);
      }
    });
  };
  const closeAssignEmployee = () => {
    setAssignEmptoModule(false);
  };

  return (
    <>
      <Modal className="modal-dialog-centered modal-90" isOpen={true}>
        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={cancelHandler}>
          <Label className="modalHeaderGlobal m-4">
            {t("assessment.Assign Employees to Assessment")}
          </Label>
        </Modal.Header>

        <Modal.Body className="ps-8 pe-8 pb-3 pt-2"
        >
          <Row>
            <Col xs="6">
              <Label>
                {" "}
                <Trans i18nKey="assessment.Assessment">Assessment</Trans>
              </Label>
              <Input
                value={assessment}
                disabled={true}
              ></Input>
            </Col>
            <Col xs="6">
              <Label>
                {" "}
                <Trans i18nKey="Common.Module">Module</Trans>
              </Label>
              <Input
                value={task}
                disabled={true}
              ></Input>
            </Col>
            <Col xs="12">
              <div className="mt-4">
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                  onClick={() => assignemptoModuleClick()}
                  rel="noopener noreferrer"
                >
                  {t("assessment.Assign employees to module")}
                </span>
                <Info24
                  id="assignToModule"
                ></Info24>

                <UncontrolledPopover
                  placement="right"
                  target="assignToModule"
                  trigger="hover focus"
                  popperClassName="popover"
                >

                  <PopoverBody className="tooltip-msg">
                    {t("assessment.assignEmployeeInfotext")}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </Col>
          </Row>
          <div className="">
            <div className="gridDiv">

              <div
                className="ag-theme-material gridHtWd "
                style={{ width: "100%", position: 'relative' }}

              >
                <AgGridReact
                  ref={gridRef}
                  columnDefs={gridColDefs}
                  rowData={gridData}
                  suppressMenuHide={true}
                  overlayNoRowsTemplate="No records found"
                  defaultColDef={gridDefaultColDef}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10]}
                  suppressScrollOnNewData={true}
                  suppressHorizontalScroll={false}
                ></AgGridReact>

              </div>
            </div>

          </div>
          {assignEmptoModule ? (
            <AssignEmployees
              isAllMCQ={props.data.isMCQ}
              isFromAssessment={true}
              assessment_Type={props.data.assessment_Type}
              assID={props.data.assessmentID}
              close={closeAssignEmployee}
              isopen={true}
              taskID={taskID}
              locationID={locationID}
              empModel={assignTaskData}
              reload={() => {
              }}
              reloadDone={() => {
                getassignedemployee();
                getAllEmployees();
                updateMyTrainingCount();
              }}
            ></AssignEmployees>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer >
          <Button color="standard-secondary" onClick={cancelHandler}
            className="rounded-pill ms-2 btn-style"
            size="sm"
          >
            {t("Common.Cancel")}
          </Button>{" "}
          <Button
            color="standard-primary"
            className="rounded-pill ms-2 btn-style"
            disabled={!ischeckboxClicked}
            onClick={closemodal}
            size="sm"

          >
            {t("Common.Save")}
          </Button>{" "}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssignEmployee;
