import React, { useState, useEffect } from "react";
import base from "../../../Services/BaseService";
import "../../../Content/stylesheet/common.css";
import "../../../Content/stylesheet/createnewsfeed.css";
import "../../../Content/stylesheet/createquestion.css";
import "../../../Content/stylesheet/tooltip.css";
import { Row, Col } from "reactstrap";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import { useTranslation } from "react-i18next";
import LoadingView from "../../Shared/Layout/LoadingView";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';
import { Info24, World24 } from "@bphxd/ds-core-react/lib/icons";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { useUser } from "../../Shared/userContext";

const CreateThreats = ({ create, editThreat, close, getAll, saveSuccess, dataError, isopen }) => {
  const { t } = useTranslation();
  const { languages, userData, languagePreference } = useUser();
  const { fk_languageID } = userData;

  const englishID = "en-GB";
  var feed = [{
    hazard_Description: "",
    ID: 0,
    HazardID: "",
    fk_LanguageID: englishID
  }];
  if (create === false) {
    feed = editThreat;
  }
  // const englishThreat = editThreat.filter(d => d.fk_LanguageID === englishID);
  const [language, setLanguage] = useState(create ? englishID : fk_languageID)
  const [threat, setThreat] = useState(feed);
  const [descriptionRemaining, setDescriptionRemaining] = useState(threat.find(item => item.fk_LanguageID === language)?.hazard_Description.length);
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [isChanged, setIsChanged] = useState(false);
  const [isLoadingSteps, setisLoadingSteps] = useState(false);
  const [threatError, setThreatError] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "hazard_Description") {
      setThreatError(false);
    }
    let elementValue = e.target.value;
    let elementID = e.target.id
    setDescriptionRemaining(elementValue.length)
    setIsChanged(true);

    // setThreat({ ...threat, [e.target.name]: elementValue });
    setThreat((prevThreatData) => {
      const updatedLanguageID = language;
      const updatedItemIndex = prevThreatData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
      if (updatedItemIndex !== -1) {
        const updatedItem = {
          ...prevThreatData[updatedItemIndex],
          [elementID]: elementValue
        };
        const updatedThreatData = [
          ...prevThreatData.slice(0, updatedItemIndex),
          updatedItem,
          ...prevThreatData.slice(updatedItemIndex + 1)
        ];

        return updatedThreatData;
      }
      return prevThreatData;
    });
  };

  const cancelHandler = () => {
    if (!isChanged) close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const handleDiscard = () => {
    setThreat(feed);
    close(false);
  };



  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const saveThreat = () => {
    let isValid = true;
    threat.map((item) => {
      item.hazard_Description = item.hazard_Description.trim();
      if (item.hazard_Description === "") {
        isValid = false;
      }
      return isValid
    })
    // threat.hazard_Description = threat.hazard_Description.trim();

    if (isValid) {
      if (create === false) {
        base.update(process.env.REACT_APP_THREAT_UPDATE, threat).then((response) => {
          getAll();
          handleDiscard();
          setThreat({
            ...response.data,
            hazard_Description:
              response.data.hazard_Description.toString(),
          });
          setIsChanged(false);
          saveSuccess();
          setisLoadingSteps(false);
        })
          .catch((error) => {
            handleDiscard();
            dataError();
            setisLoadingSteps(false);
          });
      } else {
        base.add(process.env.REACT_APP_THREAT_ADD, threat[0]).then((response) => {
          getAll();
          handleDiscard();
          setThreat({
            ...response.data,
            hazard_Description:
              response.data.hazard_Description.toString(),
          });
          setIsChanged(false);
          saveSuccess();
          setisLoadingSteps(false);
        })
          .catch((error) => {
            handleDiscard();
            dataError();
            setisLoadingSteps(false);
          });
      }
    } else {
      setThreatError(true);
    }
  };

  const handleLanguageChange = (event) => {
    let selectedLanguage = event.target.value
    setLanguage(selectedLanguage);
    // setSelectedQuestion(props.questionData.filter(d => d.fk_LanguageID === selectedLanguage));
  };

  useEffect(() => {
    setLanguage(languagePreference);
  }, [languagePreference]);

  return (
    <>
      <Modal isOpen={isopen} className="modal-dialog-centered modal-50" >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
            handleNo={saveThreat}
          />
        )}
        {isLoadingSteps && <LoadingView />}
        <Modal.Header
          className="comm-header-bgPosition"

          onToggleOpen={cancelHandler}
        >
          <Label className="modalHeaderGlobal m-4">
            {create
              ? t("Threatpage.Create Threat")
              : t("Threatpage.Edit Threat")}
          </Label>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="">
              <Label className="" htmlFor="hazard_Description">
                {t("Threatpage.Threat Description")}{" "}
                <span className="text-danger">*</span>
              </Label>
            </Col>
            {(language !== englishID) && (
              <Col className="text-end">
                <span>
                  <Info24
                    id='threatPopover'
                  ></Info24>
                  <UncontrolledPopover
                    placement="top"
                    target="threatPopover"
                    trigger="hover focus"
                    popperClassName="popover"
                  >
                    <PopoverBody className="tooltip-msg">
                      {threat.find(item => item.fk_LanguageID === englishID)?.hazard_Description}
                    </PopoverBody>
                  </UncontrolledPopover>

                </span>
              </Col>
            )
            }
          </Row>
          <Row>
            <Col className="">
              <Input
                id="hazard_Description"
                name="hazard_Description"
                onChange={handleChange}
                disabled={language === "en-GB" ? true : false}
                value={threat.find(item => item.fk_LanguageID === language)?.hazard_Description || ''}
                invalid={threatError}
                maxLength={70}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={`desclength text-end`} >
              {descriptionRemaining}/70
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100" >
            <Col xs={4}>
              {(!create) &&
                <div className="d-flex" >
                  <World24 className="icon-margin" />
                  <Input type="select" className="border-0" value={language} onChange={handleLanguageChange}>
                    {languages.map((item, index) => {
                      return (
                        <option key={index} value={item.languageID} >
                          {item.language_Name}
                        </option>
                      )
                    })
                    }
                  </Input>
                </div>
              }
            </Col>
            <Col className="text-end">

              <Button size="sm"
                color="standard-secondary"
                className="rounded-pill btn-style" onClick={cancelHandler}>
                {t("Common.Cancel")}
              </Button>{" "}
              <Button
                size="sm"
                color="standard-primary"
                className="rounded-pill btn-style"
                onClick={() => saveThreat()}
              >
                {t("Common.Save")}
              </Button>{" "}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreateThreats;