import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import base from "../../../../Services/BaseService";
import "ag-grid-community";
import "ag-grid-enterprise";
import "../../../../Content/stylesheet/ag-grid-style.css";
import { createRoot } from "react-dom/client";
import { useModuleContext } from "../../../Shared/moduleContext";

import { Edit32, Trash32 } from "@bphxd/ds-core-react/lib/icons";
import ConfirmDeleteToast from "../../../Toast/ConfirmDeleteToast";
import LearningOutcomeModal from '../Modals/LearningOutcomeModal';

const LearningOutcomeTab = () => {
    const { t } = useTranslation();
    const [loModal, setLOModal] = useState(false)
    const [create, setCreate] = useState(false)
    const [loFeedData, setLOFeedData] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [gridColumnHeadings, setGridColumnHeadings] = useState([]);
    const gridDefaultColDef = {
        flex: 1,
        minWidth: 50,
        sortable: true,
        filter: true,
        resizable: true,
        editable: false,
        wrapText: true,
        autoHeight: true,
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
        sortingOrder: ["asc", "desc"],
        filterParams: {
            buttons: ["clear"],
            newRowsAction: "keep",
        },
        menuTabs: ["filterMenuTab"],
    }

    const [gridData, setGridData] = useState();
    const [deleteLO, setDeleteLO] = useState(false);
    const [deleteLOConfirmToast, setDeleteLOConfirmToast] = useState(false);
    const pageSize = 10;
    const { moduleData, moduleLanguagePreference, moduleDataSaved, moduleRefetch, globalUser } = useModuleContext();
    const currentLanguageRef = useRef(moduleLanguagePreference);

    const deleteLOClick = (params) => {
        setDeleteLOConfirmToast(true);
        setDeleteLO(params);
    };

    const cancelDeleteLO = () => {
        setDeleteLOConfirmToast(false);
        setDeleteLO({});
    };

    const handleDeleteLO = () => {
        if (!!deleteLO) {
            try {
                base
                    .delete("learningOutcome/del", deleteLO.outcomeID)
                    .then((response) => {
                        if (response === 200) {
                            moduleDataSaved(
                                "dataSuccess",
                                t("Curriculumpage.LerningOutcomeDeletedToast")
                            );
                            getAllLearningOutcomes(deleteLO.taskID);
                        }
                    });
            } catch (err) {
            } finally {
                setDeleteLOConfirmToast(false);
            }
        }
    };

    class EditCellRendererLO {
        init(params) {
            var divElement = document.createElement("div");
            divElement.classList.add("gridCrudIcons");
            this.eGui = divElement;
            const root = createRoot(divElement);

            let disableDelete = "";
            let disableEdit = "";

            if (moduleData[0].task_Site === "GLOBAL") {
                if (moduleData[0].task_Type !== "TSK") {
                    if (globalUser !== "GADMIN") {
                        disableDelete = "dat2DisableStepsActivityIcons";
                    } else {
                        disableDelete = "";
                    }
                } else {
                    disableDelete = "dat2DisableStepsActivityIcons";
                }
            }

            root.render(
                <>
                    <Edit32 className={"pointer " + disableEdit}
                        style={{ marginLeft: "0px", marginTop: "6px" }}
                        onClick={() => openLOModal("edit", params.node.data)}
                    />
                    <Trash32 className={"pointer " + disableDelete}
                        style={{ marginLeft: "8px", marginTop: "6px" }}
                        onClick={() => deleteLOClick(params.node.data)}
                    />
                </>
            );
        }
        getGui() {
            return this.eGui;
        }
    }

    const getAllLearningOutcomes = async (taskID) => {
        base.getAll("learningoutcome/qry1/" + taskID + "/" + moduleLanguagePreference).then((res) => {
            if (res !== undefined) {
                var data = res.filter(
                    (x) =>
                        x.rel_rec !== 0 &&
                        x.outcome_Description !== "" &&
                        x.outcome_Description != null
                );
                for (let i = 0; i < data.length; i++) {
                    data[i].outcome_Description = convertUnicode(
                        data[i].outcome_Description
                    );
                    data[i].taskID = moduleData[0].taskID;
                    data[i].Global_TaskID = moduleData[0].Global_TaskID;
                    data[i].Task_Type = moduleData[0].Task_Type;
                    data[i].Task_Site = moduleData[0].Task_Site;
                }
                setGridData(data);
            }
        });
    };

    useEffect(() => {
        setGridColumnHeadings([
            {
                headerName: t("Curriculumpage.LearningOutcomeHeader.LEARNING_OUTCOME"),
                headerTooltip: t(
                    "Curriculumpage.LearningOutcomeHeader.LEARNING_OUTCOME"
                ),
                field: "outcome_Description",
                resizable: false,
                filter: true,
                suppressMovable: true,
                minWidth: 750,
                maxWidth: 1400,
            },
            {
                headerName: t("Curriculumpage.LearningOutcomeHeader.IMG_SIZE"),
                headerTooltip: t("Curriculumpage.LearningOutcomeHeader.IMG_SIZE"),
                field: "s_IMAGE",
                resizable: false,
                filter: true,
                suppressMovable: true,
                minWidth: 200,
                maxWidth: 200,
            },
            {
                headerName: t("Curriculumpage.LearningOutcomeHeader.LINKED_ACTIVITIES"),
                headerTooltip: t(
                    "Curriculumpage.LearningOutcomeHeader.LINKED_ACTIVITIES"
                ),
                field: "stepLabel",
                resizable: false,
                filter: true,
                sort: "asc",
                suppressMovable: true,
                minWidth: 300,
                maxWidth: 400,
            },
            {
                headerName: t("Curriculumpage.LearningOutcomeHeader.EDIT_DELETE"),
                headerTooltip: t("Curriculumpage.LearningOutcomeHeader.EDIT_DELETE"),
                resizable: false,
                suppressMovable: true,
                sortable: false,
                minWidth: 200,
                maxWidth: 200,
                filter: false,
                suppressHeaderMenuButton: true, cellRenderer: EditCellRendererLO,
            },
        ]);
        // eslint-disable-next-line
    }, [moduleData, t]);

    function convertUnicode(input) {
        if (input != null) {
            return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
            });
        }
    }

    useEffect(() => {
        getAllLearningOutcomes(moduleData[0].taskID)
        currentLanguageRef.current = moduleLanguagePreference;
        // eslint-disable-next-line
    }, [moduleLanguagePreference, moduleRefetch]);

    const openLOModal = (params, data) => {
        if (params === "create") {
            setLOModal(true)
            setCreate(true)
            setModalTitle(t("Curriculumpage.Create Learning Outcome"))

        }
        if (params === "edit") {
            base.getAll("learningoutcome/frm/" + data.outcomeID).then((res) => {
                if (res !== undefined) {
                    setLOFeedData(res)
                    setLOModal(true)
                    setCreate(false)
                    setModalTitle(t("Curriculumpage.Edit Learning Outcome"))
                }
            });
        }
    }

    const closeLOModal = () => {
        setLOModal(false)
        setLOFeedData([])
        getAllLearningOutcomes(moduleData[0].taskID)
    }

    const saveLOModal = () => {
        setLOModal(false)
        setLOFeedData([])
        getAllLearningOutcomes(moduleData[0].taskID)
        moduleDataSaved("dataSuccess", "Learning outcome saved")
    }


    let isDisabled;
    if (moduleData[0].global_TaskID === "GBL10058") {
        if (globalUser === "GADMIN") {
            isDisabled = false;
        } else {
            isDisabled = true;
        }
    } else if (moduleData[0].task_Site === "GLOBAL") {
        if (globalUser === "GADMIN") {
            if (moduleData[0].task_Type === "TSK") {
                isDisabled = true;
            } else {
                isDisabled = false;
            }
        } else {
            isDisabled = true;
        }
    }
    if (moduleData[0].task_Site === "SITE" && moduleData[0].taskID !== moduleData[0].global_TaskID) {
        isDisabled = true;
    }

    return (
        <div className="ms-3 me-3  mt-0">
            <div className="text-end mb-2 mt-2">
                <Button
                    className="rounded-pill"
                    color="standard-primary"
                    size="sm"
                    onClick={() => openLOModal("create")}
                    disabled={isDisabled}                >

                    {t("Curriculumpage.Create Learning Outcomes")}
                </Button>{" "}
            </div>
            <div className="gridDiv">
                <div
                    className="ag-theme-material gridHtWd "
                    style={{ position: 'relative' }}
                >
                    <AgGridReact
                        columnDefs={gridColumnHeadings}
                        rowData={gridData}
                        suppressMenuHide={true}
                        overlayNoRowsTemplate="No records found"
                        defaultColDef={gridDefaultColDef}
                        pagination={true}
                        paginationPageSizeSelector={[10, 20, 50]}

                        paginationPageSize={pageSize}
                        suppressScrollOnNewData={true}
                        resizeable={true}
                        animateRows={true}
                    />
                </div>
                <ConfirmDeleteToast
                    show={deleteLOConfirmToast}
                    handleCancel={cancelDeleteLO}
                    Message={t("ErrorMessages.DeleteSure")}
                    handleDelete={handleDeleteLO}
                />
            </div>
            {loModal && <LearningOutcomeModal modalTitle={modalTitle} create={create} closeLOModal={closeLOModal} saveLOModal={saveLOModal} setLOFeedData={setLOFeedData} loFeedData={loFeedData}></LearningOutcomeModal>}
        </div>
    );
}
export default LearningOutcomeTab;
