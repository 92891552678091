import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom-v5-compat";
import AppLayout from "./AppLayout";
import RequireAuth from "./RequireAuth";
import ContactUs from "../../ContactUs/ContactUs";
import HomeScreen from "../../Homescreen/homeScreen";
import ManageNewsFeed from "../../ManageData/NewsFeed/ManageNewsFeed";
import ManageQuestion from "../../ManageData/ManageQuestions/ManageQuestion";
import ManageThreats from "../../ManageData/ManageThreats/ManageThreats"
import ManageAssessment from "../../Assessment/ManageAssessment";
import ManageCurriculum from "../../ManageData/ManageCurriculum/ManageCurriculum";
import AssignEmployeesToModule from "../../ManageData/AssignEmployeesToTask/AssignEmployeesToModuleLanding";
import SystemusersLandingpage from "../../ManageData/SystemUsers/SystemusersLandingpage";
import AssignModuleToEmployee from "../../ManageData/AssignModuleToEmployee/AssignModuleToEmployee";
import MyTraining from "../../TraineeAssessment/MyTraining";
import ManageRegion from "../../ManageData/Locations/ManageRegion";
import { ModuleProvider } from "../moduleContext";
import { useEffect } from "react";
import ReportLayout from "../../Reports/ReportLayout";

const AppRoutes = ({ userData, logOut }) => {
  const { userLevel: accessLevel, isTrainee, isTraineeLocation } = userData;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Function to handle redirection to "/MyTraining" for userLevel 50
    if (accessLevel === "50" && location.pathname === "/") {
      navigate('/MyTraining', { replace: true });
    }
  }, [accessLevel, location, navigate]);

  if (accessLevel === "50") {
    return (
      <Routes>
        <Route element={<AppLayout userData={userData} logOut={logOut} />}>
          <Route index element={<MyTraining />} />
          <Route path="/MyTraining/*" element={<MyTraining />} />

        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<AppLayout userData={userData} logOut={logOut} />}>
        <Route
          index
          element={
            <RequireAuth>
              <HomeScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <HomeScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/home1"
          element={
            <RequireAuth>
              <HomeScreen />
            </RequireAuth>
          }
        />

        {isTrainee === 1 && isTraineeLocation === 1 && (
          <Route
            path="/MyTraining/*"
            element={
              <RequireAuth>
                <MyTraining />
              </RequireAuth>
            }
          />)
        }

        <Route
          path="/ManageAssessment"
          element={
            <RequireAuth>
              <ManageAssessment />
            </RequireAuth>
          }
        />

        <Route
          path="/evaluationReport"
          element={
            <RequireAuth>
              <ReportLayout />
            </RequireAuth>
          }
        />
        <Route
          path="/complianceReport"
          element={
            <RequireAuth>
              <ReportLayout />
            </RequireAuth>
          }
        />
        <Route
          path="/PreformattedDocument"
          element={
            <RequireAuth>
              <ReportLayout />
            </RequireAuth>
          }
        />
        <Route
          path="/MyFavouriteReports"
          element={
            <RequireAuth>
              <ReportLayout />
            </RequireAuth>
          }
        />

        <Route
          path="/AssignEmployeesToModule"
          element={
            <RequireAuth>
              <AssignEmployeesToModule />
            </RequireAuth>
          }
        />
        <Route
          path="/AssignModuleToEmployee"
          element={
            <RequireAuth>
              <AssignModuleToEmployee />
            </RequireAuth>
          }
        />
        <Route
          path="/ManageCurriculum"
          element={
            <RequireAuth>
              <ModuleProvider>
                <ManageCurriculum />
              </ModuleProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/ManageNewsFeed"
          element={
            <RequireAuth>
              <ManageNewsFeed />
            </RequireAuth>
          }
        />
        <Route
          path="/ManageQuestions"
          element={
            <RequireAuth>
              <ManageQuestion />
            </RequireAuth>
          }
        />
        <Route
          path="/ManageRegion"
          element={
            <RequireAuth>
              <ManageRegion />
            </RequireAuth>
          }
        />
        <Route
          path="/ManageThreats"
          element={
            <RequireAuth>
              <ManageThreats />
            </RequireAuth>
          }
        />
        <Route
          path="/Managesystemusers"
          element={
            <RequireAuth>
              <SystemusersLandingpage />
            </RequireAuth>
          }
        />
        <Route
          path="/contactus"
          element={
            <RequireAuth>
              <ContactUs />
            </RequireAuth>
          }
        />

        <Route path="*" element={<Navigate to="/home" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
