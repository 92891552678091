import React, { useState, useEffect, useRef, useCallback } from "react";
import TitleBanner from "../Shared/Layout/TitleBanner";
import { Trans, useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { Col, Row, Label, Spinner, UncontrolledPopover, PopoverHeader, PopoverBody, Input, InputGroup } from "reactstrap";
import Select from "react-select";
import SuccessToastNew from "../Toast/SuccessToastNew";
import ConfirmDeleteToast from "../Toast/ConfirmDeleteToast";
import ErrorToastNew from "../Toast/ErrorToastNew1";
import base from "../../Services/BaseService";
import { Info24, Save24 } from '@bphxd/ds-core-react/lib/icons';
import { Datepicker, Button } from '@bphxd/ds-core-react';
import Cookies from "universal-cookie";
import UserParameters from "./UserParameters";
import { useLocation } from 'react-router';
import "../../Content/stylesheet/reports.css";
import fileDownload from "js-file-download";
import queryString from "query-string";

const ReportLayout = () => {

    const cookies = new Cookies();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const page = location.pathname === "/evaluationreport" ? "Evaluation" : location.pathname === "/compliancereport" ? "Compliance" : location.pathname === "/PreformattedDocument" ? "Preformatted" : "My favourites"
    const { t } = useTranslation();
    const [reportsList, setReportsList] = useState([]);
    const [selectedReportName, setSelectedReportName] = useState("");
    const selectedReportNameRef = useRef("");
    const urlModuleRef = useRef("");
    const [selectedDetail, setSelectedDetail] = useState("");
    const [selectedExpiry, setSelectedExpiry] = useState("");
    const [extractParams, setExtractParams] = useState(urlParams.size === 0 ? true : false);
    const [detailData, setDetailData] = useState([]);
    const [expiryData, setExpiryData] = useState([]);
    const [detailSelect, setDetailSelect] = useState(false);
    const [expirySelect, setExpirySelect] = useState(false);
    const [isRunDisabled, setIsRunDisabled] = useState(true);
    const [reportDownloading, setReportDownloading] = useState(false);
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [userParamsVisible, setUserParamsVisible] = useState(false);
    const [preformattedParamsVisible, setPreformattedParamsVisible] = useState(false);
    const [reportValueData, setReportValueData] = useState([]);
    const [activityBoxSizeVisible, setActivityBoxSizeVisible] = useState(false);
    const [favCheckbox, setFavCheckbox] = useState(false);
    const [reportID, setReportID] = useState("");
    const [showtoast, setshowtoast] = useState({ name: "", show: false });

    // const [objlabel, setobjlabel] = useState({})

    const [hasFailed, setHasFailed] = useState(false);
    // add favourite reports 
    const [resetTrigger, setResetTrigger] = useState(false);
    const inputfavname = useRef(null);
    const [addFavVisible, setAddFavVisible] = useState(false);
    const [favnameremaining, setfavnameremaining] = useState(0);
    const [favreportdesc, setfavreportdesc] = useState("");
    const [reqfavname, setreqfavname] = useState(false);
    const [duplicatefavname, setduplicatefavname] = useState(false);
    const [removeBtnVisible, setRemoveBtnVisible] = useState(false);
    const [showFavouriteSave, setShowFavouriteSave] = useState(false);
    const [preformattedParamsArray, setPreformattedParamsArray] = useState([]);

    useEffect(() => {
        setSelectedReportName("");
        selectedReportNameRef.current = "";
        setPreformattedParamsArray([]);
        setAddFavVisible(false);
        setIsRunDisabled(true);
    }, [page]);

    // dates
    let todaydate = new Date();
    let stdate = new Date(todaydate.valueOf());
    stdate.setFullYear(stdate.getFullYear() - 1);

    let udstartdate = new Date(todaydate.valueOf());
    udstartdate.setFullYear(udstartdate.getFullYear() - 1);

    let treqenddate = new Date(todaydate.valueOf());
    treqenddate.setFullYear(treqenddate.getFullYear() + 1);

    const [startDate, setStartDate] = useState(udstartdate.toISOString().slice(0, 10))
    const [endDate, setEndDate] = useState(todaydate.toISOString().slice(0, 10));

    const [datepickerStartDate, setDatepickerStartDate] = useState(udstartdate.toISOString())
    const [datepickerEndDate, setDatepickerEndDate] = useState(todaydate.toISOString())
    const [datepickerTREndDate, setDatepickerTREndDate] = useState(treqenddate.toISOString())
    const [exceedDateErr, setExceedDateErr] = useState(false);

    const handleDateChange = (selectedDates, dateStr, instance) => {
        document.getElementById("startinputgrp").style.border = ""
        document.getElementById("endinputgrp").style.border = ""
        setExceedDateErr(false)

        if (instance.element.id === "datePickerStart") {
            setStartDate(new Date(selectedDates).toISOString().slice(0, 10))
        }
        if (instance.element.id === "datePickerEnd") {
            setEndDate(new Date(selectedDates).toISOString().slice(0, 10))
        }
    }

    useEffect(() => {
        if (startDate > endDate) {
            setExceedDateErr(true)
            document.getElementById("startinputgrp").style.border = "1px solid red"
            document.getElementById("endinputgrp").style.border = "1px solid red"
        }
    }, [startDate, endDate]);

    // activity box size grid 
    const gridRef = useRef();
    const [gridDefaultColDef, setGridDefaultColDef] = useState();
    const [gridColDefs, setGridColDefs] = useState([]);
    const [gridData, setGridData] = useState();
    const [dataLoaded, setdataLoaded] = useState(false);
    const pageSize = 100;
    const [DefaultBoxSizes, setDefaultBoxSizes] = useState([]);

    const customComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    const showActivitiesRef = useRef(ShowActivitiesOfModule)

    useEffect(() => {
        if (selectedReportNameRef.current === "OPS_FLOW" && reportValueData.length !== 0) {
            const selectedModule = reportValueData.filter(d => d.label_Name === "TASKOPS");
            const selectedOutput = reportValueData.filter(d => d.label_Name === "OUTPUT");
            if (selectedOutput[0].value === "CUSTOM" && selectedModule[0].value !== "") {
                setActivityBoxSizeVisible(true);
                showActivitiesRef.current(selectedModule[0].value, true);
            } else {
                setActivityBoxSizeVisible(false);
            }
        }
    }, [reportValueData]);


    function convertUnicode(input) {
        if (input != null) {
            return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
            });
        }
    }

    function ShowActivitiesOfModule(
        tskID,
        isFirst,
        selectedActivitiesInGridOnlyID,
        increaseCount
    ) {
        initGridVar();
        var isValid = isFirst
            ? true
            : selectedActivitiesInGridOnlyID !== undefined &&
                selectedActivitiesInGridOnlyID.length > 0
                ? true
                : false;
        if (isValid) {
            base.getAll("step/Qry/" + tskID + "/2/null/").then((res) => {
                if (res !== undefined) {
                    var data = res;
                    var defaultBoxSizes = [];
                    //add box size based on character lenght
                    for (let i = 0; i < data.length; i++) {
                        //if (selectedActivitiesInGridOnlyID == undefined && fromIsDefault == true) {
                        if (data[i].step_Description !== "") {
                            if (
                                isFirst ||
                                selectedActivitiesInGridOnlyID !== undefined
                            ) {
                                data[i].step_Description = convertUnicode(
                                    data[i].step_Description
                                );
                                if (data[i].step_Description.length < 25)
                                    data[i].boxSize = 1;
                                else if (
                                    data[i].step_Description.length < 50 &&
                                    data[i].step_Description.length >= 25
                                )
                                    data[i].boxSize = 2;
                                else if (
                                    data[i].step_Description.length < 75 &&
                                    data[i].step_Description.length >= 50
                                )
                                    data[i].boxSize = 3;
                                else if (
                                    data[i].step_Description.length < 100 &&
                                    data[i].step_Description.length >= 75
                                )
                                    data[i].boxSize = 4;
                                else if (
                                    data[i].step_Description.length < 125 &&
                                    data[i].step_Description.length >= 100
                                )
                                    data[i].boxSize = 5;
                                else if (
                                    data[i].step_Description.length < 150 &&
                                    data[i].step_Description.length >= 125
                                )
                                    data[i].boxSize = 6;
                                else if (
                                    data[i].step_Description.length < 175 &&
                                    data[i].step_Description.length >= 150
                                )
                                    data[i].boxSize = 7;
                                else if (
                                    data[i].step_Description.length < 200 &&
                                    data[i].step_Description.length >= 175
                                )
                                    data[i].boxSize = 8;
                                else if (
                                    data[i].step_Description.length < 225 &&
                                    data[i].step_Description.length >= 200
                                )
                                    data[i].boxSize = 9;
                                else if (
                                    data[i].step_Description.length < 250 &&
                                    data[i].step_Description.length >= 225
                                )
                                    data[i].boxSize = 10;
                                else if (data[i].step_Description.length >= 250)
                                    data[i].boxSize = 11;

                                if (
                                    selectedActivitiesInGridOnlyID !== undefined &&
                                    selectedActivitiesInGridOnlyID.length > 0
                                ) {
                                    if (
                                        selectedActivitiesInGridOnlyID.includes(
                                            data[i].stepID
                                        )
                                    ) {
                                        data[i].boxSize =
                                            data[i].boxSize !== 11
                                                ? data[i].boxSize + increaseCount
                                                : data[i].boxSize; //need to take clarification fo box size 11
                                    }
                                }
                            }
                            if (isFirst) {
                                //set default box sizes
                                defaultBoxSizes.push(data[i].boxSize);
                            }
                        }
                    }
                    if (isFirst) {
                        //set default box sizes
                        setDefaultBoxSizes(defaultBoxSizes);
                    }
                    setGridData(data);
                    setdataLoaded(true);
                    //assignedEmployees();
                }
            });
        }
    }

    // activities display
    const changeBoxSizePlus = (count) => {
        const selectedActivitiesInGrid = gridRef.current.api.getSelectedRows();
        var selectedActivitiesInGridOnlyID = selectedActivitiesInGrid.map(
            (item) => item.stepID
        );
        const selectedModule = reportValueData.filter(d => d.label_Name === "TASKOPS");
        ShowActivitiesOfModule(
            selectedModule[0].value,
            false,
            selectedActivitiesInGridOnlyID,
            count
        );
    };

    // modify Column Menu position
    const postProcessPopup = useCallback((params) => {
        // check callback is for menu
        if (params.type !== "columnMenu") {
            return;
        }

        const ePopup = params.ePopup;
        let oldTopStr = ePopup.style.top;
        // remove 'px' from the string (AG Grid uses px positioning)
        oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
        const oldTop = parseInt(oldTopStr);
        const newTop = oldTop + 30;
        ePopup.style.top = newTop + "px";
    }, []);

    const initGridVar = () => {
        setGridDefaultColDef({
            flex: 1,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            wrapText: true,
            autoHeight: true,
            suppressHeaderMenuButton: true,
            suppressHeaderContextMenu: true,
            sortingOrder: ["asc", "desc"],
            filterParams: {
                buttons: ["clear"],
                newRowsAction: "keep",
            },
            menuTabs: ["filterMenuTab"],
        });

        setGridColDefs([
            {
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                checkboxSelection: true,
                headerName: "",
                field: "",
                resizable: false,
                suppressMovable: true,
                sortable: false,
                filter: false,
                minWidth: 80,
                maxWidth: 80,
            },
            {
                headerName: t("Reportpage.LandingGridHeader.LABEL"),
                headerTooltip: t("Reportpage.LandingGridHeader.LABEL"),
                field: "step_Label",
                resizable: false,
                suppressMovable: true,
                sort: "asc",
                minWidth: 135,
                maxWidth: 135,
                comparator: customComparator,
            },
            {
                headerName: t("Reportpage.LandingGridHeader.ACTIVITY_DESCRIPTION"),
                headerTooltip: t("Reportpage.LandingGridHeader.ACTIVITY_DESCRIPTION"),
                field: "step_Description",
                resizable: false,
                suppressMovable: true,
            },
            {
                headerName: t("Reportpage.LandingGridHeader.BOX_SIZES"),
                headerTooltip: t("Reportpage.LandingGridHeader.BOX_SIZES"),
                field: "boxSize",
                resizable: false,
                suppressMovable: true,
                minWidth: 135,
                maxWidth: 135,
            },
        ]);
    };

    const [favdata, setfavdata] = useState({
        fk_ReportID: "",
        rVal1: "",
        rVal2: "",
        rVal3: "",
        rVal4: "",
        rVal5: "",
        rVal6: "",
        rVal7: "",
        rVal8: "",
        report_Description: "",
        fk_UserID: cookies.get("email"),
    });

    function updatefavdatawithreport(reportid) {
        let data = {
            fk_ReportID: reportid,
            rVal1: "",
            rVal2: "",
            rVal3: "",
            rVal4: "",
            rVal5: "",
            rVal6: "",
            rVal7: "",
            rVal8: "",
            report_Description: "",
        };
        let newfavdata = { ...favdata, ...data };
        setfavdata(newfavdata);
    }

    const detailList = [
        { label: "Group by business unit", value: "GROUP BY BU", name: "BU", key: 2, },
        { label: "Group by performance unit", value: "GROUP BY PU", name: "PU", key: 6, },
        { label: "Group by country", value: "GROUP BY COUNTRY", name: "COUNTRY", key: 3, },
        { label: "Group by location", value: "GROUP BY LOCATION", name: "LOCATION", key: 4, },
        { label: "Group by employee", value: "GROUP BY PERSON", name: "EMPLOYEE", key: 5, },
        { label: "Group by module", value: "GROUP BY TASK", name: "TASK", key: 7, },
        { label: "Group by module", value: "GROUP BY SYLLABUS", name: "SYLLABUS", key: 8, },
        { label: "All data", value: "ALL DATA", name: "ALL", key: 1, },
    ]

    const expiryList = [
        { pValue: "EXPIRED", pExp: "EXPIRED", pLabel: "Expired - training required that is > 30 days beyond the due date" },
        { pValue: "OVERDUE", pExp: "OVERDUE", pLabel: "Overdue - training required that is beyond the due date" },
        { pValue: "30", pExp: "EXPIRED30", pLabel: "Expiring in 30 days" },
        { pValue: "60", pExp: "EXPIRED60", pLabel: "Expiring in 60 days" },
        { pValue: "90", pExp: "EXPIRED90", pLabel: "Expiring in 90 days" },
        { pValue: "180", pExp: "EXPIRED180", pLabel: "Expiring in 180 days" },
        { pValue: "DEFINED", pExp: "USERDEFINED", pLabel: "User defined" },
    ]

    const expiryListRef = useRef(expiryList);

    useEffect(() => {
        var required = [];
        const requiredFields = reportValueData.filter(f => f.field_Mandatory === true);

        for (let i = 0; i < requiredFields.length; i++) {
            if (requiredFields[i].value === "" || requiredFields[i].value === undefined) {
                required.push(requiredFields[i].label_Name);
            }
        }

        let passExpiry = true;
        if (expirySelect) {
            if (selectedExpiry === "") {
                passExpiry = false;
            } else {
                passExpiry = true;
            }
        }

        let passDetail = true;
        if (detailSelect) {
            if (selectedDetail === "") {
                passDetail = false;
            } else {
                passDetail = true;
            }
        }

        if ((requiredFields && required.length === 0) && (passExpiry) && (passDetail)) {
            setIsRunDisabled(false);
        } else {
            setIsRunDisabled(true);
        }

    }, [reportValueData, selectedDetail, selectedExpiry, expirySelect, detailSelect]);



    const reportFavDetailQry = (reportDesc) => {
        base.getAll("ReportFavoriteDetail/qry").then((res) => {

            let favReportArr = [];
            for (let i = 0; i < res.length; i++) {
                favReportArr.push({
                    value: res[i].fk_reportID,
                    label: res[i].report_Description,
                    favouriteID: res[i].report_FavouriteID,
                    definedParams: res[i].definedParameters
                });
            }

            setReportsList(favReportArr);

            if (reportDesc) {
                handleReportNameChange(favReportArr.find(x => x.label === reportDesc))
            }
        });
    }

    useEffect(() => {
        const paramsReport = urlParams.get('reportname');
        if (paramsReport && !extractParams) {
            setDetailSelect(true);
        } else {
            setDetailSelect(false);
            setSelectedReportName("");
        }

        //get report names
        setRemoveBtnVisible(false)
        setIsRunDisabled(true)
        setExpirySelect(false)
        setDatePickerVisible(false)
        setPreformattedParamsVisible(false)

        if (page === "Preformatted") {
            base.getAll(`report/qry/DOCUMENT`).then((res) => {
                if (res !== undefined) {
                    const sortedRes = res
                        .filter(
                            (repname) => repname.reportID !== "TTB_RPT"
                        )
                        .sort((rep1, rep2) => {
                            let repname1 = rep1.report_Name;
                            let repname2 = rep2.report_Name;
                            if (repname1 > repname2) return 1;
                            if (repname1 < repname2) return -1;
                            return 0;
                        });

                    let reportArr = [];
                    for (let i = 0; i < sortedRes.length; i++) {

                        reportArr.push({
                            value: sortedRes[i].reportID,
                            label: sortedRes[i].reportID === "MN_DAT1" || sortedRes[i].reportID === "MN_DAT2" ? sortedRes[i].report_Name.charAt(0).toUpperCase() + sortedRes[i].report_Name.slice(1, 23).toLowerCase() + sortedRes[i].report_Name.slice(23).toUpperCase() : sortedRes[i].report_Name.charAt(0).toUpperCase() + sortedRes[i].report_Name.slice(1).toLowerCase(),
                            report_Name: sortedRes[i].report_Name,
                            reportID: sortedRes[i].reportID,
                            description: sortedRes[i].report_Description,
                        });
                    }
                    setReportsList(reportArr);
                    setIsRunDisabled(true)
                }
            });
        } else
            if (page === "My favourites") {

                reportFavDetailQry()

            } else {
                base.get("report/qry", page).then((res) => {
                    // sorting Report Name list in alphabetical order
                    const sortedRes = res.sort((rep1, rep2) => {

                        let repname1 = rep1.report_Name;
                        let repname2 = rep2.report_Name;
                        if (repname1 > repname2) return 1;
                        if (repname1 < repname2) return -1;
                        return 0;
                    });
                    let reportArr = [];
                    for (let i = 0; i < sortedRes.length; i++) {
                        if (sortedRes[i].reportID === "Q_DAT" || sortedRes[i].reportID === "DAT2") {
                            reportArr.push({
                                value: sortedRes[i].reportID,
                                label: sortedRes[i].report_Name.charAt(0).toUpperCase() + sortedRes[i].report_Name.charAt(1).toUpperCase() + sortedRes[i].report_Name.charAt(2).toUpperCase() + sortedRes[i].report_Name.slice(3, 12).toLowerCase() + sortedRes[i].report_Name.slice(12, 15).toUpperCase() + sortedRes[i].report_Name.slice(16).toLowerCase(),
                                description: sortedRes[i].report_Description,
                            });
                        } else if (sortedRes[i].reportID === "Q_UPK") {
                            reportArr.push({
                                value: sortedRes[i].reportID,
                                label: sortedRes[i].report_Name.charAt(0).toUpperCase() + sortedRes[i].report_Name.slice(1, 18).toLowerCase() + sortedRes[i].report_Name.slice(18, 22).toUpperCase() + sortedRes[i].report_Name.slice(22).toLowerCase(),
                                description: sortedRes[i].report_Description,
                            });
                        } else if (sortedRes[i].reportID === "Q_CAS") {
                            reportArr.push({
                                value: sortedRes[i].reportID,
                                label: sortedRes[i].report_Name.charAt(0).toUpperCase() + sortedRes[i].report_Name.slice(1, 28).toLowerCase() + sortedRes[i].report_Name.slice(28, 32).toUpperCase() + sortedRes[i].report_Name.slice(32).toLowerCase(),
                                description: sortedRes[i].report_Description,
                            });
                        } else {
                            reportArr.push({
                                value: sortedRes[i].reportID,
                                label: sortedRes[i].report_Name.charAt(0).toUpperCase() + sortedRes[i].report_Name.slice(1).toLowerCase(),
                                description: sortedRes[i].report_Description,
                            });
                        }
                    }
                    let reportList;
                    if (page === "Evaluation") {
                        reportList = reportArr.splice(0, 8);
                    } else {
                        reportList = reportArr;
                    }
                    setReportsList(reportList);
                    if (!extractParams) {
                        const selectReportObject = reportList.filter(d => d.value === paramsReport);
                        handleReportNameChange(selectReportObject[0])
                    }
                })
            }

        //set Expiry dates for expiry select
        let expiryArr = [];
        for (let i = 0; i < expiryListRef.current.length; i++) {
            expiryArr.push({
                value: expiryListRef.current[i].pValue,
                label: expiryListRef.current[i].pLabel,
                exp: expiryListRef.current[i].pExp
            });
        }
        setExpiryData(expiryArr);
        // eslint-disable-next-line
    }, [page]);


    useEffect(() => {
        if (addFavVisible === true && favCheckbox === false) {
            setShowFavouriteSave(false);
        }
    }, [favCheckbox, addFavVisible]);

    const handleReportNameChange = (selected) => {

        if (selected) {
            setUserParamsVisible(false);

            if (selected.value === "PER_ID" || selected.value === "TASK_INFO") {
                fetchUserParams(selected.value, "", [])
            }
            let detailArr = [];
            for (let i = 0; i < detailList.length; i++) {
                let detail = detailList[i].label;
                let detailValue = detailList[i].value
                let detailName = detailList[i].name
                detailArr.push({
                    value: detailValue,
                    label: detail,
                    name: detailName,
                });
            }

            let runDisabled = true;
            if (page === "My favourites") {
                runDisabled = false;
            }
            var expiry
            var detailData = []
            switch (selected.value) {
                case "Q_CAS":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(true)
                    setIsRunDisabled(runDisabled)
                    const data3 = detailArr.filter((array) => array.value === "GROUP BY BU" || array.value === "GROUP BY COUNTRY" || array.value === "GROUP BY LOCATION" || array.value === "GROUP BY PU" || array.value === "GROUP BY PERSON" || array.value === "GROUP BY SYLLABUS" || array.value === "ALL DATA");
                    data3.map((option) => {
                        var item = {};
                        item.value = option.value;
                        item.label = option.label;
                        item.name = option.name;
                        detailData.push(item);
                        return item
                    });
                    setDetailData(detailData)
                    break;
                case "ASS_EXP":
                case "T_COMP":
                case "T_DEL":
                case "T_COMPX":
                case "T_REQ":
                case "ACT_IND":
                case "ACT_OVER":
                case "Q_DAT":
                case "ASS_RES":
                case "Q_UPK":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(true)
                    if (selected.value === "ASS_EXP" && page === "My favourites") {
                        if (!reportValueData[1]?.label_Value) {
                            runDisabled = true
                        }
                    }
                    setIsRunDisabled(runDisabled)
                    const data = detailArr.filter((array) => array.value === "GROUP BY BU" || array.value === "GROUP BY COUNTRY" || array.value === "GROUP BY LOCATION" || array.value === "GROUP BY PU" || array.value === "GROUP BY PERSON" || array.value === "GROUP BY TASK" || array.value === "ALL DATA");
                    data.map((option) => {
                        var item = {};
                        item.value = option.value;
                        item.label = option.label;
                        item.name = option.name;
                        detailData.push(item);
                        return item
                    });
                    setDetailData(detailData)
                    if (selected.value !== "ASS_EXP") {
                        setExpirySelect(false)
                    }
                    break;
                case "DAT2":
                case "COMP_LVL":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(true)
                    setIsRunDisabled(runDisabled)
                    const data1 = detailArr.filter((array) => array.value === "GROUP BY BU" || array.value === "GROUP BY COUNTRY" || array.value === "GROUP BY LOCATION" || array.value === "GROUP BY PU" || array.value === "GROUP BY PERSON" || array.value === "ALL DATA");
                    data1.map((option) => {
                        var item = {};
                        item.value = option.value;
                        item.label = option.label;
                        item.name = option.name;
                        detailData.push(item);
                        return item
                    });
                    setDetailData(detailData)
                    break;
                case "T_COMPD":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(true)
                    setExpirySelect(false)
                    setIsRunDisabled(runDisabled)
                    const data2 = detailArr.filter((array) => array.value === "ALL DATA");
                    data2.map((option) => {
                        var item = {};
                        item.value = option.value;
                        item.label = option.label;
                        item.name = option.name;
                        detailData.push(item);
                        return item
                    });
                    setDetailData(detailData)
                    break;
                case "ROLE_TYPE":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(true)
                    setExpirySelect(false)
                    setIsRunDisabled(runDisabled)
                    const formalRoles = detailArr.filter((array) => array.value === "GROUP BY BU" || array.value === "GROUP BY COUNTRY" || array.value === "GROUP BY LOCATION" || array.value === "GROUP BY PU");
                    formalRoles.map((option) => {
                        var item = {};
                        item.value = option.value;
                        item.label = option.label;
                        item.name = option.name;
                        detailData.push(item);
                        return item
                    });
                    setDetailData(detailData);
                    break;
                case "PER_ID":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(false);
                    setUserParamsVisible(true);
                    setExpirySelect(false);
                    setIsRunDisabled(false);
                    setDetailData([]);
                    setUserParamsVisible(true);
                    if (page !== "My favourites") {
                        setAddFavVisible(true);
                    }
                    break;
                case "TASK_INFO":
                    setPreformattedParamsVisible(false);
                    setDetailSelect(false);
                    setUserParamsVisible(true);
                    setExpirySelect(false);
                    setIsRunDisabled(false);
                    setDetailData([]);
                    if (page !== "My favourites") {
                        setAddFavVisible(true);
                    }
                    break;
                case "MN_DAT1":
                case "MN_DAT2":
                case "MN_CA":
                case "MN_TP":
                    setPreformattedParamsVisible(true);
                    setIsRunDisabled(runDisabled);
                    fetchUserParams(selected.value, [], [])
                    setAddFavVisible(false);
                    break;
                case "DAT_COMP":
                case "IND_TRAIN":
                case "OPS_CHK":
                case "OPS_FLOW":
                case "TP_CERT":
                case "S_BRKD":
                case "TP_DEL":
                    setPreformattedParamsVisible(true);
                    if (selected.value === "S_BRKD" && page === "My favourites") {
                        // let employee
                        // employee = selected.definedParams.filter((f) => f.label_Name === "EXPIRY")
                        // if (!expiry[0]?.label_Value) {
                        //     runDisabled = true
                        // }
                    }
                    setIsRunDisabled(runDisabled);
                    if (page !== "My favourites" && selected.value) {
                        setAddFavVisible(true);
                        fetchUserParams(selected.value, [], [])

                    }
                    break;
                default:
                    break;
            }

            if (page === "My favourites") {
                setSelectedReportName(selected);
                selectedReportNameRef.current = selected.value;
                setRemoveBtnVisible(true);
                setfavreportdesc(selected.label);

                fetchUserParams(selected.value, selected.definedParams[0].label_Value, selected.definedParams);

                if (selected.value === "T_COMPD") {
                    setUserParamsVisible(false);
                    setExpirySelect(false);
                }
                if (selected.value === "S_BRKD" || selected.value === "TP_DEL" || selected.value === "MN_CA" || selected.value === "MN_DAT1" || selected.value === "MN_DAT2" || selected.value === "DAT_COMP" || selected.value === "IND_TRAIN" || selected.value === "OPS_CHK" || selected.value === "OPS_FLOW" || selected.value === "MN_TP" || selected.value === "TP_CERT") {
                    setDetailSelect(false);
                    setExpirySelect(false);
                    setDatePickerVisible(false);
                    setUserParamsVisible(false);
                } else if (selected.value === "PER_ID" || selected.value === "TASK_INFO") {
                    setDetailSelect(false);
                    setSelectedDetail("");
                    setExpirySelect(false);
                } else {
                    setDetailSelect(true);
                    var detail;
                    detail = selected.definedParams.filter((f) => f.label_Name === "DETAIL");
                    setSelectedDetail(detail[0].label_Value);
                    setUserParamsVisible(true);
                }

                setReportID(selected.value);

                let startdate = [];
                let enddate = [];
                if (selected.value === "ASS_EXP") {
                    setExpirySelect(true);
                    expiry = selected.definedParams.filter((f) => f.label_Name === "EXPIRY");
                    setSelectedExpiry(expiry[0]?.label_Value);

                    if (expiry[0]?.label_Value === "DEFINED") {
                        setDatePickerVisible(true);

                        startdate = selected.definedParams.filter((f) => f.label_Name === "START");
                        setDatepickerStartDate(new Date(startdate[0].label_Date));
                        setStartDate(startdate[0].label_Date.slice(0, 10));

                        enddate = selected.definedParams.filter((f) => f.label_Name === "END");
                        setDatepickerEndDate(new Date(enddate[0].label_Date));
                        setEndDate(enddate[0].label_Date.slice(0, 10));

                    } else {
                        setDatePickerVisible(false);
                    }
                } else if (selected.value === "T_REQ" || selected.value === "T_DEL" || selected.value === "ACT_IND" || selected.value === "ACT_OVER" || selected.value === "Q_DAT" || selected.value === "DAT2" || selected.value === "ASS_RES" || selected.value === "Q_UPK" || selected.value === "Q_CAS") {
                    setDatePickerVisible(true);

                    // var startdate
                    startdate = selected.definedParams.filter((f) => f.label_Name === "START");
                    setDatepickerStartDate(new Date(startdate[0].label_Date));
                    setStartDate(startdate[0].label_Date.slice(0, 10));

                    // var enddate
                    enddate = selected.definedParams.filter((f) => f.label_Name === "END");
                    setDatepickerEndDate(new Date(enddate[0].label_Date));
                    setEndDate(enddate[0].label_Date.slice(0, 10));
                } else {
                    setDatePickerVisible(false);
                }
            } else {
                setSelectedReportName(selected);
                selectedReportNameRef.current = selected.value;
                updatefavdatawithreport(selected.value);
                setExceedDateErr(false);
                setReportID(selected.value);

                if (page !== "Preformatted") {
                    setAddFavVisible(false);
                }

                const paramsDetail = urlParams.get('detail');
                const paramsModule = urlParams.get('module');
                if (urlParams.has('detail') && !extractParams) {
                    urlParams.delete('detail');
                    urlParams.delete('module');
                    urlParams.delete('reportname');
                    const filteredDetail = detailData.filter(d => d.value === paramsDetail);
                    urlModuleRef.current = paramsModule;
                    handleDetailChange(filteredDetail[0]);
                    setUserParamsVisible(true);
                    setExtractParams(true);
                    const updatedUrl = window.location.pathname;
                    window.history.replaceState(null, '', updatedUrl);
                } else {
                    setSelectedDetail("");
                }

                setSelectedExpiry("");
                setDatePickerVisible(false);
                setExpirySelect(false);
                setResetTrigger(true);
                setShowFavouriteSave(false);
                setFavCheckbox(false);
                setActivityBoxSizeVisible(false);
                setduplicatefavname(false);
            }
        }

    }

    const handleDetailChange = (selected) => {
        let report = selectedReportNameRef.current;
        setSelectedDetail(selected.value);
        if (userParamsVisible || preformattedParamsVisible) {
            setResetTrigger(true);
        } else {
            setResetTrigger(false);
        }
        let defaultSelected = [];
        if (urlModuleRef.current !== "") {
            defaultSelected.push({ label_Name: "TASKOPS", label_Value: urlModuleRef.current })
            defaultSelected.push({ label_Name: "MODULE", label_Value: urlModuleRef.current })
            defaultSelected.push({ label_Name: "TASK", label_Value: urlModuleRef.current })
            defaultSelected.push({ label_Name: "SYLLABUS", label_Value: urlModuleRef.current })
            urlModuleRef.current = "";
        }



        if (page === "Compliance") {
            switch (report) {
                case "ASS_EXP":
                    setExpirySelect(true);
                    setUserParamsVisible(true);
                    break;
                case "T_DEL":
                case "T_REQ":
                    setDatePickerVisible(true);
                    setIsRunDisabled(false);
                    if (selected.value === "ALL DATA") {
                        setUserParamsVisible(false);
                        setAddFavVisible(true);
                    } else {
                        setUserParamsVisible(true);
                    }
                    break;
                default:
                    setIsRunDisabled(false);
                    if (selected.value === "ALL DATA") {
                        setUserParamsVisible(false);
                        setAddFavVisible(true);
                    } else {
                        setUserParamsVisible(true);
                    }
            }
        }

        if (page === "Evaluation") {
            if (report !== "COMP_LVL") {
                setDatePickerVisible(true)
                setIsRunDisabled(false);
            } else {
                setDatePickerVisible(false)
                setIsRunDisabled(false);
            }

            if (report !== "ACT_IND") {
                if (selected.value === "ALL DATA") {
                    setUserParamsVisible(false);
                    setAddFavVisible(true);
                } else {
                    setUserParamsVisible(true);
                    setAddFavVisible(true);
                }
            } else {
                if (selected.value !== "ALL DATA") {
                    setUserParamsVisible(true);
                }
            }
        }
        fetchUserParams(report, selected.value, defaultSelected)
    }

    const handleExpiryChange = (selected) => {
        setSelectedExpiry(selected)
        if (selectedReportName.value === "ASS_EXP" && selected.value === "DEFINED") {
            setDatePickerVisible(true)
        } else {
            setDatePickerVisible(false)
        }
    }

    const fetchUserParams = (reportName, reportDetail, defaultValues) => {

        base.getAll(`reportdetail/Qry/${reportName}/${reportDetail}`).then((res) => {
            const valueData = res.map(item => ({
                label_Name: item.label_Name,
                label_Description: item.label_Description,
                label_Depedency: item.report_Dependant,
                field_Mandatory: item.field_Mandatory,
                value: defaultValues.length === 0
                    ? ""
                    : (defaultValues.find(values => values.label_Name === item.label_Name)?.label_Value ?? ""),
            }));


            setReportValueData(valueData)
            setPreformattedParamsArray(res);

            if (page !== "My favourites") {
                setAddFavVisible(true)
            }


        });
    }

    function handlefavcheckbox() {
        setFavCheckbox(!favCheckbox)
        let chkbox = document.getElementById("addfavchkbox");
        if (chkbox.checked === true) {
            setShowFavouriteSave(true);
            setfavreportdesc(selectedReportName.label);
        } else {
            setShowFavouriteSave(false);
            setreqfavname(false);
            setduplicatefavname(false);
            document.getElementById("favreporttext").classList.remove("date-border");
        }
        if (selectedReportName) {
            setfavnameremaining(selectedReportName.label.length);
        }
    }

    function handlefavnamechange(e) {
        setduplicatefavname(false);
        if (e.target.value.length === 0) {
            setreqfavname(true);
            document.getElementById("favreporttext").classList.add("date-border");
        }
        else {
            setreqfavname(false);
            document.getElementById("favreporttext").classList.remove("date-border");
        }
        setfavreportdesc(e.target.value);
        setfavnameremaining(e.target.value.length);
    }

    function saveToMyFav() {
        let userDefinedObject = [];
        let arrayItem

        if (selectedDetail) {
            arrayItem = {
                Label_Name: "DETAIL",
                Label_Value: selectedDetail,
            };
            userDefinedObject.push(arrayItem)
        }
        if (selectedExpiry) {
            arrayItem = {
                Label_Name: "EXPIRY",
                Label_Value: selectedExpiry.value,
            };
            userDefinedObject.push(arrayItem)
        }

        if (startDate) {
            arrayItem = {
                Label_Name: "START",
                Label_Value: null,
                Label_Date: startDate
            };
            userDefinedObject.push(arrayItem)
        }

        if (endDate) {
            arrayItem = {
                Label_Name: "END",
                Label_Value: null,
                Label_Date: endDate
            };
            userDefinedObject.push(arrayItem)
        }

        let addFavouriteBody = {
            "report_Description": favreportdesc,
            "fk_reportID": reportID,
            "definedParameters": userDefinedObject,
        }
        let values;
        for (let i = 0; i < reportValueData.length; i++) {
            values = reportValueData[i];
            arrayItem = {
                Label_Name: values.label_Name,
                Label_Value: values.value,
            };
            userDefinedObject.push(arrayItem);
        }
        base.add("ReportFavoriteDetail/add", addFavouriteBody).then((res) => {
            if (res.data === "MSG_DUPFAVOURITE") {
                if (!duplicatefavname) {
                    setduplicatefavname(true);
                }
            } else {
                setshowtoast({ name: "successaddtomyfav", show: true });
                setduplicatefavname(false);
                setShowFavouriteSave(false);
                setFavCheckbox(false);

            }
        });
    }

    function resetfavcheckbox() {
        let chkbox = document.getElementById("addfavchkbox");
        if (chkbox) {
            if (chkbox.checked === true) {
                setShowFavouriteSave(false);
                chkbox.checked = false;
            }
        }
        setreqfavname(false);
        setduplicatefavname(false);
        setFavCheckbox(false);
        let favtextel = document.getElementById("favreporttext");
        if (favtextel) {
            favtextel.classList.remove("date-border");
        }
        if (selectedReportName) {
            setfavnameremaining(selectedReportName.label.length);
        }
    }

    const resetall = () => {
        setResetTrigger(!resetTrigger);
        resetfavcheckbox();
        setDatepickerStartDate(udstartdate.toISOString());
        setDatepickerEndDate(todaydate.toISOString());
        setDatepickerTREndDate(treqenddate.toISOString());

        setshowtoast({ name: "resetall", show: true });
        document.addEventListener("mousedown", () => setshowtoast(false));
    }

    async function createReport() {

        let detail = "";
        if (selectedDetail !== "") {
            let detailName = detailList.filter(f => f.value === selectedDetail);
            detail = detailName[0].name;
        }
        let exp = ""
        if (page === "My favourites" && selectedExpiry !== "") {
            let expiry = expiryList.filter(f => f.pValue === selectedExpiry);
            exp = expiry[0].pValue;
        } else {
            exp = selectedExpiry.exp;
        }

        // compliance: assexpiry / trainingdelivered / trainingrequired dates
        // eval: all date fields
        let d1 = "NULL";
        if (startDate) {
            d1 = startDate;
        }
        let d2 = "NULL";
        if (endDate) {
            d2 = endDate;
        }

        //compliance: comp analysis / training del not ass dates
        let d1comp = endDate;
        let d2comp = "1899-12-30";

        let printtype = "Excel";
        let moduletype = "NULL";
        let tskID = "NULL"
        let pu = "NULL";
        let country = "NULL";
        let loc = "NULL";
        let critical = "NULL";
        let failurerate = "ALL";
        let role = "NULL";
        let tskdata = "INCLUDE";
        let activitydata = "INCLUDE";
        let val = "NULL";
        let criticalQuestion = "All";
        let empID = "NULL";
        let dat = "NULL"
        let doc = "NULL"
        let assessment = "NULL"

        let data;
        for (let i = 0; i < reportValueData.length; i++) {
            data = reportValueData[i];
            if (data.value !== "") {
                switch (data.label_Name) {
                    case "MODULE":
                    case "TASK":
                    case "SYLLABUS":
                    case "TASKOPS":
                        tskID = data.value
                        break;
                    case "MODULE TYPE":
                        moduletype = data.value
                        break;
                    case "OUTPUT":
                        printtype = data.value;
                        break;
                    case "PU":
                        pu = data.value;
                        break;
                    case "COUNTRY":
                        country = data.value;
                        break;
                    case "LOCATION":
                        loc = data.value;
                        break;
                    case "CRITICAL":
                        critical = data.value;
                        break;
                    case "FAILURE RATE":
                        failurerate = data.value;
                        break;
                    case "ROLE TYPE":
                        role = data.value;
                        break;
                    case "TASK DATA":
                        tskdata = data.value;
                        break;
                    case "ACTIVITY DATA":
                        activitydata = data.value;
                        break;
                    case "VALUES":
                        val = data.value;
                        break;
                    case "CRITICAL QUESTION":
                        criticalQuestion = data.value;
                        break;
                    case "PERSON":
                    case "EMPLOYEE":
                        empID = data.value;
                        break;
                    case "DATA":
                        dat = data.value;
                        break;
                    case "DOCUMENT":
                        doc = data.value;
                        break;
                    case "ASSESSMENT":
                        assessment = data.value;
                        break;
                    default:
                        break;
                }
            }
        }

        const reportnameid = selectedReportName.value;
        let arrEndpoints = [];

        switch (reportnameid) {
            case "ASS_EXP":
                arrEndpoints.push(`report_ASSEXP/Qry/${detail}/${exp}/${pu}/${country}/${loc}/${moduletype}/${tskID}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "T_COMP":
                arrEndpoints.push(`report_compliance/Qry/${detail}/T_COMP/${val}/${pu}/${country}/${loc}/${moduletype}/${tskID}/${empID}/${d1comp}/${d2comp}/${printtype}`);
                break;
            case "T_COMPD":
                arrEndpoints.push(`report_Covid19Deferred/Qry/ALL`);
                break;
            case "PER_ID":
                arrEndpoints.push(`report_EmployeeIdentification/Qry/${loc}`);
                break;
            case "ROLE_TYPE":
                arrEndpoints.push(`report_EmployeeRoles/Qry/${detail}/${pu}/${country}/${loc}/${printtype}`);
                break;
            case "TASK_INFO":
                arrEndpoints.push(`report_TaskInformation/Qry/${critical}`);
                break;
            case "T_DEL":
                arrEndpoints.push(`Report_TrainingDelivered/Qry/${detail}/${tskdata}/${pu}/${country}/${loc}/${moduletype}/${tskID}/${empID}/${role}/${d1}/${d2}/${printtype}`);
                break;
            case "T_COMPX":
                arrEndpoints.push(`report_compliance/Qry/${detail}/T_COMPX/${val}/${pu}/${country}/${loc}/${moduletype}/${tskID}/${empID}/${d1comp}/${d2comp}/${printtype}`);
                break;
            case "T_REQ":
                arrEndpoints.push(`Report_TrainingRequired/Qry/${detail}/${tskdata}/${pu}/${country}/${loc}/${moduletype}/${tskID}/${empID}/${role}/${d1}/${d2}/${printtype}`);
                break;
            case "ACT_IND":
                arrEndpoints.push(`report_activityfails/Qry/${detail}/${activitydata}/${pu}/${country}/${loc}/${tskID}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "ACT_OVER":
                arrEndpoints.push(`report_activityfailsoverview/Qry/${detail}/${failurerate}/${pu}/${country}/${loc}/${tskID}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "Q_DAT":
                arrEndpoints.push(`report_questionresult/Qry/${detail}/DAT/${pu}/${country}/${loc}/${tskID}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "DAT2":
                arrEndpoints.push(`report_dat2compliance/Qry/${detail}/${pu}/${country}/${loc}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "ASS_RES":
                arrEndpoints.push(`report_TaskAssessmentResults/Qry/${detail}/${pu}/${country}/${loc}/${tskID}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "Q_CAS":
                arrEndpoints.push(`report_questionresult/Qry/${detail}/CAS/${pu}/${country}/${loc}/${tskID}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "Q_UPK":
                arrEndpoints.push(`report_QuestionResultUPK/Qry/${detail}/${pu}/${country}/${loc}/${tskID}/${criticalQuestion}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "COMP_LVL":
                arrEndpoints.push(`Report_CompetencyLevel/Qry/${detail}/${printtype}/${pu}/${country}/${loc}/${empID}/${d1}/${d2}/${printtype}`);
                break;
            case "DAT_COMP":
                const employeeList = empID.split(",")
                if (employeeList.length > 0) {
                    for (let i = 0; i < employeeList.length; i++) {
                        arrEndpoints.push(`Report_DriverTrainingSummary/qry/${loc}/${employeeList[i]}`);
                    }
                }
                break;
            case "IND_TRAIN":
                arrEndpoints.push(`Report_IndividualTrainingRequirements/qry/${loc}`);
                break;
            case "TP_CERT":
                const employeeList1 = empID.split(",")
                if (employeeList1.length > 0) {
                    for (let i = 0; i < employeeList1.length; i++) {
                        arrEndpoints.push(`Report_TrainingProgrammeCertification/qry/${loc}/${employeeList1[i]}/${dat}`);
                    }
                }
                break;
            case "TP_DEL":
                const employeeList2 = empID.split(",")
                if (employeeList2.length > 0) {
                    for (let i = 0; i < employeeList2.length; i++) {
                        arrEndpoints.push(`Report_TrainingProgrammeCertification/qry/${loc}/${employeeList2[i]}/ALL DATA`);
                    }
                }
                break;
            case "S_BRKD":
                arrEndpoints.push(`Report_ScoreBreakdown/qry/${empID}/${assessment}`);
                arrEndpoints.push(`Report_ScoreBreakdown/qry_TARpart1/${empID}/${assessment}`);
                break;
            case "MN_DAT1":
                const documentList = doc.split(",")
                for (let i = 0; i < documentList.length; i++) {
                    if (documentList[i] === "TPO") {
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT1/PDF");
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT1/EXCEL");
                    } else {
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT1/TTB/PDF");
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT1/TTB/EXCEL");
                    }
                }
                break;
            case "MN_TP":
                let documentList3 = doc.split(",")
                for (let i = 0; i < documentList3.length; i++) {
                    let tskList = tskID.split(",");
                    for (let j = 0; j < tskList.length; j++) {
                        if (documentList3[i] === "TTB") {
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/Pre/TTB/${tskList[j]}/PDF`);
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/Pre/TTB/${tskList[j]}/EXCEL`);
                        } else if (documentList3[i] === "TAR") {
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/TAR/Q/${tskList[j]}/PDF`);
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/TAR/Q/${tskList[j]}/EXCEL`);
                        } else if (documentList3[i] === "MAS") {
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/TAR/A/${tskList[j]}/PDF`);
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/TAR/A/${tskList[j]}/EXCEL`);
                        } else {
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/OTF/${tskList[j]}/PDF`);
                            arrEndpoints.push(`Report_BatchTaskTraining/qry/OTF/${tskList[j]}/EXCEL`);
                        }
                    }
                }
                break;
            case "MN_CA":
                const taskList = tskID.split(",");
                for (let i = 0; i < taskList.length; i++) {
                    arrEndpoints.push("Report_ClassroomAssessment/qry/Q/" + taskList[i] + "/PDF");
                    arrEndpoints.push("Report_ClassroomAssessment/qry/Q/" + taskList[i] + "/EXCEL");
                    arrEndpoints.push("Report_ClassroomAssessment/qry/A/" + taskList[i] + "/PDF");
                    arrEndpoints.push("Report_ClassroomAssessment/qry/A/" + taskList[i] + "/EXCEL");
                }
                break;
            case "OPS_CHK":
                const taskList1 = tskID.split(",");
                let reptype = "PDF";
                if (printtype === "EDIT") reptype = "EXCEL";
                for (let i = 0; i < taskList1.length; i++) {
                    arrEndpoints.push(`Report_OperationsMaintenanceTaskChecklist/qry/${taskList1[i]}/${reptype}`);
                }
                break;
            case "MN_DAT2":
                const documentList2 = doc.split(",");
                for (let i = 0; i < documentList2.length; i++) {
                    if (documentList2[i] === "TPO") {
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT2/PDF");
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT2/EXCEL");
                    } else {
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT2/TTB/PDF");
                        arrEndpoints.push("Report_BatchTaskTraining/qry/DT2/TTB/EXCEL");
                    }
                }
                break;
            case "OPS_FLOW":
                if (printtype === "CUSTOM") {
                    var boxsizesNew = "0," + gridData.map((x) => x.boxSize).toString();
                    var boxSizesOld = "0," + DefaultBoxSizes.toString();

                    let reportendpoint1 =
                        "Report_BatchTaskTraining/qry/OTF_CS/" +
                        tskID +
                        "/PDF/" +
                        boxSizesOld +
                        "/" +
                        boxsizesNew;
                    //Excel
                    let reportendpoint2 =
                        "Report_BatchTaskTraining/qry/OTF_CS/" +
                        tskID +
                        "/EXCEL/" +
                        boxSizesOld +
                        "/" +
                        boxsizesNew;

                    arrEndpoints.push(reportendpoint1);
                    arrEndpoints.push(reportendpoint2);
                }

                if ((printtype && printtype === "EDIT")) {
                    arrEndpoints.push("Report_BatchTaskTraining/qry/OTF/" + tskID + "/EXCEL");
                }
                if ((printtype && printtype === "PDF")) {
                    arrEndpoints.push("Report_BatchTaskTraining/qry/OTF/" + tskID + "/PDF");
                }
                break;
            default:
                break;
        }

        for (let i = 0; i < arrEndpoints.length; i++) {
            let reportendpoint = arrEndpoints[i];
            await base
                .getReport(reportendpoint)
                .then(({ responseData, fileName }) => {
                    setReportDownloading(false);
                    setIsRunDisabled(false);
                    if (
                        responseData ===
                        "Task Count exceeds maximum allow for this report type."
                    ) {
                        setshowtoast({ name: "ITR", show: true });
                        document.addEventListener("mousedown", () =>
                            setshowtoast(false)
                        );
                    } else if (
                        responseData === "There are no recorded results for DAT Part 1"
                    ) {
                        setshowtoast({ name: "EMPDTS", show: true });
                        document.addEventListener("mousedown", () =>
                            setshowtoast(false)
                        );
                    } else if (
                        responseData ===
                        "Not adequate questions assigned for this module!"
                    ) {
                        setshowtoast({ name: "InsuffQuestions", show: true });
                        document.addEventListener("mousedown", () =>
                            setshowtoast(false)
                        );
                    } else if (responseData === "Non MCQ assessment") {
                        // do nothing
                    } else {
                        fileDownload(responseData, fileName);
                    }
                    // resolve("report success");
                })
                // fileDownload library will covert the byte array(data from API) into PDF/Excel respectively.
                .catch((err) => {
                    setReportDownloading(false);
                    setIsRunDisabled(false);
                    let toastName = ""
                    console.log(err)
                    if (err.response.status === 400) {
                        try {
                            const buffer = err.response.data;
                            const uint8Array = new Uint8Array(buffer);
                            const decodedString = new TextDecoder().decode(uint8Array);
                            switch (decodedString) {
                                case "Page limit exceeded!":
                                    toastName = "pagelimit"
                                    break;
                                case "Step count exceeded.":
                                    toastName = "stepcount"
                                    break;
                                case "You are not a site Manager at this location":
                                    toastName = "empidentification"
                                    break;
                                default:
                                    toastName = "nodata"
                                    break;
                            }
                        } catch {
                            toastName = "nodata"
                        }
                    } else if (err.response.status === 404) {
                        toastName = "nodata"
                    } else {
                        if (err.response.message)
                            switch (err.response.message) {
                                case "Incorrect task type":
                                    toastName = ""
                                    break;
                                default:
                                    toastName = "error"
                                    break;
                            }
                    }

                    if (toastName !== "") {
                        setshowtoast({ name: toastName, show: true });
                        document.addEventListener("mousedown", () =>
                            setshowtoast(false)
                        );
                    }
                });
        }
    }

    const handleCancelDelete = () => {
        setshowtoast({ name: "", show: false });
    }

    const removefavreport = () => {
        base.update(`ReportFavoriteDetail/del/${selectedReportName.favouriteID}`)
            .then((res) => {
                setfavreportdesc("")
                setSelectedReportName("")
                selectedReportNameRef.current = "";
                favrepremovehandler();
                setExpirySelect(false);
                setDetailSelect(false);
                setDatePickerVisible(false);
                setUserParamsVisible(false);
                setRemoveBtnVisible(false);
                setshowtoast({ name: "cancel", show: false });
                setPreformattedParamsVisible(false);
                setReportValueData([]);
            });
    }

    function favrepremovehandler() {
        reportFavDetailQry()
        setshowtoast({ name: "removefavreport", show: true });

    }

    const favreportparams = queryString.parse(useLocation().search);

    useEffect(() => {
        // From Home Screen to Reports
        if (favreportparams.reportid) {
            const favrepdesc = decodeURIComponent(favreportparams.reportid);
            reportFavDetailQry(favrepdesc)
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ flex: 1 }}>
            <div>
                <TitleBanner
                    heading={page}
                    // subheading={<Trans i18nKey="Homepage.Title">Competency Assessment Database for Operations Staff</Trans>}
                    subheading={""}
                    infoText={(page === "Compliance") ? <Trans i18nKey="Reportpage.compinfo"></Trans> : (page === "Evaluation") ? <Trans i18nKey="Reportpage.evainfo"></Trans> : (page === "Preformatted") ? <Trans i18nKey="Reportpage.prefoinfo"></Trans> : <Trans i18nKey="Reportpage.favinfo1"></Trans>}
                    breadcrumbArray={[<Trans i18nKey="Common.Reports"></Trans>, <Trans i18nKey={page === "Preformatted" ? `Reportpage.${page} Document` : (page === "My favourites") ? `Reportpage.My Favourite Report` : `Reportpage.${page} Report`}></Trans>]}
                    showButton1={true}
                    buttonHeading={<Trans i18nKey="Reportpage.bp Policy1">
                        Please note, that all personal data contained in reports shall
                        be used in accordance with:</Trans>}
                    textButton1={t("Reportpage.bp Data Privacy")}
                    // functionButton2={setcreateassessment(true)}
                    showButton2={true}
                    textButton2={t("Reportpage.bp Code of Conduct")}
                    functionButton1={() => window.open(
                        "https://www.bp.com/en/global/air-bp/privacy-statement.html"
                    )}
                    functionButton2={() => window.open(
                        "https://bp365.sharepoint.com/sites/Intranet-Ethicsandcompliance/SitePages/code-of-conduct.aspx"
                    )}
                    showButton3={false}
                />
            </div>

            {showtoast.show && showtoast.name === "removefavreport" && (
                <SuccessToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.removeFav")}
                />
            )}
            {showtoast.name === "cancel" && (
                <ConfirmDeleteToast
                    className={"showPopup"}
                    show={showtoast.show}
                    handleCancel={handleCancelDelete}
                    handleDelete={removefavreport}
                    Message={t("Reportpage.SureRemove")}
                />
            )}
            {showtoast.show && showtoast.name === "successaddtomyfav" && (
                <SuccessToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.SuccessFav")}
                />
            )}
            {showtoast.show && showtoast.name === "resetall" && (
                <SuccessToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.ResetSuccess")}
                />
            )}
            {showtoast.show && showtoast.name === "empidentification" && (
                <ErrorToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.NotSiteMng")}
                    setHasFailed={setHasFailed}
                    hasFailed={hasFailed}
                />
            )}
            {showtoast.show && showtoast.name === "nodata" && (
                <ErrorToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.NoData")}
                    setHasFailed={setHasFailed}

                />
            )}
            {showtoast.show && showtoast.name === "pagelimit" && (
                <ErrorToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.PageLimit")}
                    setHasFailed={setHasFailed}

                />
            )}
            {showtoast.show && showtoast.name === "stepcount" && (
                <ErrorToastNew
                    show={showtoast.show}
                    Message={t("Reportpage.StepCount")}
                    setHasFailed={setHasFailed}

                />
            )}
            {showtoast.show && showtoast.name === "error" && (
                <ErrorToastNew
                    show={showtoast.show}
                    Message={t("Common.ServerErrorMsg")}
                    setHasFailed={setHasFailed}

                />
            )}

            <div className="container-fluid" >
                <Row>
                    <Col lg="6" md="6" sm="12" xs="12" className="">
                        <Row>
                            <Col lg="8" md="12" sm="12" xs="12" className="  ms-5 mt-6">
                                <Label>
                                    {t("Reportpage.Report Name")}{" "}
                                    <span className="text-danger">*</span>
                                </Label>
                                <div className="d-flex">
                                    <div className="reportsdropdownitem">
                                        <Select
                                            id="reportDropdown"
                                            placeholder={selectedReportName}
                                            value={selectedReportName}
                                            options={reportsList}
                                            onChange={handleReportNameChange}
                                            styles={{
                                                menuList: (base) => ({
                                                    ...base,
                                                    maxHeight:
                                                        window.screen.availWidth < 1024 ? "180px" : "181px",
                                                }),
                                            }}
                                            // components={{
                                            //     Option: SingleOption,
                                            // }}
                                            classNamePrefix="react-select"
                                        ></Select>
                                    </div>

                                    {((page === "My favourites") || (selectedReportName && selectedReportName.value !== "MN_DAT1" && selectedReportName.value !== "MN_DAT2" && selectedReportName.value !== "MN_CA" && selectedReportName.value !== "MN_TP")) && (
                                        <div className="reportinfoiconitem">
                                            <Info24
                                                id="tskRetInfo"
                                                className={" mt-n2"}
                                            ></Info24>
                                            <UncontrolledPopover trigger="hover focus" placement="right" target="tskRetInfo"
                                            // popperClassName="popover-dark"
                                            >
                                                {page === "My favourites" ?
                                                    <PopoverBody><Trans i18nKey="Reportpage.favinfo"></Trans></PopoverBody>
                                                    :
                                                    <>
                                                        <PopoverHeader>{selectedReportName.label}</PopoverHeader>
                                                        <PopoverBody> {selectedReportName.description}</PopoverBody>
                                                    </>
                                                }
                                            </UncontrolledPopover>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {preformattedParamsVisible &&
                            <Row>
                                <Col lg="8" md="12" sm="12" xs="12" className={page === "My favourites" ? " mt-2 mb-3" : "ms-5 mt-2 mb-n4"}>
                                    <UserParameters
                                        selectData={preformattedParamsArray}
                                        valueData={reportValueData}
                                        setReportValueData={setReportValueData}
                                        reportID={reportID}
                                        resetTrigger={resetTrigger}
                                        page={page}
                                    />
                                </Col>

                            </Row>
                        }
                        {detailSelect && (
                            <Row>
                                <Col lg="8" md="12" sm="12" xs="12" className=" ms-5 ">
                                    <Label>
                                        {t("Reportpage.Detail")}{" "}
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <div className="d-flex">
                                        <div className="reportsdropdownitem">
                                            <Select
                                                id="detailDropdown"
                                                placeholder={""}
                                                value={(selectedDetail === "") ? "" : detailList.find((option) => option.value === selectedDetail)}
                                                options={detailData}
                                                onChange={handleDetailChange}
                                                styles={{
                                                    menuList: (base) => ({
                                                        ...base,
                                                        maxHeight:
                                                            window.screen.availWidth < 1024
                                                                ? "120px"
                                                                : window.screen.availWidth < 1281
                                                                    ? "130px"
                                                                    : "175px",
                                                    }),
                                                }}

                                                // components={{
                                                //     Option: SingleOption,
                                                // }}
                                                classNamePrefix="react-select"
                                            ></Select>
                                        </div>
                                    </div>
                                </Col></Row>
                        )}

                        {expirySelect && (
                            <Row>
                                <Col lg="8" md="8" sm="8" xs="8" className=" ms-5 mt-2 mb-2">
                                    <Label>
                                        {t("Reportpage.Expiry")}{" "}<span className="text-danger">*</span>
                                    </Label>
                                    <div className="d-flex">
                                        <div className="reportsdropdownitem">
                                            <Select
                                                id="expiryDropdown"
                                                placeholder={selectedExpiry}
                                                value={expiryData.find((opt) => opt.value === selectedExpiry)}
                                                options={expiryData}
                                                onChange={handleExpiryChange}
                                                styles={{
                                                    menuList: (base) => ({
                                                        ...base,
                                                        maxHeight: "146px",
                                                    }),

                                                }}
                                                classNamePrefix="react-select"
                                            ></Select>
                                        </div>
                                    </div>
                                </Col></Row>
                        )}

                        {datePickerVisible && (
                            <>
                                <Row>
                                    <Col xs="8" className=" ms-5 mt-2 mb-2">
                                        <div className="">
                                            <div className="reportinfoiconitem">
                                                <Label>
                                                    {t("Reportpage.Date Range")}{" "}
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Info24
                                                    id="dateRangeIcon"
                                                    className={" mt-n2"}
                                                ></Info24>
                                                <UncontrolledPopover trigger="hover focus" placement="right" target="dateRangeIcon"
                                                // popperClassName="popover-dark"
                                                >
                                                    <PopoverHeader>{t("Reportpage.Date Range")}</PopoverHeader>
                                                    <PopoverBody>  <span >
                                                        <Trans i18nKey="Reportpage.dateinfo">
                                                            Default Period = 12 months from today’s date
                                                        </Trans>
                                                    </span>
                                                        <div>
                                                            <Trans i18nKey="Reportpage.dateinfo1">
                                                                This field is user defined
                                                            </Trans>
                                                        </div> </PopoverBody>
                                                </UncontrolledPopover>
                                            </div>

                                        </div>
                                        <Row>
                                            <Col xs="6">
                                                <div className="">
                                                    <Label
                                                    >
                                                        {t("Reportpage.Start Date")}
                                                    </Label>
                                                    <div
                                                        id="startinputgrp"
                                                        className={" mt-3"
                                                            // + startdateborder
                                                        }
                                                    >

                                                        <Datepicker
                                                            id="datePickerStart"

                                                            options={{
                                                                dateFormat: "d M Y",
                                                                allowInput: false,
                                                                position: "below",
                                                                static: true,
                                                                onChange: (selectedDates, dateStr, instance) => handleDateChange(selectedDates, dateStr, instance)
                                                            }}
                                                            // options={dateoptions}
                                                            style={{ zIndex: "1" }}
                                                            value={selectedReportName.value === "T_REQ"
                                                                ? datepickerEndDate
                                                                : datepickerStartDate}
                                                        ></Datepicker>

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs="6"> <div className="">
                                                <Label
                                                >
                                                    {t("Reportpage.End Date")}
                                                </Label>
                                                <div
                                                    id="endinputgrp"
                                                    className={" mt-3  "
                                                    }
                                                >

                                                    <Datepicker
                                                        id="datePickerEnd"

                                                        options={{
                                                            dateFormat: "d M Y",
                                                            allowInput: false,
                                                            position: "below",
                                                            static: true,
                                                            onChange: (selectedDates, dateStr, instance) => handleDateChange(selectedDates, dateStr, instance)
                                                        }}
                                                        // options={dateoptions}

                                                        value={selectedReportName.value === "T_REQ"
                                                            ? datepickerTREndDate
                                                            : datepickerEndDate}
                                                    ></Datepicker>

                                                </div>
                                            </div>
                                                {exceedDateErr && (
                                                    <div
                                                        className="float-start text-danger h7"
                                                        id="errorDateRange"
                                                    >
                                                        {t("Reportpage.DateErrormsg")}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row className=" col-auto">
                            <Col>
                                {removeBtnVisible &&
                                    <Button
                                        className="rounded-pill btn-style mt-5 mb-5 ms-5 me-n4"
                                        color="standard-secondary"
                                        size="md"
                                        onClick={() => {
                                            setshowtoast({ name: "cancel", show: true });
                                        }}
                                    >
                                        <div> {t("Reportpage.Remove")}</div>
                                    </Button>
                                }

                                <Button
                                    className="rounded-pill btn-style mt-5 mb-5 ms-5"
                                    color="standard-primary"
                                    size="md"
                                    onClick={() => {
                                        setReportDownloading(true);
                                        setIsRunDisabled(true);
                                        createReport();
                                    }}
                                    disabled={isRunDisabled}
                                >
                                    {reportDownloading ? (
                                        <Spinner as="span" size="sm" />
                                    ) : (
                                        <div> {t("Reportpage.Run")}</div>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        {userParamsVisible && (
                            <>
                                <Row >
                                    <Col xs="6" className="mt-5 mb-2">
                                        <div className="ms-5">
                                            <Label>
                                                {t("Reportpage.User Defined Parameter")}
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col xs="6" className="text-end mt-5 mb-2">
                                        <Button
                                            className="rounded-pill btn-style "
                                            color="standard-primary"
                                            size="md"
                                            onClick={resetall}
                                        >
                                            {t("Reportpage.Reset All")}

                                        </Button>

                                    </Col>
                                    <React.Fragment >
                                        <UserParameters
                                            selectData={preformattedParamsArray}
                                            valueData={reportValueData}
                                            setReportValueData={setReportValueData}
                                            reportID={reportID}
                                            resetTrigger={resetTrigger}
                                            page={page}
                                        />
                                    </React.Fragment>
                                </Row>
                            </>
                        )}
                        {activityBoxSizeVisible && (
                            <>
                                <Row className="mt-5">
                                    <Col lg="12" className="mt-4 ms-5 ">
                                        <Label>
                                            {t("Reportpage.Activities")}{" "}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" className=" ms-5 mb-5 ">
                                        <div className="ag-theme-material gridHtWdOTFActivityGrid">
                                            <AgGridReact
                                                ref={gridRef}
                                                columnDefs={gridColDefs}
                                                rowData={gridData}
                                                suppressMenuHide={true}
                                                overlayNoRowsTemplate={
                                                    dataLoaded === false ? t("Loading") : t("NoRecords")
                                                }
                                                defaultColDef={gridDefaultColDef}
                                                pagination={true}
                                                paginationPageSizeSelector={[10, 20, 50, 100]}
                                                paginationPageSize={pageSize}
                                                suppressScrollOnNewData={true}
                                                resizeable={true}
                                                rowSelection={"multiple"}
                                                postProcessPopup={postProcessPopup}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" className="mt-3 ms-5">
                                        <div
                                            className="col-6"
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <Label>
                                                {" "}
                                                {t("Reportpage.Modify Box Sizes")}
                                            </Label>
                                            <Info24
                                                id="tskRetInfo1"
                                                className="mt-n3"
                                            ></Info24>
                                            <UncontrolledPopover trigger="hover focus" placement="right" target="tskRetInfo1"
                                            // popperClassName="popover-dark"
                                            >
                                                <PopoverHeader>
                                                    <label className={" "}>
                                                        {t("Reportpage.Modify Box Sizes")}
                                                    </label></PopoverHeader>
                                                <PopoverBody>
                                                    <Row>
                                                        <Col lg="6" className="text-center">
                                                            {t("Reportpage.Box Size")}
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            {t("Reportpage.Text Length")}
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            1
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            25
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            2
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            50
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            3
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            75
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            4
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            100
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            5
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            125
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            6
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            150
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            7
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            175
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            8
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            200
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            9
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            225
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            10
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            250
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col lg="6" className="text-center">
                                                            11
                                                        </Col>
                                                        <Col lg="6" className="text-center">
                                                            225
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                        <Col lg="12">
                                                            <Trans i18nKey="Reportpage.modifyinfo">
                                                                ** Max number of characters that box size can hold.
                                                            </Trans>
                                                        </Col>
                                                    </Row>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="col-auto mt-3 ms-5 me-5" >
                                                <Button
                                                    className="rounded-pill"
                                                    size="md"
                                                    color="standard-secondary"
                                                    onClick={() => {
                                                        changeBoxSizePlus(0);
                                                    }}
                                                >
                                                    {t("Reportpage.Default (EN)")}
                                                </Button>
                                            </div>
                                            <div className="col-auto mt-3 ">
                                                <Button
                                                    className="rounded-pill  ms-2 me-5"
                                                    size="md"
                                                    color="standard-secondary"
                                                    onClick={() => {
                                                        changeBoxSizePlus(1);
                                                    }}
                                                >
                                                    {t("Reportpage.Default+1")}
                                                </Button>
                                            </div>
                                            <div className="col-auto mt-3 ">
                                                <Button
                                                    className="rounded-pill btn-style ms-2"
                                                    size="md"
                                                    color="standard-secondary"
                                                    onClick={() => {
                                                        changeBoxSizePlus(2);
                                                    }}
                                                >
                                                    {t("Reportpage.Default+2")}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {addFavVisible && (
                            <Row className={page === "Preformatted" ? "mt-5" : "mb-2"}>
                                <Col lg="8" md="12" sm="12" xs="12" className="  ms-5 mt-6">
                                    <Input
                                        id="addfavchkbox"
                                        type="checkbox"
                                        checked={favCheckbox}
                                        style={{ marginTop: "0px" }}
                                        onChange={handlefavcheckbox}
                                    ></Input>
                                    <Label
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "15px",
                                            marginBottom: "9x",
                                            alignSelf: "end",
                                        }}
                                    >
                                        {t("Reportpage.Add to my Favourite")}
                                    </Label>
                                    {showFavouriteSave && (
                                        <div className="d-flex">
                                            <div className="reportsdropdownitem">
                                                <div id="savefavreport" className="mt-2">
                                                    <div className="">
                                                        <Label>{t("Reportpage.Save my Report as")}{" "}</Label>
                                                        <span className="text-danger">*</span>
                                                    </div>

                                                    <InputGroup size="md"  >
                                                        <Input
                                                            ref={inputfavname}
                                                            id="favreporttext"
                                                            value={favreportdesc}
                                                            className=""
                                                            maxLength={60}
                                                            onChange={(e) => {
                                                                handlefavnamechange(e);
                                                            }}
                                                            invalid={duplicatefavname}
                                                            autoComplete="off"
                                                        ></Input>
                                                        <Button onClick={() => saveToMyFav()} color="darker-outline"><Save24 /></Button>
                                                    </InputGroup>
                                                    <Row >
                                                        <Col xs="9" className="">

                                                            {reqfavname && (
                                                                <span className="desclength">
                                                                    {t("ErrorMessages.Required")}
                                                                </span>
                                                            )}
                                                            {duplicatefavname && (
                                                                <span className="desclength">
                                                                    {t("Reportpage.sameNameFav")}
                                                                </span>
                                                            )}
                                                        </Col>
                                                        <Col xs="3" className="text-end">

                                                            <label className="desclength ">
                                                                {favnameremaining}/60
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row >
            </div >
        </div >
    )
};
export default ReportLayout;
