import React from 'react';
import { useState, useRef, useEffect } from "react";
import { Row, Col, Input, Label, PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import Select from "react-select";
import { useUser } from '../../../Shared/userContext'
import { useModuleContext } from "../../../Shared/moduleContext";
import translations from '../../../../i18n/locales/en/translations.json'
import { Datepicker } from '@bphxd/ds-core-react';
import { Info24 } from "@bphxd/ds-core-react/lib/icons";
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import base from "../../../../Services/BaseService";

const ModuleTab = ({ retrainOptions, globalModules, create, invalid, setIsChangedModule, setInvalid, issueSelected, setissueSelected, selecteddate, setselecteddate }) => {
    const emptyGlobal = {
        "value": "",
        "label": "",
        "text": "",
        "allData": {
            "id": 0,
            "taskID": "",
            "task_Number": "",
            "task_Critical": "",
            "retrainPeriod": "",
            "task_Type": "",
            "task_Name": "",
            "g_TaskID": "",
            "task_Site": "",
            "approved": "",
            "global_TaskID": "",
            "footer": "",
            "risk_Rating": ""
        }
    }
    const { t } = useTranslation();
    const fp = useRef(null);
    const { moduleData, moduleLanguagePreference, setModuleData, globalUser } = useModuleContext();
    const dropData = translations.drops;
    const { userData } = useUser();
    // const { userLevel } = userData;
    const [disabled, setDisabled] = useState([]);
    const dateoptions = {
        onChange: (selectedDates) => handleChangeNew("issueDate", selectedDates, 6),
        allowInput: false,
        disabled: true
    };
    const createEnabled = !create;
    const [evergreenCheck, setEvergreenCheck] = useState(moduleData[0].evergreen);
    const [showSurveyPoll, setshowSurveyPoll] = useState("hideInitial");
    const [surveyPollCheck, setSurveyPollCheck] = useState(moduleData[0].caS_SRVY);
    const [versionSelectClass, SetversionSelectClass] = useState("hideInitial");
    const [ddlRegionalData, setDDLRegionalData] = useState([]);
    const [ddlGlobalTaskData, setDDLGlobalTaskData] = useState([]);
    const [showEvergreen, setshowEvergreen] = useState("hideInitial");
    const maxLenTaskName = 125;
    const [criticalReset, setCriticalReset] = useState(false)
    const [curricReset, setCurricReset] = useState(false)
    const [taskRetentionCheck, setTaskRetentionCheck] = useState(false);
    const [ddlVersionSelected, setDDLVersionSelected] = useState(moduleData[0].task_Site);
    const [ddlCurriculumSelected, setDDLCurriculumSelected] = useState(moduleData[0].task_Type);
    const [ddlRegionalSelected, setDDLRegionalSelected] = useState("");
    const [disabledRegionalDDL, setdisabledRegionalDDL] = useState(true);
    const [disabledGTASKDDL, setdisabledGTASKDDL] = useState(true);
    const taskNumberDisable = moduleData.task_Site === "SITE" && moduleData.global_TaskID !== moduleData.taskID ? true : false;
    const selectYornData = Object.keys(dropData.yorn).map((key) => ({
        label: t(`drops.yorn.${key}`),
        value: key,
    }));
    const [ddlRetrainPeriodData, setDDLRetrainPeriodData] = useState(() => {
        if (moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.retrainPeriod && moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Critical) {
            switch (moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Critical) {
                case 'Y':
                    return retrainOptions.retrainOptions.critical.map((key) => ({
                        label: key.pValue2,
                        value: key.pValue1,
                    }));
                case 'P':
                    return retrainOptions.retrainOptions.partial.map((key) => ({
                        label: key.pValue2,
                        value: key.pValue1,
                    }));
                case 'N':
                    if (moduleData[0].task_Type === "NON" && retrainOptions.retrainOptions.noncritical.length === 7) {
                        retrainOptions.retrainOptions.noncritical.push({ pValue1: "N/A", pValue2: "N/A" })
                    } else if (ddlCurriculumSelected !== "NON") {
                        if (retrainOptions.retrainOptions.noncritical.length === 8) {
                            retrainOptions.retrainOptions.noncritical.pop()
                        }
                    }
                    return retrainOptions.retrainOptions.noncritical.map((key) => ({
                        label: key.pValue2,
                        value: key.pValue1,
                    }));
                default:
                    return [];
            }
        } else {
            return [];
        }
    });

    function convertUnicode(input) {
        if (input != null) {
            return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
            });
        }
    }

    const selectCriticalTierData = Object.keys(dropData.criticalTier).map((key) => ({
        label: t(`drops.criticalTier.${key}`),
        value: key,
    }));

    let selectVersionData = [{}]
    if (userData.userLevel <= 20) {
        selectVersionData = Object.keys(dropData.version).map((key) => ({
            label: t(`drops.version.${key}`),
            value: key,
        }));
    }
    else {
        selectVersionData[0].label = t(`drops.version.SITE`)
        selectVersionData[0].value = "SITE"
    }

    const [ddlCurriculumData, setDDLCurriculumData] = useState(createEnabled ? [{
        label: t(`drops.siteCurriculum.${moduleData[0].task_Type}`),
        value: moduleData[0].task_Type,
    }] : []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
    };

    const customStyles1 = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "195px"
        })
    };

    const customStyles2 = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "110px"
        })
    };

    const ADDRedBorderDynamically = (id) => {
        if (
            document.getElementById(id) != null &&
            document.getElementById(id) !== undefined
        ) {
            document.getElementById(id).classList.add("redBorder");
        }
    };

    const removeRedBorderDynamically = (id) => {
        if (
            document.getElementById(id) != null &&
            document.getElementById(id) !== undefined
        ) {
            document.getElementById(id).classList.remove("redBorder");
            document.getElementById(id).style.border = "";
        }
    };
    const removeredMessagesforDropdown = (id) => {
        if (
            document.getElementById(id) != null &&
            document.getElementById(id) !== undefined
        ) {
            document.getElementById(id).innerHTML = "";
        }
    };
    const showEvergreenAndSurveyPoll = () => {
        if (ddlVersionSelected === "GLOBAL") {
            if (ddlCurriculumSelected === "NON") {
                setshowEvergreen("dispayEvergreen");
                setshowSurveyPoll("hideInitial");
            }
            if (ddlCurriculumSelected === "SYL") {
                setshowSurveyPoll("dispayEvergreen");
                setshowEvergreen("hideInitial");
            }
            if (ddlCurriculumSelected === "EXT" || ddlCurriculumSelected === "TSK") {
                setshowEvergreen("hideInitial");
                setshowSurveyPoll("hideInitial");
            }
        }
        if (ddlVersionSelected === "SITE") {
            if (ddlCurriculumSelected === "NON") {
                setshowEvergreen("dispayEvergreen");
                setshowSurveyPoll("hideInitial");
            }
            if (ddlCurriculumSelected === "SYL") {
                setshowEvergreen("hideInitial");
                setshowSurveyPoll("dispayEvergreen");
            }
            if (ddlCurriculumSelected === "EXT") {
                setshowEvergreen("hideInitial");
                setshowSurveyPoll("hideInitial");
            }
            if (ddlCurriculumSelected === "DAT" || ddlCurriculumSelected === "TSK") {
                setshowEvergreen("hideInitial");
                setshowSurveyPoll("hideInitial");
            }
        }

        if (ddlCurriculumSelected !== "" && ddlVersionSelected === "") {
            setDDLCurriculumSelected("");
            ADDRedBorderDynamically("Task_Type");
            SetversionSelectClass("dispayVesionClass");
        }
        if (ddlCurriculumSelected === "" && ddlVersionSelected === "") {
            if (versionSelectClass !== "dispayVesionClass") {
                SetversionSelectClass("hideInitial");
                removeRedBorderDynamically("Task_Type");
            }
        }
    };

    const setIssueNumber = (footer) => {
        if (footer.toString().split(":")[1] !== undefined) {
            var issue = footer
                .toString()
                .split(":")[1]
                .toString()
                .trim()
                .split(" ")[0];
            if (issue.toString().toLowerCase().includes("date")) {
                issue = issue.toString().toLowerCase().replace("date", "");
            }
            setissueSelected(issue);
        } else {
            setissueSelected("0");
        }
    }

    const setIssueDate = (footer) => {
        if (footer.toString().split(":")[2] !== undefined) {
            const dateString = footer.toString().split(":")[2];
            const dateObj = new Date(dateString);
            setselecteddate(dateObj);
        }
    }
    const getRetrainPeriods = (e) => {
        setDDLRetrainPeriodData(() => {
            switch (e) {
                case 'Y':
                    document.getElementById("RetrainInformationError").style.display =
                        "none";
                    return retrainOptions.retrainOptions.critical.map((key) => ({
                        label: key.pValue2,
                        value: key.pValue1,
                    }));
                case 'P':

                    return retrainOptions.retrainOptions.partial.map((key) => ({
                        label: key.pValue2,
                        value: key.pValue1,
                    }));
                case 'N':
                    if (ddlCurriculumSelected === "NON" && retrainOptions.retrainOptions.noncritical.length === 7) {
                        retrainOptions.retrainOptions.noncritical.push({ pValue1: "N/A", pValue2: "N/A" })
                    } else if (ddlCurriculumSelected !== "NON") {
                        if (retrainOptions.retrainOptions.noncritical.length === 8) {
                            retrainOptions.retrainOptions.noncritical.pop()
                        }
                    }
                    return retrainOptions.retrainOptions.noncritical.map((key) => ({
                        label: key.pValue2,
                        value: key.pValue1,
                    }));
                default:
                    return [];
            }
        });
    };

    useEffect(() => {
        if (create === false && globalUser === "GADMIN" && moduleData[0].task_Site === "GLOBAL" && (moduleData[0].global_TaskID === "GBL10058" || moduleData[0].global_TaskID === "GBL10057")) {
            setDisabled([])
        }
        if (create === false && globalUser !== "GADMIN" && moduleData[0].task_Site === "GLOBAL" && moduleLanguagePreference !== "en-GB") {
            setDisabled(["task_Number", "task_Critical", "retrainPeriod", "risk_Rating", "issueNumber", "issueDate", "taskRet", "pollcheck", "evergreen", "approved"])
        }
        if (create === false && moduleData[0].task_Site === "SITE" && moduleLanguagePreference !== "en-GB") {
            setDisabled(["task_Number", "task_Critical", "risk_Rating", "pollcheck", "evergreen"])
        }
        if (create === false && moduleData[0].task_Site === "SITE" && moduleLanguagePreference === "en-GB") {
            setDisabled(["task_Name", "task_Number", "task_Critical", "risk_Rating", "pollcheck", "evergreen"])
        }
        if (create === false && moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type === "TSK" && moduleLanguagePreference === "en-GB") {
            setDisabled(["task_Name", "task_Number", "task_Critical", "risk_Rating", "pollcheck", "evergreen", "retrainPeriod", "issueNumber", "issueDate", "approved", "taskRet"])
        }
        if (create === false && globalUser !== "GADMIN" && moduleData[0].task_Site === "GLOBAL" && moduleData[0].task_Type !== "TSK" && moduleLanguagePreference === "en-GB") {
            setDisabled(["task_Name", "task_Number", "task_Critical", "risk_Rating", "pollcheck", "evergreen", "retrainPeriod", "issueNumber", "issueDate", "approved", "taskRet"])
        }
        if (create === false && moduleData[0].task_Site === "GLOBAL" && moduleLanguagePreference !== "en-GB") {
            setDisabled(["task_Number", "task_Critical", "risk_Rating", "pollcheck", "evergreen", "retrainPeriod", "issueNumber", "issueDate", "approved", "taskRet"])
        }
        if (create === false && moduleData[0].task_Site === "SITE" && (moduleData[0].taskID === moduleData[0].global_TaskID)) {
            setDisabled([])
        }
        if (create === false) {
            setdisabledGTASKDDL(true);
            setdisabledRegionalDDL(true);
        }
        if (create === true && ddlCurriculumSelected === "NON") {
            setDisabled(["task_Critical"])
        }
    }, [moduleLanguagePreference, globalUser, create, moduleData, ddlCurriculumSelected]);


    useEffect(() => {
        let moduleCopy = moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference);
        if (
            moduleCopy?.footer != null &&
            moduleCopy?.footer !== undefined &&
            moduleCopy?.footer !== ""
        ) {
            setIssueNumber(moduleCopy?.footer)
            setIssueDate(moduleCopy?.footer)
        }
        if (
            moduleCopy?.fk_PUID !== "" &&
            moduleCopy?.fk_PUID != null
        ) {
            setDDLRegionalSelected({
                value: moduleCopy?.fk_PUID,
                label: moduleCopy?.fk_PUID,
            });
        }
        if (
            moduleCopy?.fk_CountryID !== "" &&
            moduleCopy?.fk_CountryID != null
        ) {
            setDDLRegionalSelected({
                value: moduleCopy?.fk_CountryID,
                label: moduleCopy?.fk_CountryID,
            });
        }
        if (
            moduleCopy?.fk_LocationID !== "" &&
            moduleCopy?.fk_LocationID != null
        ) {
            setDDLRegionalSelected({
                value: moduleCopy?.fk_LocationID,
                label: moduleCopy?.fk_LocationID,
            });
        }
        if (moduleCopy?.taskRetention === true) {
            setTaskRetentionCheck(true)
        } else {
            setTaskRetentionCheck(false)
        }

        var regional = [];
        var PUUnits = [];
        var countries = [];
        var locations = [];

        const promises = [
            base.getAll(`performanceunit/Qry`),
            base.getAll(`Country/Qry`),
            base.getAll(`Location/Qry`)
        ];

        Promise.all(promises)
            .then(([puList, ctyList, locnList]) => {
                var item = {};
                for (let i = 0; i < puList.length; i++) {
                    item = {};
                    item.value = puList[i].puid;
                    item.label = puList[i].puid;
                    item.text = puList[i].pU_Description;
                    item.isPU = true;
                    item.isCountry = false;
                    item.location = false;
                    PUUnits.push(item);
                }
                for (let i = 0; i < ctyList.length; i++) {
                    item = {};
                    item.value = ctyList[i].countryID;
                    item.label = ctyList[i].countryID;
                    item.text = ctyList[i].country_Description;
                    item.isPU = false;
                    item.isCountry = true;
                    item.location = false;
                    countries.push(item);
                }
                for (let i = 0; i < locnList.length; i++) {
                    item = {};
                    item.value = locnList[i].locationID;
                    item.label = locnList[i].locationID;
                    item.text = locnList[i].location_Description;
                    item.isPU = false;
                    item.isCountry = false;
                    item.location = true;
                    locations.push(item);
                }
                setDDLRegionalData(regional.concat(PUUnits).concat(countries).concat(locations));
            }
            );
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        var GTask = [];
        showEvergreenAndSurveyPoll();

        if (ddlCurriculumSelected === "TSK") {
            GTask = [];
            const filteredOptions = globalModules.filter((option) => option.task_Type === "TSK");
            filteredOptions.map((option) => {
                var item = {};
                item.value = option.taskID;
                item.label = option.task_Number;
                item.text = convertUnicode(option.task_Name);
                item.allData = option;
                GTask.push(item);
                return item;
            });
            setDDLGlobalTaskData(GTask);
        }
        if (ddlCurriculumSelected === "DAT") {
            GTask = [];
            var taskID = ""
            if (moduleData[0].global_TaskID === "GBL10058") {
                taskID = "GBL10058"
            } else if (moduleData[0].global_TaskID === "GBL10057") {
                taskID = "GBL10057"
            } else {
                taskID = "GBL10058"
            }
            const filteredOptions = globalModules.filter((option) => option.taskID === taskID);
            filteredOptions.forEach((option) => {
                var item = {};
                item.value = option.taskID;
                item.label = option.task_Number;
                item.text = convertUnicode(option.task_Name);
                item.allData = option;
                GTask.push(item);
            });
            if (create === true) {
                handleChangeNew("", GTask[0], 4)
            }
            setDDLGlobalTaskData(GTask);
            setdisabledGTASKDDL(true);
        }
        // eslint-disable-next-line
    }, [ddlCurriculumSelected]);

    const handleCriticalChange = (e) => {
        getRetrainPeriods(e);
    }



    const ddlVersionChange = (params) => {
        switch (params) {
            case "SITE":
                setDDLCurriculumData(Object.keys(dropData.siteCurriculum).map((key) => ({
                    label: t(`drops.siteCurriculum.${key}`),
                    value: key,
                })));
                break;
            case "GLOBAL":
                setDDLCurriculumData(Object.keys(dropData.globalCurriculum).map((key) => ({
                    label: t(`drops.globalCurriculum.${key}`),
                    value: key,
                })));
                break;
            default:
                break;
        }
        if (params === "GLOBAL") {
            setdisabledRegionalDDL(true);
            if (create === true) {
                if (ddlCurriculumSelected === "DAT") setDDLCurriculumSelected("");
            }
            removeRedBorderDynamically("RegionalInformation");
            removeredMessagesforDropdown("RegionalInformationError");
        }
        if (params === "SITE") {
            setdisabledRegionalDDL(false);
        }
        removeRedBorderDynamically("Task_Site");
        removeredMessagesforDropdown("Task_SiteErrorlbl");
        showEvergreenAndSurveyPoll();
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    }

    const handleChangeNew = (id, value, condition) => {
        if (id === "fk_PUID" || id === "fk_CountryID" || id === "fk_LocationID") {
            setInvalid(prevInvalid =>
                prevInvalid.filter(element => element !== "RegionalInformation")
            );
        } else if (id === "task_Critical") {
            setInvalid(prevInvalid =>
                prevInvalid.filter(element => element !== "retrainPeriod")
            );
            setInvalid(prevInvalid =>
                prevInvalid.filter(element => element !== id)
            );
        } else if (condition === 4) {
            setInvalid([])
        } else {
            setInvalid(prevInvalid =>
                prevInvalid.filter(element => element !== id)
            );
        }

        if (id)
            setIsChangedModule(true)

        switch (condition) {
            case 1:
                setModuleData(prevModuleData => {
                    const updatedModuleData = prevModuleData.map(module => ({
                        ...module,
                        [id]: value
                    }));
                    return updatedModuleData;
                });
                break; case 2:
                setModuleData((prevModuleData) => {
                    const updatedLanguageID = moduleLanguagePreference;
                    const updatedItemIndex = prevModuleData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
                    if (updatedItemIndex !== -1) {
                        const updatedItem = {
                            ...prevModuleData[updatedItemIndex],
                            [id]: convertUnicode(value)
                        };
                        const updatedQuestionData = [
                            ...prevModuleData.slice(0, updatedItemIndex),
                            updatedItem,
                            ...prevModuleData.slice(updatedItemIndex + 1)
                        ];
                        return updatedQuestionData;
                    }
                    return prevModuleData;
                });
                break;
            case 3:
                let puValue
                let countryValue
                let locationValue
                switch (id) {
                    case "fk_PUID":
                        puValue = value;
                        countryValue = "";
                        locationValue = "";
                        break;
                    case "fk_CountryID":
                        puValue = "";
                        countryValue = value;
                        locationValue = "";
                        break;
                    case "fk_LocationID":
                        puValue = "";
                        countryValue = "";
                        locationValue = value;
                        break;
                    default:
                        break;
                }
                setDDLRegionalSelected({
                    value: puValue + countryValue + locationValue,
                    label: puValue + countryValue + locationValue,
                });
                let moduleNumber = moduleData[0].global_Task_Number !== "" ? moduleData[0].global_Task_Number + ' ' + (puValue || '') + (countryValue || '') + (locationValue || '') : "";
                setModuleData(prevModuleData => {
                    const updatedModuleData = prevModuleData.map(module => ({
                        ...module,
                        "fk_PUID": puValue,
                        "fk_CountryID": countryValue,
                        "fk_LocationID": locationValue,
                        "task_Number": moduleNumber
                    }));
                    return updatedModuleData;
                });
                break;
            case 4:
                let footer = value.allData.footer;
                if (footer.toString().split(":")[1] !== undefined) {
                    const issue = footer.toString()
                        .split(":")[1]
                        .toString()
                        .trim()
                        .split(" ")[0];
                    setissueSelected(issue);
                }
                if (footer.toString().split(":")[2] !== undefined) {
                    const dateString = footer.toString().split(":")[2];
                    const dateObj = new Date(dateString);
                    setselecteddate(dateObj);
                }
                getRetrainPeriods(value.allData.task_Critical)
                setModuleData(prevModuleData => {
                    const updatedModuleData = prevModuleData.map(module => ({
                        ...module,
                        "task_Type": value.allData.task_Type,
                        "task_Number": value.allData.task_Number === "" ? "" : value.allData.task_Number + ' ' + (moduleData[0].fk_PUID || '') + (moduleData[0].fk_CountryID || '') + (moduleData[0].fk_LocationID || ''),
                        "task_Name": value.allData.task_Name,
                        "task_Critical": value.allData.task_Critical,
                        "retrainPeriod": value.allData.retrainPeriod,
                        "risk_Rating": value.allData.risk_Rating,
                        "footer": value.allData.footer,
                        "global_TaskID": value.allData.taskID,
                        "global_Task_Number": value.allData.task_Number,
                        "global_Task_Name": value.allData.task_Name
                    }));

                    return updatedModuleData;
                });
                let disabledFields = [];
                if (value.allData.taskID !== "") {
                    disabledFields.push("task_Name");
                    disabledFields.push("task_Number");
                    disabledFields.push("task_Critical");
                    disabledFields.push("risk_Rating");
                }
                setDisabled(disabledFields);
                break;
            case 5:
                let retrainValue = ""
                switch (value) {
                    case "Y":
                        retrainValue = "365"
                        break;
                    default:
                        retrainValue = "730"
                        break;
                }
                setModuleData(prevModuleData => {
                    const updatedModuleData = prevModuleData.map(module => ({
                        ...module,
                        [id]: value,
                        retrainPeriod: retrainValue
                    }));
                    return updatedModuleData;
                });
                break;
            case 6:
                setInvalid(prevInvalid =>
                    prevInvalid.filter(element => element !== id)
                );
                setModuleData(prevModuleData => {
                    let footer
                    let formattedDate
                    switch (id) {
                        case "issueNumber":
                            formattedDate = formatDate(selecteddate);
                            footer = `Issue: ${value} Date: ${formattedDate}`
                            break;
                        case "issueDate":
                            formattedDate = formatDate(value[0]);
                            setselecteddate(value)
                            footer = `Issue: ${issueSelected} Date: ${formattedDate}`
                            break;
                        default:
                            break;
                    }
                    const updatedModuleData = prevModuleData.map(module => ({
                        ...module,
                        "footer": footer
                    }));
                    return updatedModuleData;
                });
                break;
            case 7:
                setModuleData(prevModuleData => {
                    const updatedModuleData = prevModuleData.map(module => ({
                        ...module,
                        [id]: value,
                        "global_TaskID": "",
                        "global_Task_Number": "",
                        "global_Task_Name": "",
                        "task_Number": "",
                        "task_Name": "",
                        "task_Critical": "",
                        "retrainPeriod": "",
                        "risk_Rating": "",
                    }));
                    return updatedModuleData;
                });
                break;
            default:
                break;
        }
    };

    const enabledisableGlobalModule = (id, value) => {
        const taskType = id === "task_Type" ? value : moduleData[0].task_Type;
        const taskSite = id === "task_Site" ? value : moduleData[0].task_Site;

        if (taskType === "TSK" && taskSite === "SITE") {
            setdisabledGTASKDDL(false);
        }
        if (taskType !== "TSK") {
            setdisabledGTASKDDL(true)
            setDisabled([])
        }
        setselecteddate("")
        setissueSelected("")
    }
    // Find the option that matches moduleData[0].global_TaskID
    const selectedOption = ddlGlobalTaskData.find(
        (option) => option.value === moduleData[0]?.global_TaskID
    );

    // Set the value based on whether a matching option is found or not
    const valueToSet = selectedOption ? selectedOption : '';
    return (
        <div>
            <div className="ms-3 me-3  mt-1">
                <div className="text-end mb-2 pt-1 me-4 mt-2">
                    <div className="p-2" />
                </div>
                <Row>
                    <Col className="pb-2" xs="6" xl="6">
                        <Label>
                            {t("Curriculumpage.Version")}{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        <Select
                            id="task_Site"
                            name="Task_Site"
                            placeholder={moduleData[0].task_Site === "GLOBAL" ? "Global" : ""}
                            value={selectVersionData.find((option) => option.value === moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Site || '')}
                            options={selectVersionData}
                            onChange={(e) => {
                                setCurricReset(true)
                                setDDLVersionSelected(e.value);
                                ddlVersionChange(e.value);
                                handleChangeNew("task_Site", e.value, 1)
                                enabledisableGlobalModule("task_Site", e.value)
                            }}
                            isDisabled={createEnabled}
                            styles={customStyles}
                            invalid={invalid.includes(`task_Site`)}
                            classNamePrefix="react-select"
                        ></Select>
                    </Col>
                    <Col className="pb-2" xs="6" xl="6">
                        <Label>
                            {t("Curriculumpage.Curriculum")}{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        <Select
                            id="task_Type"
                            name="Task_Type"
                            placeholder={""}
                            value={curricReset ? "" : ddlCurriculumData.find((option) => option.value === moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Type || '')}
                            options={ddlCurriculumData}
                            onChange={(e) => {
                                setCurricReset(false)
                                if (e.value !== "DAT") {
                                    setCriticalReset(true)
                                } else {
                                    setCriticalReset(false)
                                }
                                setDDLCurriculumSelected(e.value);
                                if (e.value !== "DAT") {
                                    handleChangeNew("task_Type", e.value, 7)
                                }
                                if (e.value === "NON" && create === true) {
                                    setModuleData(prevModuleData => {
                                        const updatedModuleData = prevModuleData.map(module => ({
                                            ...module,
                                            "task_Critical": "N",
                                        }));
                                        return updatedModuleData;
                                    });
                                    setCriticalReset(false)
                                    getRetrainPeriods("N")
                                }
                                enabledisableGlobalModule("task_Type", e.value)
                            }}
                            styles={customStyles}
                            invalid={invalid.includes(`task_Type`)}
                            isDisabled={createEnabled}
                            classNamePrefix="react-select"
                        ></Select>
                    </Col>
                    <Col xs="12" xl="6">
                        <div>
                            <Label >
                                {t("Curriculumpage.Regional_Information")}
                            </Label>
                            <div>
                                <Select
                                    id="RegionalInformation"
                                    placeholder={""}
                                    isDisabled={disabledRegionalDDL}
                                    value={ddlRegionalSelected}
                                    options={ddlRegionalData}
                                    onChange={(e) => {
                                        const field = e.isPU ? "fk_PUID" : e.isCountry ? "fk_CountryID" : "fk_LocationID"
                                        handleChangeNew(field, e.value, 3);
                                    }}
                                    styles={customStyles1}
                                    invalid={invalid.includes(`RegionalInformation`)}
                                    components={{
                                        Option: (props) => (
                                            <div id=" ">
                                                <components.Option {...props}>
                                                    <div
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "15% 85%",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <span> {props.value}</span>
                                                        <span> {props.label}</span>
                                                    </div>
                                                </components.Option>
                                            </div>
                                        ),
                                        IndicatorSeparator: () => null,
                                    }}
                                    classNamePrefix="react-select"
                                ></Select>
                                <div
                                    style={{ paddingLeft: 0, display: "none" }}
                                    className="col-8 float-start text-danger h7"
                                    id="RegionalInformationError"
                                >
                                    {t("ErrorMessages.Required")}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="pb-2" xs="12" xl="6">
                        <Label>
                            {t("Curriculumpage.Global_Module")}
                        </Label>
                        <div>
                            <Select
                                id="GlobalTask"
                                isDisabled={disabledGTASKDDL}
                                placeholder={""}
                                value={valueToSet}
                                options={ddlGlobalTaskData}
                                onChange={(res, actionMeta) => {
                                    let data
                                    if (actionMeta.action === 'clear') {
                                        setInvalid([]);
                                    }
                                    if (res === null) {
                                        data = emptyGlobal;
                                    } else {
                                        data = res;
                                    }
                                    setCriticalReset(false)
                                    handleChangeNew("global_TaskID", data, 4)
                                }}
                                isClearable
                                styles={{
                                    menuList: (base) => ({
                                        ...base,
                                        maxHeight: "195px"
                                    })
                                }}
                                components={{
                                    Option: (props) => (
                                        <div id=" " >
                                            <components.Option {...props}>
                                                <div
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "15% 85%",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <span> {props.data.allData.task_Number}</span>
                                                    <span> {props.data.allData.task_Name}</span>
                                                </div>
                                            </components.Option>
                                        </div>
                                    ),
                                    IndicatorSeparator: () => null,
                                }}
                                classNamePrefix="react-select"
                            ></Select>
                            <div
                                style={{ paddingLeft: 0, display: "none" }}
                                className="col-8 float-start text-danger h7"
                                id="GlobalTaskError"
                            >
                                {t("ErrorMessages.Required")}
                            </div>
                        </div>
                    </Col>
                    <Col xs="7" sm="8" xl="6">
                        <Label htmlFor="questionLable">
                            {t("Curriculumpage.Module")}{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        <div>
                            <Input
                                id="task_Number"
                                name="Task_Number"
                                maxLength={15}
                                onChange={(e) => handleChangeNew("task_Number", e.target.value, 1)}
                                disabled={disabled.includes(`task_Number`)}
                                value={moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Number}
                                className={(taskNumberDisable === true) ? "disabled-input" : ""}
                                invalid={invalid.includes(`task_Number`)}
                            ></Input>
                        </div>
                        <div
                            className="row p-0"
                        >
                            <div className="text-end desclength">
                                <span>
                                    {(moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Number.length || 0)}/15
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col className="pb-2" xs="5" sm="4" xl="3">
                        <div>
                            <Label className="label-copy ">
                                {" "}
                                {t("Curriculumpage.Approved")}
                            </Label>
                            <div className="d-flex">
                                <div style={{ flex: "0 0 150px" }}>
                                    <Select
                                        id="approved"
                                        name="approved"
                                        placeholder={""}
                                        value={selectYornData.find((option) => option.value === moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.approved || '')}
                                        options={selectYornData}
                                        onChange={(e) => {
                                            handleChangeNew("approved", e.value, 1)
                                        }}
                                        isDisabled={disabled.includes(`approved`)}
                                        classNamePrefix="react-select"
                                    ></Select>
                                </div>
                                {userData.userLevel > "30" && (
                                    <div style={{ flex: "0 0 1" }} className="mt-2">
                                        <span className="modret__infobox">
                                            <Info24
                                                id="tskRetInfo"
                                            ></Info24>
                                            <UncontrolledPopover
                                                placement="right"
                                                target="tskRetInfo"
                                                trigger="hover focus"
                                                popperClassName="popover"
                                            >

                                                <PopoverBody className="tooltip-msg">
                                                    {t("Curriculumpage.ApproveInfoText1")}
                                                    <br />
                                                    {t("Curriculumpage.ApproveInfoText2")}
                                                </PopoverBody>
                                            </UncontrolledPopover>

                                        </span>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <Label htmlFor="questionLable">
                            {t("Curriculumpage.Name")}{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        <div>
                            <Input
                                type="text"
                                id="task_Name"
                                name="Task_Name"
                                className={(taskNumberDisable === true) ? "disabled-input" : ""}
                                disabled={disabled.includes(`task_Name`)}
                                maxLength={maxLenTaskName}
                                onChange={(e) => handleChangeNew(e.target.id, e.target.value, 2)}
                                value={moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Name}
                                invalid={invalid.includes(`task_Name`)}
                            ></Input>
                        </div>
                        <div
                            className="row p-0"
                        >
                            <div className="text-end desclength">
                                {(moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Name || 0).length || 0}/{maxLenTaskName}
                            </div>
                        </div>
                    </Col>
                    <Col className="pb-2" xs="6" xl="3">
                        <Label>
                            {t("Curriculumpage.Critical")}{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        <div>
                            <Select
                                id="task_Critical"
                                name="Task_Critical"
                                placeholder={""}
                                value={criticalReset ? "" : selectCriticalTierData.find((option) => option.value === moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.task_Critical)}
                                options={selectCriticalTierData}
                                onChange={(e) => {
                                    setCriticalReset(false)
                                    handleCriticalChange(e.value);
                                    document.getElementById("CriticalInformationError").style.display =
                                        "none";
                                    handleChangeNew("task_Critical", e.value, 5)
                                }}
                                isDisabled={disabled.includes(`task_Critical`)}
                                classNamePrefix="react-select"
                                styles={customStyles}
                                invalid={invalid.includes(`task_Critical`)}
                            ></Select>
                            <div
                                style={{ paddingLeft: 0, display: "none" }}
                                className="col-12 float-start text-danger h7"
                                id="CriticalInformationError"
                            >
                                {t("ErrorMessages.Required")}
                            </div>
                        </div>
                    </Col>
                    <Col className="pb-2" xs="6" xl="3">
                        <Label>
                            {t("Curriculumpage.Retrain_Period")}
                            <span className="text-danger">*</span>
                        </Label>
                        <div><Select
                            id="retrainPeriod"
                            name="RetrainPeriod"
                            placeholder={""}
                            value={criticalReset ? "" : ddlRetrainPeriodData.find((option) => option.value === moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.retrainPeriod || null)}
                            options={ddlRetrainPeriodData}
                            onChange={(e) => {
                                setCriticalReset(false)
                                handleChangeNew("retrainPeriod", e.value, 1)
                            }}
                            styles={customStyles2}
                            invalid={invalid.includes(`retrainPeriod`)}
                            classNamePrefix="react-select"
                            isDisabled={moduleData[0].task_Critical === "Y" ? true : false || disabled.includes("retrainPeriod")}
                        ></Select>
                            <div
                                style={{ paddingLeft: 0, display: "none" }}
                                className="col-12  float-start text-danger h7"
                                id="RetrainInformationError"
                            >
                                {t("ErrorMessages.Required")}
                            </div>
                        </div>
                    </Col>
                    <Col className="pb-2" xs="6" xl="2">
                        <Label htmlFor="questionLable">
                            {t("Curriculumpage.Risk_Rating")}
                        </Label>
                        <div>
                            <Input
                                type="text"
                                id="risk_Rating"
                                name="Risk_Rating"
                                className={(taskNumberDisable === true) ? "disabled-input" : ""}
                                maxLength={6}
                                disabled={disabled.includes(`risk_Rating`)}
                                onChange={(e) => handleChangeNew("risk_Rating", e.target.value, 1)}
                                // value={taskFeed.Risk_Rating}
                                value={(moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.risk_Rating || '')}
                            ></Input>
                        </div>
                        <div className="">
                            <div className="row p-0">
                                <div className="text-end desclength">
                                    {moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.risk_Rating !== null ? moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.risk_Rating.length : 0}/6
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" xl="0" className="hide-lg pb-2" />
                    <Col className="pb-2" xs="6" xl="2">
                        <span >
                            <Label >
                                {t("Curriculumpage.Issue")}
                                <span className="text-danger">*</span>
                            </Label>
                            <Input
                                min="0"
                                type="numeric"
                                maxLength={3}
                                id="issueNumber"
                                onChange={(e) => {
                                    setissueSelected(e.target.value);
                                    handleChangeNew("issueNumber", e.target.value, 6);
                                }}
                                value={issueSelected}
                                invalid={invalid.includes(`issueNumber`)}
                                disabled={disabled.includes(`issueNumber`)}
                            />
                            <div
                                style={{ paddingLeft: 0, display: "none" }}
                                className="col-10 float-start text-danger h7"
                                id="IssueInformationError"
                            >
                                {t("ErrorMessages.Required")}
                            </div>
                        </span>
                        <div className="">
                            <div className="p-0">
                                <div className="text-end desclength">
                                    {issueSelected.length}/3
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="pb-2" xs="6" xl="2">
                        <Label>
                            {t("Curriculumpage.Date")}{" "}
                            <span className="text-danger">*</span>
                        </Label>
                        {disabled.includes(`issueDate`)
                            ?
                            <Input id="issueDateInput" value={formatDate(selecteddate)} disabled={true} />
                            :
                            <Datepicker
                                ref={fp}
                                id="issueDate"
                                style={{ backgroundColor: "transparent", width: "100%", color: "transparent", border: "0px" }}
                                options={dateoptions}
                                invalid={invalid.includes(`issueDate`)}
                                value={selecteddate}
                                disabled={true}
                            ></Datepicker>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="pb-2">
                        <div className="">
                            <Input
                                onChange={(e) => {
                                    setTaskRetentionCheck(!taskRetentionCheck);
                                    handleChangeNew("taskRetention", e.target.checked, 1)
                                }}
                                checked={taskRetentionCheck}
                                type="checkbox"
                                id="taskRet"
                                name=""
                                value=""
                                disabled={disabled.includes("taskRet")}
                                className={"pt-2"}
                                style={{ marginTop: "0px", alignSelf: "center" }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Label className="mb-2">
                                {t("Curriculumpage.Module_Retention")}
                            </Label>
                            &nbsp;&nbsp;&nbsp;
                            <span className="modret__infobox">
                                <Info24
                                    id="moduleRetPopover"
                                ></Info24>
                                <UncontrolledPopover
                                    placement="right"
                                    target="moduleRetPopover"
                                    trigger="hover focus"
                                    popperClassName="popover"
                                >
                                    <PopoverHeader>
                                        {t("Curriculumpage.Module_Retention")}
                                    </PopoverHeader>
                                    <PopoverBody className="tooltip-msg">
                                        {t("Curriculumpage.Module_Retention_Infotext")}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="pb-2">
                        <div
                            className={showEvergreen}
                        >
                            <Input
                                onChange={(e) => {
                                    setEvergreenCheck(!evergreenCheck);
                                    handleChangeNew("evergreen", e.target.checked, 1)
                                }}
                                checked={evergreenCheck}
                                type="checkbox"
                                id="evergreen"
                                disabled={disabled.includes("evergreen")}
                                name=""
                                value=""
                                className={""}
                                style={{ marginTop: "0px", alignSelf: "center" }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Label className="mt-1">
                                {t("Curriculumpage.Evergreen")}
                            </Label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="pb-2">
                        <div
                            className={showSurveyPoll}
                        >
                            <Input
                                onChange={(e) => {
                                    setSurveyPollCheck(!surveyPollCheck);
                                    handleChangeNew("caS_SRVY", e.target.checked, 1)
                                }}
                                checked={surveyPollCheck}
                                type="checkbox"
                                id="pollcheck"
                                disabled={disabled.includes("pollcheck")}
                                name=""
                                value=""
                                className={""}
                                style={{ marginTop: "0px", alignSelf: "center" }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Label className="mt-1">
                                {t("Curriculumpage.Survey_/_Poll")}
                            </Label>
                        </div>
                    </Col>
                </Row>
            </div>
        </div >
    );
}

export default ModuleTab;
