import { useEffect, useState, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import base from "../../../Services/BaseService";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { UncontrolledDropdown } from "reactstrap";
import { DropdownToggle } from "reactstrap";
import fileDownload from "js-file-download";
import "../../../Content/stylesheet/ag-grid-style.css";
import CreateModule from "./CreateModule";
import React from "react";
import "ag-grid-community";
import "ag-grid-enterprise";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew from "../../Toast/ErrorToastNew1";
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { Trash32, Edit32, DownloadBasic32 } from "@bphxd/ds-core-react/lib/icons";
import { createRoot } from "react-dom/client";
import { useModuleContext } from "../../Shared/moduleContext";
import { useUser } from "../../Shared/userContext";
import '../../../Content/stylesheet/createcurriculum.css'

const ManageCurriculum = (props) => {
  const { updateModuleData, updateLanguagePreference, globalUser } = useModuleContext();
  const { languagePreference } = useUser();
  const { t } = useTranslation();
  const [gridData, setGridData] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteCurriculum, setDeleteCurriculum] = useState({});
  const [showError, setShowError] = useState(false);
  const [deleteCurriculumConfirmToast, setDeleteCurriculumConfirmToast] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [retrainOptions, setRetrainOptions] = useState([]);
  const [globalModules, setGlobalModules] = useState([]);
  const [role, setRole] = useState();
  const [isDownloadStarted, setIsDownloadStarted] = useState(false);


  const gridRef = useRef();

  const emptyModule = [{
    "taskID": "0",
    "global_TaskID": "",
    "task_Number": "",
    "task_Name": "",
    "task_Critical": "",
    "task_Type": "",
    "task_Site": "",
    "fk_PUID": "",
    "fk_CountryID": "",
    "fk_LocationID": "",
    "approved": "N",
    "retrainPeriod": "",
    "tolerance": "30",
    "footer": "",
    "id": 0,
    "aim": "",
    "learning_Outcomes": "",
    "notes": "",
    "risk_Rating": "",
    "n_IMAGE": "",
    "s_IMAGE": "",
    "b_IMAGE": "",
    "n_IMAGE1": "",
    "s_IMAGE1": "",
    "b_IMAGE1": "",
    "n_IMAGE2": "",
    "s_IMAGE2": "",
    "b_IMAGE2": "",
    "caS_SRVY": false,
    "evergreen": false,
    "taskRetention": false,
    "global_Task_Number": "",
    "global_Task_Name": "",
    "fk_LanguageID": languagePreference
  }]

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserRole();
    initGridVar();
    // eslint-disable-next-line
  }, []);

  function docDownloadingToast() {
    setIsDownloadStarted(true);
    setTimeout(() => {
      setIsDownloadStarted(false);
    }, 3000);
  }

  const getUserRole = () => {
    base.getAll("Auth/Get").then((res) => {
      setRole(res.userLevel);
    });
  };

  const dataSaved = () => {
    setShowToast({ Name: "success", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataNotSaved = () => {
    setShowToast({ Name: "error", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  function DataSavingToastonClick() {
    setShowToast(false);
  }

  function handleSuccessClickOutside() {
    setDeleteSuccess(false);
  }

  function handleErrorClickOutside() {
    setShowError(false);
  }

  const handleDeleteCurriculum = () => {
    if (!!deleteCurriculum) {
      try {
        base
          .delete(process.env.REACT_APP_TASK_DELETE, deleteCurriculum.taskID)
          .then((response) => {
            setDeleteSuccess(true);
            getAllCurriculum();
            document.addEventListener(
              "mousedown",
              handleSuccessClickOutside
            );
            //}
          });
      } catch (err) {
        setShowError(true);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeleteCurriculumConfirmToast(false);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteCurriculumConfirmToast(false);
    setDeleteCurriculum({});
  };

  // modify pageSize

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  //redirect to respective pages based on dropdown selected values

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      minWidth: 50,
      sortable: true,
      filter: true,
      resizable: true,
      editable: false,
      wrapText: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
    });

    setGridColDefs([
      {
        headerName: t("Curriculumpage.LandingGridHeader.MODULE_NUMBER"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.MODULE_NUMBER"),
        field: "task_Number",
        minWidth: 120,
        maxWidth: 175,
        cellClass: 'right-border',
        headerClass: 'right-border',
        pinned: true,
        resizable: false,
        suppressMovable: true,
        comparator: customComparator,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.NAME"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.NAME"),
        field: "task_Name",
        minWidth: 420,
        resizable: false,
        suppressMovable: true,
        headerClass: "threatdesc-left",
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.CURRICULUM"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.CURRICULUM"),
        field: "task_Type",
        minWidth: 150,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.VERSION"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.VERSION"),
        field: "task_Site",
        minWidth: 150,
        sort: "asc",
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.CRITICAL"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.CRITICAL"),
        field: "task_Critical",
        minWidth: 150,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.RETRAIN"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.RETRAIN"),
        field: "retrainPeriod",
        minWidth: 185,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.APPROVED_PENDING"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.APPROVED_PENDING"),
        field: "approved",
        minWidth: 150,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.PRODUCE_DOCUMENT"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.PRODUCE_DOCUMENT"),
        field: "approved",
        minWidth: 130,
        resizable: false,
        suppressMovable: true,
        sortable: false,
        filter: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        suppressHeaderMenuButton: true, cellRenderer: ProduceDocumentCellRenderer,
      },
      {
        headerName: t("Curriculumpage.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("Curriculumpage.LandingGridHeader.EDIT_DELETE"),
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressHeaderMenuButton: true, cellRenderer: NewsEditCellRenderer,
      },
    ]);
  };

  function convertUnicode(input) {
    return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
  }

  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  const editCurriculumClick = (params) => {
    base.get("task/frm", params.taskID).then((response) => {
      updateModuleData(response);
      setEditModal(true);
      setIsCreate(false);
    });
  };

  const deleteCurriculumClick = (params) => {
    setDeleteCurriculumConfirmToast(true);
    setDeleteCurriculum(params);
  };

  class NewsEditCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;

      const root = createRoot(divElement);

      var taskSite = params.node.data.task_Site
      var taskType = params.node.data.task_Type

      let trashStyle;
      if (taskSite === "SITE") {
        trashStyle = { cursor: "pointer" }
      } else if (taskSite === "GLOBAL") {
        if (globalUser === "GADMIN") {
          if (taskType !== "TSK") {
            trashStyle = { cursor: "pointer" }
          } else {
            trashStyle = { color: "#cecece" }
          }
        } else {
          trashStyle = { color: "#cecece" }
        }
      }

      root.render(
        <>
          <Edit32
            onClick={() => editCurriculumClick(params.node.data)}
            className="pointer"
          />

          <Trash32
            onClick={() => {
              if (taskSite === "SITE") {
                deleteCurriculumClick(params.node.data)
              } else if (taskSite === "GLOBAL") {
                if (globalUser === "GADMIN") {
                  if (taskType !== "TSK") {
                    deleteCurriculumClick(params.node.data)
                  }
                }
              }
            }
            }
            style={trashStyle}
            className=" trashOnSmallerScreen ms-2"
          />

        </>);
    }
    getGui() {
      return this.eGui;
    }
  }

  const curriculumDocumentTypes = [
    { TPO: "Test Paper" },
    { TAR: "Task Assessment Record" },
    { MAS: "Model Answer Sheet" },
    { OTF: "Operators Task Flow" },
    { TTB: "Trainers Task Breakdown" },
  ];

  const ProduceDocumentCellRenderer = (params) => {
    if (params.data.task_Type !== "EXT" && params.data.task_Type !== "NON")
      return (
        <UncontrolledDropdown direction="down" className="pointer" style={{ position: "fixed", right: "14.5%", color: "#111" }}>
          <DropdownToggle className="kebabdropdown btnpadding">
            <DownloadBasic32 />
          </DropdownToggle>
          <DropdownMenu container="body">
            {curriculumDocumentTypes
              .filter((docType) => {
                const docTypeKey = Object.keys(docType)[0];
                if (params.data.task_Type === "TSK") {
                  if (docTypeKey !== "TPO") return true;
                  return false;
                } else if (params.data.task_Type === "DAT") {
                  if (docTypeKey === "TTB" || docTypeKey === "TPO") return true;
                  return false;
                } else if (params.data.task_Type === "SYL") {
                  if (docTypeKey === "MAS" || docTypeKey === "TPO") return true;
                  return false;
                }
                return docType
              })
              .map((docType, index) => {
                const docTypeKey = Object.keys(docType)[0];
                const docTypeValue = docType[docTypeKey];
                return (
                  <DropdownItem
                    key={`document-${index}`}
                    onClick={() => {
                      docDownloadingToast();
                      ProduceCurrDocument(params.data, docTypeKey);
                    }}
                  >
                    {docTypeValue}
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    return null;
  };

  async function handleSingleDownload(reportendpoint) {
    return new Promise((resolve) => {
      base
        .getReport(reportendpoint)
        .then(({ responseData, fileName }) => {
          setIsDownloadStarted(false);
          if (
            responseData ===
            "Not adequate questions assigned for this module!"
          ) {
            setShowToast({ Name: "InsuffQuestions", show: true });
            document.addEventListener("mousedown", () =>
              setShowToast(false)
            );
          } else {
            fileDownload(responseData, fileName);
          }
          resolve("report success");
        })
        .catch((err) => {
          setIsDownloadStarted(false);
          if (err.status === 400) {
            setShowToast({ Name: "nodata", show: true });
            document.addEventListener("mousedown", () =>
              setShowToast(false)
            );
          } else {
            setShowToast({ Name: "error1", show: true });
            document.addEventListener("mousedown", () =>
              setShowToast(false)
            );
          }
          resolve("report failure");
        });
    });
  }

  async function handleMultipleDownloads(rependpoints) {
    for (let i = 0; i < rependpoints.length; i++) {
      await handleSingleDownload(rependpoints[i]);
    }
  }

  function ProduceCurrDocument(moduleData, docTypeKey) {
    // docTypeKey - MAS, OTF, TAR, TPO, TTB
    let arrEndpoints = [];
    const moduleID = moduleData.taskID;
    const moduleType = moduleData.task_Type;

    if (moduleType === "TSK") {
      if (docTypeKey === "TTB") {
        let reportendpoint1 = `Report_BatchTaskTraining/qry/Pre/TTB/${moduleID}/PDF`;
        let reportendpoint2 = `Report_BatchTaskTraining/qry/Pre/TTB/${moduleID}/EXCEL`;
        arrEndpoints.push(reportendpoint1);
        arrEndpoints.push(reportendpoint2);
      } else if (docTypeKey === "TAR") {
        let reportendpoint1 = `Report_BatchTaskTraining/qry/TAR/Q/${moduleID}/PDF`;
        let reportendpoint2 = `Report_BatchTaskTraining/qry/TAR/Q/${moduleID}/EXCEL`;
        arrEndpoints.push(reportendpoint1);
        arrEndpoints.push(reportendpoint2);
      } else if (docTypeKey === "MAS") {
        let reportendpoint1 = `Report_BatchTaskTraining/qry/TAR/A/${moduleID}/PDF`;
        let reportendpoint2 = `Report_BatchTaskTraining/qry/TAR/A/${moduleID}/EXCEL`;
        arrEndpoints.push(reportendpoint1);
        arrEndpoints.push(reportendpoint2);
      } else if (docTypeKey === "OTF") {
        let reportendpoint1 = `Report_BatchTaskTraining/qry/OTF/${moduleID}/PDF`;
        let reportendpoint2 = `Report_BatchTaskTraining/qry/OTF/${moduleID}/EXCEL`;
        arrEndpoints.push(reportendpoint1);
        arrEndpoints.push(reportendpoint2);
      }
    } else if (moduleType === "DAT") {
      const datType = moduleData.task_Number.includes("TRN006")
        ? "DAT1"
        : "DAT2";

      if (datType === "DAT1") {
        if (docTypeKey === "TPO") {
          let reportendpoint1 = `Report_BatchTaskTraining/qry1/DT1/${moduleID}/PDF`;
          let reportendpoint2 = `Report_BatchTaskTraining/qry1/DT1/${moduleID}/EXCEL`;
          arrEndpoints.push(reportendpoint1);
          arrEndpoints.push(reportendpoint2);
        } else if (docTypeKey === "TTB") {
          let reportendpoint1 = `Report_BatchTaskTraining/qry1/DT1/TTB/${moduleID}/PDF`;
          let reportendpoint2 = `Report_BatchTaskTraining/qry1/DT1/TTB/${moduleID}/EXCEL`;
          arrEndpoints.push(reportendpoint1);
          arrEndpoints.push(reportendpoint2);
        }
      } else if (datType === "DAT2") {
        if (docTypeKey === "TPO") {
          let reportendpoint1 = `Report_BatchTaskTraining/qry/DT2/PDF`;
          let reportendpoint2 = `Report_BatchTaskTraining/qry/DT2/EXCEL`;
          arrEndpoints.push(reportendpoint1);
          arrEndpoints.push(reportendpoint2);
        } else if (docTypeKey === "TTB") {
          let reportendpoint1 = `Report_BatchTaskTraining/qry/DT2/TTB/PDF`;
          let reportendpoint2 = `Report_BatchTaskTraining/qry/DT2/TTB/EXCEL`;
          arrEndpoints.push(reportendpoint1);
          arrEndpoints.push(reportendpoint2);
        }
      }
    } else if (moduleType === "SYL") {
      if (docTypeKey === "TPO") {
        let reportendpoint1 =
          "Report_ClassroomAssessment/qry/Q/" + moduleID + "/PDF";
        let reportendpoint2 =
          "Report_ClassroomAssessment/qry/Q/" + moduleID + "/EXCEL";
        arrEndpoints.push(reportendpoint1);
        arrEndpoints.push(reportendpoint2);
      } else if (docTypeKey === "MAS") {
        let reportendpoint1 =
          "Report_ClassroomAssessment/qry/A/" + moduleID + "/PDF";
        let reportendpoint2 =
          "Report_ClassroomAssessment/qry/A/" + moduleID + "/EXCEL";
        arrEndpoints.push(reportendpoint1);
        arrEndpoints.push(reportendpoint2);
      }
    }
    handleMultipleDownloads(arrEndpoints);
  }

  const closeCreateCurriCulum = (val) => {
    setEditModal(val);
    updateLanguagePreference(languagePreference);
  };

  const getAllCurriculum = () => {
    base.getAll("task/qry/null/null/null/null/DATA").then((res) => {
      if (res !== undefined) {
        var data = res.filter(
          (x) =>
            x.rel_rec !== 0 && x.task_Name !== "" && x.task_Name != null
        );
        for (let i = 0; i < data.length; i++) {
          data[i].task_Name = convertUnicode(data[i].task_Name);
        }
        setGridData(data);
      }
    });
    base.getAll("drop/qry/retrain").then((res) => {
      setRetrainOptions(res);
    });
    base.getAll("task/Qry/null/GLOBAL/Y/null/BATCH").then((res) => {
      setGlobalModules(res);
    });
  };


  useEffect(() => {
    getAllCurriculum()
    // eslint-disable-next-line
  }, [languagePreference]);

  const createModuleClick = () => {
    updateModuleData(emptyModule);
    setIsCreate(true);
    setEditModal(true)
  }

  return (
    <div>
      <div>
        <TitleBanner
          heading={t("Curriculumpage.Curriculum")}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("Common.Manage Data"), t("Curriculumpage.Curriculum")]}
          showButton1={false}
          functionButton2={() => createModuleClick()
          }
          showButton2={true}
          textButton2={t("Curriculumpage.Create_Module")}
          buttonHeading={""}
          showButton3={false}
        />

        <ConfirmDeleteToast
          show={deleteCurriculumConfirmToast}
          handleCancel={cancelDelete}
          Message={t("Curriculumpage.DeleteToastCurriculum")}
          handleDelete={handleDeleteCurriculum}
        />
        {showToast.Name === "success" && (
          <SuccessToastNew
            show={showToast.show}
            //Message={Messages.NewsFeed.Success}
            Message={t("Curriculumpage.SuccessToastCurriculum")}
          />
        )}
        {showToast.Name === "error" && (
          <ErrorToastNew
            setHasFailed={setHasFailed}
            hasFailed={hasFailed}
            show={showToast.show}
            //Message={Messages.NewsFeed.Error}
            Message={t("Curriculumpage.ErrorToastCurriculum")}
          />
        )}
        {
          showToast.Name === "delete" && (
            <ErrorToastNew
              show={showToast.show}
              Message={t("ErrorMessages.UserLevelDelete")}
            />
          )
        }
        {showToast.Name === "error1" && (
          <ErrorToastNew
            setHasFailed={setHasFailed}
            hasFailed={hasFailed}
            show={showToast.show}
            Message={t("ErrorMessages.Something")}
          />
        )}
        {showToast.Name === "successDownload" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Curriculumpage.TTBSuccessToast")}
          />
        )}
        {showToast.Name === "InsuffQuestions" && (
          <ErrorToastNew
            setHasFailed={setHasFailed}
            hasFailed={hasFailed}
            show={showToast.show}
            Message={t("Curriculumpage.InsuffQuestionsToast")}
          />
        )}
        {showToast.Name === "nodata" && (
          <ErrorToastNew
            setHasFailed={setHasFailed}
            hasFailed={hasFailed}
            show={showToast.show}
            Message={t("Curriculumpage.NoDataToast")}
          />
        )}
        <SuccessToastNew
          show={isDownloadStarted}
          Message={t("Curriculumpage.Document downloading")}
        />

        <SuccessToastNew
          show={deleteSuccess}
          Message={t("Curriculumpage.ModuleDeleteSuccessToast")}
        />
        <ErrorToastNew
          show={showError}
          setHasFailed={setHasFailed}
          hasFailed={hasFailed}
          Message={t("Curriculumpage.DeleteErrorCurriculum")}
        />
      </div>

      <div className="ps-7 pe-7 pb-5 pt-4">
        <div className="gridDiv">
          <div
            className="ag-theme-material gridHtWd "
            style={{ position: 'relative' }}>

            <AgGridReact
              ref={gridRef}
              columnDefs={gridColDefs}
              rowData={gridData}
              suppressMenuHide={true}
              overlayNoRowsTemplate="No records found"
              defaultColDef={gridDefaultColDef}
              pagination={true}
              paginationPageSizeSelector={[10, 20, 50]}
              paginationPageSize={10}
              suppressScrollOnNewData={true}
              suppressHorizontalScroll={false}
              onFilterChanged={onFilterChanged}
              postProcessPopup={postProcessPopup}
            ></AgGridReact>
          </div>
        </div>
      </div>

      {editModal &&
        <CreateModule
          closed={() => {
            setEditModal(false);
          }}
          saved={() => {
            setEditModal(false);
            dataSaved();
          }}
          create={isCreate}
          close={closeCreateCurriCulum}
          saveSuccess={dataSaved}
          error={dataNotSaved}
          getAll={getAllCurriculum}
          userRole={role}
          retrainOptions={retrainOptions}
          globalModules={globalModules}
          setIsCreate={setIsCreate}
        ></CreateModule>
      }
    </div>
  );
};
export default ManageCurriculum;
