import { Label } from "@bphxd/ds-core-react";
import { useGridFilter } from "ag-grid-react";
import React, { useCallback, useEffect, useState } from "react";
import { Input } from "reactstrap";

const AssignFilterComponent = ({ model = "ALL", onModelChange }) => {
  const [closeFilter, setCloseFilter] = useState();
  const [unappliedModel, setUnappliedModel] = useState(model);

  const doesFilterPass = useCallback((params) => {
    if (unappliedModel === "ALL") {
      return true;
    } else if (unappliedModel === "Assigned") {
      return params.data.isAssigned === "true" || params.data.isAssigned === true;
    } else {
      return params.data.isAssigned === "false" || params.data.isAssigned === false;
    }
  }, [unappliedModel]);

  const afterGuiAttached = useCallback(({ hidePopup }) => {
    setCloseFilter(() => hidePopup);
  }, []);

  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  useEffect(() => {
    if (model === "ALL" || !model) {
      setUnappliedModel("ALL");
    } else {
      setUnappliedModel(model);
    }
  }, [model]);

  const onAssignChange = ({ target: { value } }) => {
    setUnappliedModel(value);
    onModelChange(value);
    if (closeFilter) {
      closeFilter();
    }
  };

  return (
    <div className="chkboxfilter-container p-2">
      <div className="chkboxfilter-flexcontainer">
        <Label>
          <Input
            type="radio"
            name="assign"
            value="ALL"
            checked={unappliedModel === "ALL"}
            onChange={onAssignChange}
          />{" "}
          All
        </Label>
      </div>
      <div className="chkboxfilter-flexcontainer">
        <Label>
          <Input
            type="radio"
            name="assign"
            value="Assigned"
            checked={unappliedModel === "Assigned"}
            onChange={onAssignChange}
          />{" "}
          Assigned
        </Label>
      </div>
      <div className="chkboxfilter-flexcontainer">
        <Label>
          <Input
            type="radio"
            name="assign"
            value="Unassigned"
            checked={unappliedModel === "Unassigned"}
            onChange={onAssignChange}
          />{" "}
          Unassigned
        </Label>
      </div>
    </div>
  );
};

export default AssignFilterComponent;
