import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import base from "../../../Services/BaseService";
import { Col, Row } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import DeanonymizePopUp from "./DeanonymizePopUp";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import "../../../Content/stylesheet/Location.css"
import "ag-grid-community";
import "ag-grid-enterprise";
//import "ag-grid-community/dist/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import "../../../Content/stylesheet/ag-grid-style.css";
import CustomCheckboxFilter from "../../Shared/CustomCheckboxFilter";
import { Service } from "../../../Service";
import { createRoot } from "react-dom/client";
import { Input, Label, Modal } from '@bphxd/ds-core-react';

const DeAnonymiseLocation = (props) => {
  const { t } = useTranslation();
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridData, setGridData] = useState();
  const pageSize = 10;
  const [ddlReasonVal, setddlReasonVal] = useState([]);
  const [ddlDueToVal, setddlDueToVal] = useState([]);
  const [deanonymizePopUp, setdeanonymizePopUp] = useState(false);
  const [deanonymizePopUpFeed, setdeanonymizePopUpFeed] = useState({});
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initGridVar();
    getAllUsers();
    getAPIData();
    // eslint-disable-next-line
  }, []);

  //Loading data for Reason and Due To drop down
  const getAPIData = () => {

    base.getAll("drop/frm/ANON_REAS").then((res1) => {
      setddlReasonVal(res1);
    });


    base.getAll("drop/frm/ANON_DUET").then((res2) => {
      setddlDueToVal(res2);
    });
  };

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  //Setting up of AG Grid
  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      // minWidth: 50,
      sortable: true,
      filter: true,
      resizable: false,
      wrapText: true,
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        minWidth: 80,
        maxWidth: 80,
        headerName: "",
        field: "",
        resizable: false,
        suppressMovable: true,
        sortable: false,
        filter: CustomCheckboxFilter,
        cellRenderer: CheckboxCellRenderer,
      },
      {
        headerName: t("Regionpage.USER NAME"),
        headerTooltip: t("Regionpage.USER NAME"),
        field: "userDisplayName",
        resizable: false,
        suppressMovable: true,
        sort: "asc",
        comparator: customComparator,
        cellRenderer: UserHeadlineCellRenderer,
      },
      {
        headerName: t("Regionpage.USER EMAIL ID"),
        headerTooltip: t("Regionpage.USER EMAIL ID"),
        field: "email",
        resizable: false,
        suppressMovable: true,
        comparator: customComparator,
        cellRenderer: UserHeadlineCellRenderer,
      },
    ]);
  };

  //Setting up of AG Grid Row Data
  class UserHeadlineCellRenderer {
    init(params) {
      var element = document.createElement("span");
      element.innerHTML = params.value;
      element.classList.add("headline");
      this.eGui = element;
    }
    getGui() {
      return this.eGui;
    }
  }

  //Fetching all users for AG Grid

  const getAllUsers = () => {
    var locID = props.deAnonymiseFeed.locationID;

    base.getAll("user/qry1/DEANONYMISE/" + locID).then((res) => {
      if (res !== undefined) {
        var data = res
          .filter((x) => x.id !== "" && x.id != null)
          .map((userData) => {
            return { ...userData, isAssigned: userData.isDeanonymised };
          });

        setGridData(data);
        //getAllExistingAssignedQuestions();
      }
    });
  };

  //Setting up of Checkbox renderer in AG Grid
  class CheckboxCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridCrudIcons");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <Input
            type="checkbox"
            id={params.data.userID}
            style={{ left: "100px" }}
            checked={params.node.data.isDeanonymised === "true" ? true : false}
            onChange={() => callDeanonymizePopUp(params.node.data)}
          />

        </>);

    }
    getGui() {
      return this.eGui;
    }
  }


  //Function invoked when user clicks on checkbox

  const callDeanonymizePopUp = (params) => {
    var deanonymizePopUpFeed = {
      fk_UserID: params.userID,
      fk_LocationID: props.deAnonymiseFeed.locationID,
    };
    if (
      document.getElementById(params.userID) != null &&
      document.getElementById(params.userID) !== undefined
    ) {
      var checked = document.getElementById(params.userID).checked;
      if (checked) {
        setdeanonymizePopUpFeed(deanonymizePopUpFeed);
        setdeanonymizePopUp(true);
      } else if (!checked) {
        setdeanonymizePopUpFeed(deanonymizePopUpFeed);
        setdeanonymizePopUp(false);
        RemoveDeAnonymizeUser(params);
      }
    }
  };

  // Function invoked when user unselects a checkbox
  const RemoveDeAnonymizeUser = (params) => {
    var deanonymize = {
      fk_UserID: params.userID,
      fk_LocationID: props.deAnonymiseFeed.locationID,
      assign_Indicator: "DEANONYMISE",
      text01: "",
      text02: "",
      text03: "",
      text04: null,
      rel_rec: 0,
    };


    base
      .add(process.env.REACT_APP_DEANONYMISE_ADD, deanonymize)
      .then((response) => {
        getAllUsers();
        removedeanonymizeSuccess();
      })
      .catch((error) => {
      });
  };

  function DataSavingToastonClick() {
    setShowToast(false);
  }

  const customComparator = (valueA, valueB) => {
    // getAllExistingAssignedQuestions();
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };


  const cancelHandler = () => {
    props.close(false);
  };

  const closeDeAnonymisePopUp = (val) => {
    setdeanonymizePopUp(val);
  };

  //Toast messages setup
  const deanonymizeSuccess = () => {
    setShowToast({ Name: "successDeanonymize", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const removedeanonymizeSuccess = () => {
    setShowToast({ Name: "successRemoveDeanonymize", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  return (
    <div>
      <Modal
        isOpen={props.isopen}
        centered
        className="modal-70"
      >
        <Modal.Header
          className="comm-header-bgPosition modalPopupBGColour "
          onToggleOpen={cancelHandler}
        >

          <label
            className="modalHeaderGlobal ms-5"
          >
            {t("Regionpage.Select Users")}
          </label>
          {showToast.Name === "successDeanonymize" && (
            <SuccessToastNew
              form={true}
              show={showToast.show}
              Message={t("Regionpage.deanonymizeSuccess")}
            />
          )}
          {showToast.Name === "successRemoveDeanonymize" && (
            <SuccessToastNew
              form={true}
              show={showToast.show}
              Message={t("Regionpage.removedeanonymizeSuccess")}
            />
          )}
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Label>
                {t("Regionpage.Location Code")}
              </Label>
              <Input
                disabled
                value={props.deAnonymiseFeed.locationID}
              ></Input>
            </Col>
            <Col>
              <Label >
                {t("Regionpage.Location")}
              </Label>
              <Input
                disabled
                value={props.deAnonymiseFeed.location_Description}
              ></Input>
            </Col>
          </Row>

          <div className="pt-4">
            <div className="gridDiv">

              <div
                className="ag-theme-material gridHtWd "
                style={{ position: 'relative' }}
              >

                <AgGridReact
                  ref={gridRef}
                  columnDefs={gridColDefs}
                  rowData={gridData}
                  suppressMenuHide={true}
                  overlayNoRowsTemplate="No records"
                  defaultColDef={gridDefaultColDef}
                  pagination={true}
                  paginationPageSizeSelector={[10, 20, 50]}

                  paginationPageSize={pageSize}
                  suppressScrollOnNewData={true}
                  resizeable={true}
                  onFilterChanged={onFilterChanged}
                  postProcessPopup={postProcessPopup}
                />
              </div>
            </div>

          </div>

          <div className="mt-8"></div>
          {deanonymizePopUp ? (
            <DeanonymizePopUp
              getAllUser={getAllUsers}
              close={closeDeAnonymisePopUp}
              DeanonymizeFeed={deanonymizePopUpFeed}
              ReasonList={ddlReasonVal}
              DueToList={ddlDueToVal}
              isopen={deanonymizePopUp}
              saveSuccess={deanonymizeSuccess}
            ></DeanonymizePopUp>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeAnonymiseLocation;
